import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';

import Enums from '../../generated-types/Enums';
import { useSlabMutation } from '../../hooks/useSlabMutation';
import { useSlabQuery } from '../../hooks/useSlabQuery';
import { SlabContext } from '../../SlabContext';
import { QueryError } from '../../utils/Query';
import { ErrorFullPage } from '../Error/ErrorFullPage';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { AuthorizationTokenSchema } from './Login/NylasLoginFormik';

type NylasAccountManagementParams = {
  onSuccess: (message: string) => void;
  onError: (error: QueryError) => void;
};
export const NylasAccountManagement = ({
  onError,
  onSuccess,
}: NylasAccountManagementParams): JSX.Element => {
  const [authorized, setAuthorized] = useState<boolean | undefined>();
  const [successfulAuth, setSuccessfulAuth] = useState<boolean | undefined>();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const code = params.get('code');
  const [confirmRevokeOpen, setConfirmRevokeOpen] = useState<boolean>(false);
  const ctx = React.useContext(SlabContext);
  const useNylasV3 = ctx.userInfo.hasFlags([Enums.FeatureFlagName.FeatureFlagNylasV3]);

  const codeQuery = useNylasV3 ? 'POST nylas v3 code' : 'POST nylas code';

  const codeMutation = useSlabMutation(codeQuery, {
    onError: (error) => {
      setAuthorized(false);
      onError(error);
    },
    onSuccess: () => {
      setAuthorized(true);
      setSuccessfulAuth(true);
      navigate(location.pathname);
    },
    retry: false,
  });

  useEffect(() => {
    if (successfulAuth === true) {
      onSuccess('Authenticated');
      setSuccessfulAuth(false);
    }
  }, [successfulAuth]);

  const revokeQuery = useNylasV3 ? 'POST nylas v3 revoke grant' : 'POST nylas revoke token';

  const revokeMutation = useSlabMutation(revokeQuery, {
    onError: (error) => {
      onError(error);
    },
    onSuccess: () => {
      setAuthorized(false);
      onSuccess('Account access revoked');
    },
  });

  const accountInfoQuery = useNylasV3 ? 'GET nylas v3 grant info' : 'GET nylas account info';
  const {
    data: accountInfo,
    isLoading: isLoadingAccountInfo,
    isError: isErrorAccountInfo,
    isSuccess: isSuccessAccountInfo,
  } = useSlabQuery(
    accountInfoQuery,
    {},
    {
      retry: (fc, error) => error.response?.status !== 401,
      /** The cache must be invalidated immediately so that the page will update when a user's token
       * is revoked. Otherwise the page will not update until the cache expires or the page is hard
       * refreshed.
       */
      gcTime: 0,
    },
  );
  useEffect(() => {
    if (isSuccessAccountInfo) {
      setAuthorized(true);
    }
    if (isErrorAccountInfo) {
      setAuthorized(false);
    }
  }, [isErrorAccountInfo, isSuccessAccountInfo]);

  const loginQuery = useNylasV3 ? 'GET nylas v3 login' : 'GET nylas login';

  const {
    data: loginUrl,
    isLoading: isLoadingLoginUrl,
    isError: isErrorLoginUrl,
  } = useSlabQuery(loginQuery, {});

  const isLoading = isLoadingLoginUrl || isLoadingAccountInfo;
  const isError = isErrorLoginUrl || (isErrorAccountInfo && authorized === true);

  useEffect(() => {
    if (code !== null && authorized !== true && !isLoading && !codeMutation.isPending) {
      codeMutation.mutate({
        args: {
          body: {
            code,
          },
        },
        schema: AuthorizationTokenSchema,
      });
    }
  }, [code, authorized, isLoading, codeMutation.isPending]);

  const submitRevoke = async (): Promise<void> => {
    await revokeMutation.mutateAsync(
      {
        args: {
          body: null,
        },
        schema: Yup.mixed().nullable(),
      },
      {
        onSettled: (): void => setConfirmRevokeOpen(false),
      },
    );
  };

  if (isLoading) return <LoadingSpinner />;
  if (isError || loginUrl === undefined) return <ErrorFullPage />;

  return (
    <Card
      raised
      sx={{
        borderRadius: '1.5rem',
        maxWidth: '45rem',
      }}
    >
      <CardHeader
        title={`${accountInfo === undefined ? 'No ' : ''}Account Linked for Sending Emails`}
      />
      <CardContent>
        {accountInfo === undefined || authorized === false ? (
          <Typography>
            There is no account currently linked for sending emails. Please follow{' '}
            <a href={loginUrl.fullUrl}>this link</a> to authorize an email account to use when
            sending emails.
          </Typography>
        ) : (
          <>
            <Grid container columns={2} rowGap={1}>
              {useNylasV3 === false && (
                <>
                  <Grid item xs={1}>
                    <Typography variant='body1'>Name</Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography variant='body1'>{accountInfo.name}</Typography>
                  </Grid>
                </>
              )}
              <Grid item xs={1}>
                <Typography variant='body1'>Email address</Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography variant='body1'>{accountInfo.emailAddress}</Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography variant='body1'>Provider</Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography variant='body1'>{accountInfo.provider}</Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography variant='body1'>Sync state</Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography variant='body1'>{accountInfo.syncState}</Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography variant='body1'>Account linked on</Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography variant='body1'>
                  {accountInfo.linkedAt !== undefined
                    ? DateTime.fromSeconds(accountInfo.linkedAt, { zone: 'UTC' }).toFormat('DDD t')
                    : ''}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Box>
                  <Button onClick={(): void => setConfirmRevokeOpen(true)}>
                    Revoke account access
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Dialog open={confirmRevokeOpen} onClose={(): void => setConfirmRevokeOpen(false)}>
              <DialogTitle>Confirm access revocation</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  By clicking &quot;OK&quot; you will revoke the application&apos;s current access
                  to send emails from your email account that you previously granted and will no
                  longer be able to send quotes. You can re-authorize the same account or authorize
                  a new acount by following the same process as when you first authorized an
                  account.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={(): void => setConfirmRevokeOpen(false)}>Cancel</Button>
                <Button onClick={submitRevoke}>OK</Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </CardContent>
    </Card>
  );
};
