import { Currency } from '../generated-types/Currency/Currency';

type CalculateAggregateTotalPriceParams = {
  unitPrice: Currency;
  haulRate: Currency;
  taxRate: number;
  aggHaulTaxable: boolean;
};

/**
 * Calculates the total price for an aggregate product based on
 * the unit price, haul rate and tax rate.
 */
export const CalculateAggregateTotalPrice = ({
  unitPrice,
  haulRate,
  taxRate,
  aggHaulTaxable,
}: CalculateAggregateTotalPriceParams): Currency | null => {
  const priceAfterTax = unitPrice.multiply(1 + taxRate) ?? Currency.zero();
  const haulTaxRate = aggHaulTaxable ? taxRate : 0;
  const rateAfterTax = haulRate.multiply(1 + haulTaxRate) ?? Currency.zero();

  return priceAfterTax.add(rateAfterTax);
};
