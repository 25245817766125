import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { CSSObject, styled, Theme, useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { Logout } from 'iconsax-react';
import LogRocket from 'logrocket';
import * as React from 'react';
import { useCookies } from 'react-cookie';
import { Link, useLocation } from 'react-router-dom';

import { useSlabAuth } from '../../hooks/useSlabAuth';
import { SlabContext } from '../../SlabContext';
import { BoundSlabRoutes } from '../../SlabRoutes';
import { TenantSelectionMenu } from './TenantSelectionMenu';

const drawerWidth = 240;
const closedDrawerWidth = 65;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: closedDrawerWidth,
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  width: `calc(100% - ${closedDrawerWidth}px)`,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open === true && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open === true && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!(open === true) && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export const NavBar = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const location = useLocation();
  const theme = useTheme();
  const { logout, user } = useSlabAuth();
  const [open, setOpen] = React.useState(true);
  const { userInfo } = React.useContext(SlabContext);
  const [{ AuthOverride: authOverride }] = useCookies(['AuthOverride', 'User']);

  const isActive = (path: string): boolean => {
    const basePath = location.pathname.split('/')[1];
    return `/${basePath}` === path;
  };

  const isImpersonating = user?.admin && (authOverride ?? '') !== '';

  return (
    <Box sx={{ display: '-webkit-box', backgroundColor: 'inherit' }}>
      <CssBaseline />
      <AppBar position='fixed' open={open}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box>
            <img src='/logo-words-grey.png' alt='logo' width='180px' height='48px' />
          </Box>
          <Box
            sx={{
              flex: 1,
              backgroundColor: isImpersonating ? theme.palette.SlabBrown['600'] : 'transparent',
              color: 'white',
              height: '74px',
            }}
          />
          {user !== undefined && (
            <Box display='flex'>
              <TenantSelectionMenu auth0User={user} logout={logout} />
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        variant='permanent'
        open={open}
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.SlabGray['950'],
            color: theme.palette.SlabGray['500'],
          },
        }}
      >
        <DrawerHeader>
          <IconButton onClick={(): void => setOpen(!open)}>
            {open ? (
              <ChevronLeftIcon sx={{ color: theme.palette.SlabGray[0] }} />
            ) : (
              <MenuIcon sx={{ color: theme.palette.SlabGray[0] }} />
            )}
          </IconButton>
        </DrawerHeader>
        {/* TODO: #628 Make this a dropdown per section */}
        {BoundSlabRoutes(userInfo.readableDomainTypes, userInfo.featureFlags).map(
          (routeSection) => (
            <div key={routeSection.name}>
              <Divider />
              <List>
                {routeSection.children.map(
                  (route) =>
                    route.icon !== undefined &&
                    route.icon() !== null && (
                      <ListItem
                        id={`${route.name}-${route.path}`}
                        key={`${route.name}-${route.path}`}
                        disablePadding
                        sx={{ display: 'block' }}
                      >
                        <Link to={route.path} style={{ textDecoration: 'none', color: 'inherit' }}>
                          <ListItemButton
                            sx={{
                              minHeight: 48,
                              justifyContent: open ? 'initial' : 'center',
                              px: 2.5,
                            }}
                          >
                            <ListItemIcon
                              sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                              }}
                            >
                              {route.icon(
                                isActive(route.path)
                                  ? theme.palette.SlabYellow[500]
                                  : theme.palette.SlabGray['500'],
                              )}
                            </ListItemIcon>
                            <ListItemText
                              primary={route.name}
                              sx={{
                                opacity: open ? 1 : 0,
                                span: {
                                  fontWeight: isActive(route.path) ? 'bold' : 'normal',
                                  color: isActive(route.path)
                                    ? theme.palette.SlabYellow[500]
                                    : 'inherit',
                                },
                              }}
                            />
                          </ListItemButton>
                        </Link>
                      </ListItem>
                    ),
                )}
              </List>
            </div>
          ),
        )}

        <Divider />
        <List>
          <ListItem key='logout-btn' disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              onClick={(): void => {
                LogRocket.startNewSession();
                logout({ logoutParams: { returnTo: window.location.origin } });
              }}
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <Logout color={theme.palette.SlabGray['500']} />
              </ListItemIcon>
              <ListItemText
                primary='Log Out'
                sx={{
                  opacity: open ? 1 : 0,
                }}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
};
