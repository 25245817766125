// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import { NIL_UUID } from '../../utils/UUID';
import Enums from '../Enums';

export class MaterialSummary {
  readonly id: string;
  readonly name: string;
  readonly alternateID: string | null;
  readonly materialType: Enums.MaterialType;
  readonly inProductCost: string | null;
  readonly plantName: string;
  readonly supplierCompany: string | null;
  readonly rawMaterialCost: string;
  readonly haulingCost: string | null;
  readonly plantLoadingCost: string | null;
  readonly plantId: string;
  readonly marketId: string;
  readonly externalID: string | null;

  constructor(data: { [key: string]: any } = {}) {
    this.id = data.id === undefined ? NIL_UUID : data.id;
    this.name = data.name === undefined ? '' : data.name;
    this.alternateID = data.alternateID === undefined ? null : data.alternateID;
    this.materialType =
      data.materialType === undefined ? Enums.MaterialType.Aggregate : data.materialType;
    this.inProductCost = data.inProductCost === undefined ? null : data.inProductCost;
    this.plantName = data.plantName === undefined ? '' : data.plantName;
    this.supplierCompany = data.supplierCompany === undefined ? null : data.supplierCompany;
    this.rawMaterialCost = data.rawMaterialCost === undefined ? '' : data.rawMaterialCost;
    this.haulingCost = data.haulingCost === undefined ? null : data.haulingCost;
    this.plantLoadingCost = data.plantLoadingCost === undefined ? null : data.plantLoadingCost;
    this.plantId = data.plantId === undefined ? NIL_UUID : data.plantId;
    this.marketId = data.marketId === undefined ? NIL_UUID : data.marketId;
    this.externalID = data.externalID === undefined ? null : data.externalID;
  }

  static zero(): MaterialSummary {
    const zeroValues: ExcludeMethodsDeep<MaterialSummary> = {
      id: NIL_UUID,
      name: '',
      alternateID: null,
      materialType: Enums.MaterialType.Aggregate,
      inProductCost: null,
      plantName: '',
      supplierCompany: null,
      rawMaterialCost: '',
      haulingCost: null,
      plantLoadingCost: null,
      plantId: NIL_UUID,
      marketId: NIL_UUID,
      externalID: null,
    };
    return new MaterialSummary(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewMaterialSummary = (
  props: PartialDeep<MaterialSummary, { recurseIntoArrays: true }>,
): MaterialSummary => new MaterialSummary(props);

export const NewMaterialSummaryFromDomainObject = (
  props: PartialDeep<DomainObject<MaterialSummary>, { recurseIntoArrays: true }>,
): MaterialSummary => new MaterialSummary(props);
