// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import { NIL_UUID } from '../../utils/UUID';
import { Address, NewAddress } from '../Address/Address';
import { CostRule, NewCostRule } from '../CostRule/CostRule';
import { DeliveryCost, NewDeliveryCost } from '../DeliveryCost/DeliveryCost';
import { Forecast, NewForecast } from '../Forecast/Forecast';
import { Market, NewMarket } from '../Market/Market';
import { NewPlantTruckingType, PlantTruckingType } from '../PlantTruckingType/PlantTruckingType';
import { NewReadyMixConstants, ReadyMixConstants } from '../ReadyMixConstants/ReadyMixConstants';

export class Plant {
  readonly id: string;
  readonly name: string;
  readonly notes: string | null;
  readonly address: Address;
  readonly readyMixConstants: ReadyMixConstants | null;
  readonly forecasts: Forecast[];
  readonly deliveryCosts: DeliveryCost;
  readonly materialCostRule: CostRule | null;
  readonly market: Market;
  readonly truckingTypes: PlantTruckingType[];

  constructor(data: { [key: string]: any } = {}) {
    this.id = data.id === undefined ? NIL_UUID : data.id;
    this.name = data.name === undefined ? '' : data.name;
    this.notes = data.notes === undefined ? null : data.notes;
    this.address = NewAddress(data.address);
    this.readyMixConstants =
      // eslint-disable-next-line no-nested-ternary
      (data.readyMixConstants ?? null) === null
        ? null
        : NewReadyMixConstants(data.readyMixConstants);
    this.forecasts = (data.forecasts ?? []).map((forecasts: any) => NewForecast(forecasts));
    this.deliveryCosts = NewDeliveryCost(data.deliveryCosts);
    this.materialCostRule =
      // eslint-disable-next-line no-nested-ternary
      (data.materialCostRule ?? null) === null ? null : NewCostRule(data.materialCostRule);
    this.market = NewMarket(data.market);
    this.truckingTypes = (data.truckingTypes ?? []).map((truckingTypes: any) =>
      NewPlantTruckingType(truckingTypes),
    );
  }

  static zero(): Plant {
    const zeroValues: ExcludeMethodsDeep<Plant> = {
      id: NIL_UUID,
      name: '',
      notes: null,
      address: Address.zero(),
      readyMixConstants: null,
      forecasts: [],
      deliveryCosts: DeliveryCost.zero(),
      materialCostRule: null,
      market: Market.zero(),
      truckingTypes: [],
    };
    return new Plant(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewPlant = (props: PartialDeep<Plant, { recurseIntoArrays: true }>): Plant =>
  new Plant(props);

export const NewPlantFromDomainObject = (
  props: PartialDeep<DomainObject<Plant>, { recurseIntoArrays: true }>,
): Plant => new Plant(props);
