import { createTheme } from '@mui/material/styles';

const SlabGray = {
  0: '#FFFFFF',
  50: '#F9FAFC',
  400: '#C5C6CA',
  500: '#A1A2AA',
  600: '#727382',
  800: '#091747',
  900: '#061136',
  950: '#050E2C',
  Stroke: '#EAEDF9',
  Table: '#F3F4F6',
} as const;

const SlabYellow = {
  300: '#FFE7A8',
  400: '#FFDE80',
  500: '#FFD641',
} as const;

const SlabBrown = {
  200: '#F5EAE1',
  400: '#E8B84E',
  500: '#CD930D',
  600: '#A76E1D',
  800: '#744D14',
} as const;

const SlabGreen = {
  100: '#EBFFF4',
  200: '#DFECE7',
  400: '#3FC79A',
  500: '#0DA289',
  600: '#0B815A',
  800: '#075A3E',
} as const;

const SlabRed = {
  200: '#F9E0E4',
  400: '#FF7B79',
  500: '#EE1D52',
  600: '#C61834',
  800: '#8A0F24',
} as const;

const SlabTurquoise = {
  200: '#EFEFEF',
  500: '#00B4D8',
  600: '#0077B6',
  800: '#023E8A',
  900: '#03045E',
} as const;

const SlabIndigo = {
  300: '#CDD5DF',
  500: '#6B8AFC',
  700: '#3E5CC6',
} as const;

const SlabPurple = {
  200: '#EFECFD',
  600: '#7468E4',
};

const SlabStatus = {
  error: {
    ...SlabRed,
    light: SlabRed['400'],
    main: SlabRed['600'],
    dark: SlabRed['800'],
  },
  warning: {
    ...SlabBrown,
    light: SlabBrown['400'],
    main: SlabBrown['600'],
    dark: SlabBrown['800'],
  },
  success: {
    ...SlabGreen,
    light: SlabGreen['400'],
    main: SlabGreen['600'],
    dark: SlabGreen['800'],
  },
} as const;

// Extend the MUI theme palette to include SlabColor
declare module '@mui/material/styles' {
  interface Palette {
    SlabGray: typeof SlabGray;
    SlabYellow: typeof SlabYellow;
    SlabBrown: typeof SlabBrown;
    SlabRed: typeof SlabRed;
    SlabGreen: typeof SlabGreen;
    SlabTurquoise: typeof SlabTurquoise;
    SlabIndigo: typeof SlabIndigo;
    SlabPurple: typeof SlabPurple;
    SlabStatus: typeof SlabStatus;
  }
  interface PaletteOptions {
    SlabGray: typeof SlabGray;
    SlabYellow: typeof SlabYellow;
    SlabBrown: typeof SlabBrown;
    SlabRed: typeof SlabRed;
    SlabGreen: typeof SlabGreen;
    SlabTurquoise: typeof SlabTurquoise;
    SlabIndigo: typeof SlabIndigo;
    SlabPurple: typeof SlabPurple;
    SlabStatus: typeof SlabStatus;
  }
  interface TypographyVariants {
    body3: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
  }
  interface ZIndex {
    tableHead?: number;
    tableRow?: number;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    pill: true;
  }
}

declare module '@mui/material/Drawer' {
  interface DrawerPropsVariantOverrides {
    pill: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
  }
}

/**
 * Custom slab themes that are used throughout the app, and located
 * in one place. Used to overwrite MUI defaults
 */
export const SlabTheme = createTheme({
  palette: {
    success: SlabStatus.success,
    warning: SlabStatus.warning,
    error: SlabStatus.error,
    SlabGray,
    SlabYellow,
    SlabBrown,
    SlabRed,
    SlabGreen,
    SlabTurquoise,
    SlabIndigo,
    SlabPurple,
    SlabStatus,
  },
  zIndex: {
    tableHead: 2,
    tableRow: 1,
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Helvetica Neue"',
      'Roboto',
      'sans-serif',
    ].join(','),
    h1: {
      fontSize: '3rem',
      fontWeight: 500,
      letterSpacing: 0,
      color: SlabGray['950'],
    },
    h2: {
      fontSize: '1.75rem',
      fontWeight: 400,
      letterSpacing: 0,
    },
    h6: {
      fontSize: '1.125rem',
      fontWeight: 400,
      color: SlabGray['950'],
    },
    body2: {
      fontSize: '1rem',
    },
    body3: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: '1.125rem',
      color: SlabGray['600'],
    },
  },
  components: {
    // Section headings in edit drawers
    MuiStepIcon: {
      styleOverrides: {
        root: {
          fill: SlabIndigo['500'],
        },
      },
    },
    // DataTable column headings
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
          color: SlabGray['950'],
        },
      },
    },
    // Forecast totals - row positioning
    MuiTableFooter: {
      styleOverrides: {
        root: {
          position: 'sticky',
          bottom: 0,
          fontSize: 'inherit',
          backgroundColor: SlabGray[0],
        },
      },
    },
    // Forecast totals - cell styles
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: SlabGray['800'],
        },
        footer: {
          border: '0',
          borderTop: '1px solid #E0E0E0',
          fontSize: 'inherit',
          fontWeight: 'bold',
        },
      },
    },
    // Dividing lines in main navigation (and in dropdown menus)
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: SlabGray['500'],
          marginTop: '0.5rem',
          marginBottom: '1rem',
          opacity: '0.5',
        },
      },
    },
    // Slabstack-standard large button variant
    MuiButton: {
      variants: [
        {
          props: { variant: 'pill', size: 'small' },
          style: {
            borderRadius: '15rem',
            padding: '0.375rem 1.5rem',
          },
        },
        {
          props: { variant: 'pill', size: 'medium' },
          style: {
            borderRadius: '15rem',
            padding: '0.875rem 2rem',
          },
        },
        {
          props: { variant: 'pill', size: 'large' },
          style: {
            borderRadius: '15rem',
            padding: '0.875rem 2rem',
          },
        },
      ],
    },
    // Top bar with Slabstack logo and tenant switcher menu
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: SlabGray['50'],
          color: SlabGray['900'],
        },
      },
    },
    // Tab panels (used on many single-item detail pages)
    MuiTab: {
      styleOverrides: {
        root: {
          color: SlabGray['600'],
          '&.Mui-selected': {
            color: SlabGray['800'],
          },
        },
      },
    },
  },
});
