import { Breadcrumbs, Link, LinkProps, Typography, useTheme } from '@mui/material';
import { useContext } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { SlabContext } from '../../SlabContext';
import { BoundSlabRoutes } from '../../SlabRoutes';

type PathMap = {
  [key: string]: string;
};

interface LinkRouterProps extends LinkProps {
  to: string;
}

const LinkRouter = (props: LinkRouterProps): JSX.Element => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Link {...props} component={RouterLink as any} />
);

type BreadCrumbsProps = {
  /** Passed title will be used if there is not a direct match in the path map */
  title?: string;
};

export const BreadCrumbs = ({ title }: BreadCrumbsProps): JSX.Element => {
  const location = useLocation();
  const theme = useTheme();
  const { userInfo } = useContext(SlabContext);

  const pathnames = location.pathname.split('/').filter((x) => x !== '');

  /** A map of all possible paths to the name it should display */
  const pathMap = ((): PathMap => {
    let pm: PathMap = {};
    BoundSlabRoutes(userInfo.readableDomainTypes, userInfo.featureFlags).forEach((routeSection) => {
      routeSection.children.forEach((route) => {
        if (route.name !== undefined) {
          pm = {
            ...pm,
            [route.path]: route.name,
          };
        }
      });
    });
    return pm;
  })();

  return (
    <Breadcrumbs aria-label='breadcrumb'>
      {pathnames.map((_, index) => {
        const last = index === pathnames.length - 1;
        const to = `/${pathnames.slice(0, index + 1).join('/')}`;

        return last === true ? (
          <Typography color={theme.palette.SlabGray['500']} key={to}>
            {pathMap[to] ?? title}
          </Typography>
        ) : (
          <LinkRouter color={theme.palette.SlabGray['600']} underline='hover' to={to} key={to}>
            {pathMap[to] ?? ''}
          </LinkRouter>
        );
      })}
    </Breadcrumbs>
  );
};
