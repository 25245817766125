import { Wrapper } from '@googlemaps/react-wrapper';
import { Box, Typography } from '@mui/material';

import { SlabConfig } from '../../utils/SlabConfig';
import { MapDirections } from './Directions';
import { Map, MAP_ZOOM_FAR, MAP_ZOOM_MID, MapRenderer } from './Map';

export const ProjectMap = ({
  plantLocation,
  projectLocation,
}: {
  plantLocation: google.maps.LatLngLiteral | google.maps.LatLng | undefined;
  projectLocation: google.maps.LatLngLiteral | google.maps.LatLng | undefined;
}): JSX.Element => {
  if (SlabConfig.googleMaps.id === undefined) {
    return (
      <Box>
        <Typography>Google Map is unable to load without API key</Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        '& #map': {
          height: '60vh',
        },
      }}
    >
      <Wrapper
        apiKey={SlabConfig.googleMaps.id}
        render={MapRenderer}
        libraries={['places', 'marker']}
      >
        <Map
          zoom={projectLocation === undefined ? MAP_ZOOM_FAR : MAP_ZOOM_MID}
          center={
            projectLocation === undefined && plantLocation === undefined
              ? undefined
              : projectLocation
          }
        >
          <MapDirections projectLocation={projectLocation} plantLocation={plantLocation} />
        </Map>
      </Wrapper>
    </Box>
  );
};
