import { Box, ButtonBase, Typography, useTheme } from '@mui/material';
import { Edit2 } from 'iconsax-react';
import Linkify from 'linkify-react';
import { Link } from 'react-router-dom';

import { randomUUID } from '../../utils/UUID';

const transposeMatrix = <T,>(matrix: T[][]): T[][] => {
  if (matrix.length === 0) {
    return matrix;
  }
  const tm: T[][] = [];
  Object.keys(matrix[0]).forEach((_, idx): void => {
    const row: T[] = [];
    matrix.forEach((r) => {
      if (idx < r.length) {
        row.push(r[idx]);
      }
    });
    tm.push(row);
  });
  return tm;
};

type SharedFieldOpts = {
  label?: string;
  color?: 'primary' | 'secondary' | 'error' | 'warning' | 'info' | 'success';
} & (
  | {
      type?: undefined;
    }
  | {
      // 'notes' are rendered as blocks of text, preserving line breaks and
      // automatically converting URLs to hyperlinks.
      type: 'notes';
      maxWidth: string;
    }
  | {
      type: 'phone';
    }
  | {
      type: 'email';
    }
  | {
      type: 'uri';
      uri: string;
    }
);

export type SingleField<TData extends {}> = SharedFieldOpts & {
  key: keyof TData;
};

export type SingleFieldValue = SharedFieldOpts & {
  value: any;
};

type ValueContentProps<TData extends {}> =
  | {
      type: 'singleField';
      value: TData;
      rowValue: SingleField<TData>;
    }
  | {
      type: 'singleFieldValue';
      rowValue: SingleFieldValue;
    };

const ValueContent = <TData extends {}>(props: ValueContentProps<TData>): JSX.Element | null => {
  const theme = useTheme();

  const value =
    props.type === 'singleField' ? props.value[props.rowValue.key] : props.rowValue.value;

  if (value === null || value === undefined || value === '') {
    return <Box color={theme.palette.SlabGray['400']}>[not set]</Box>;
  }

  const { rowValue } = props;

  const handleEmailCheck = (event: React.MouseEvent, email: string): void => {
    event.stopPropagation();
    window.location.href = `mailto:${email}`;
    setTimeout(() => {
      if (document.hasFocus()) {
        window.open(
          `https://mail.google.com/mail/?view=cm&fs=1&to=${encodeURIComponent(email)}`,
          '_blank',
          'noopener,noreferrer',
        );
      }
    }, 500);
  };

  switch (rowValue.type) {
    case 'uri':
      return <Link to={rowValue.uri}>{value}</Link>;

    case 'email':
      return (
        <a
          href={`mailto:${value}`}
          onClick={(e: React.MouseEvent) => handleEmailCheck(e, value)}
          role='button'
        >
          {value}
        </a>
      );

    case 'phone':
      return <a href={`tel:${value}`}>{value}</a>;

    case 'notes': {
      const color =
        rowValue.color === undefined
          ? theme.palette.SlabGray['600']
          : theme.palette[rowValue.color].main;
      return (
        <Box
          color={color}
          sx={{
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word',
            minWidth: '15rem',
            maxWidth: rowValue.maxWidth,
          }}
        >
          <Linkify options={{ defaultProtocol: 'https', target: '_blank' }}>{value}</Linkify>
        </Box>
      );
    }

    default: {
      const color =
        rowValue.color === undefined
          ? theme.palette.SlabGray['600']
          : theme.palette[rowValue.color].main;
      return <Box color={color}>{value}</Box>;
    }
  }
};

type DisplayFieldProps<TData extends {}> = {
  title: string;
  /** If omitted, DisplayField is considered un-editable. */
  onEditClick?: () => void;
} & (
  | {
      type: 'singleField';
      value: TData;
      /**
       * Two-dimensional array of keys of the value to display.
       *
       * Example below will render two rows, where there are two items in the first row,
       * one in the second row.
       * @example
       * [
       *   [A, B],
       *   [C]
       * ]
       */
      displayMatrix: SingleField<TData>[][];
    }
  | {
      type: 'array';
      /**
       * @example
       * values=values={_.chunk((project.customFields ?? []).map((pcf) => ({
       *   label: pcf.definition.name,
       *   value: pcf.value,
       * })), 2)}
       */
      values: SingleFieldValue[][];
    }
);

export const DisplayField = <TData extends {}>(props: DisplayFieldProps<TData>): JSX.Element => {
  const { title, type, onEditClick } = props;
  const theme = useTheme();

  // To maintain spacing, we render columns rather than rows. Therefore, we transpose the display matrix.
  const displayFieldContent = ((): JSX.Element => {
    if (type === 'singleField') {
      const { displayMatrix, value } = props;
      const transposedMatrix = transposeMatrix(displayMatrix);
      return (
        <>
          {transposedMatrix.map((col) => (
            <Box key={randomUUID()}>
              {col.map((rowValue, rowIdx) => (
                <Box key={randomUUID()} display='flex' flexDirection='column' paddingRight={5}>
                  <Box paddingBottom={rowIdx !== col.length - 1 ? '1.25rem' : 0}>
                    {rowValue.label !== undefined && (
                      <Box color={theme.palette.SlabGray['800']}>{rowValue.label}</Box>
                    )}
                    <ValueContent type='singleField' value={value} rowValue={rowValue} />
                  </Box>
                </Box>
              ))}
            </Box>
          ))}
        </>
      );
    }
    const { values } = props;
    const transposedValueMatrix = transposeMatrix(values);
    return (
      <>
        {transposedValueMatrix.map((col) => (
          <Box key={randomUUID()}>
            {col.map((rowValue, rowIdx) => (
              <Box key={randomUUID()} display='flex' flexDirection='column' paddingRight={5}>
                <Box paddingBottom={rowIdx !== col.length - 1 ? '1.25rem' : 0}>
                  {rowValue.label !== undefined && (
                    <Box color={theme.palette.SlabGray['800']}>{rowValue.label}</Box>
                  )}
                  <ValueContent type='singleFieldValue' rowValue={rowValue} />
                </Box>
              </Box>
            ))}
          </Box>
        ))}
      </>
    );
  })();

  return (
    <Box sx={{ backgroundColor: theme.palette.SlabGray[0], borderRadius: '1rem' }}>
      <Box
        padding='1.5rem'
        display='flex'
        justifyContent='space-between'
        border={`1px solid ${theme.palette.SlabGray.Stroke}`}
        borderRadius='1rem 1rem 0 0'
      >
        <Typography paddingRight='2rem' variant='h6'>
          {title}
        </Typography>
        {onEditClick !== undefined && (
          <ButtonBase onClick={onEditClick}>
            <Edit2
              variant='TwoTone'
              style={{
                width: '1rem',
                height: '1rem',
                color: theme.palette.SlabIndigo['700'],
              }}
            />
            <Typography
              paddingLeft='0.375rem'
              color={theme.palette.SlabIndigo['700']}
              fontSize='0.75rem'
            >
              Edit
            </Typography>
          </ButtonBase>
        )}
      </Box>

      <Box
        padding='1.5rem'
        display='flex'
        border={`1px solid ${theme.palette.SlabGray.Stroke}`}
        borderRadius='0 0 1rem 1rem'
        sx={{
          div: {
            fontSize: '0.875rem',
          },
        }}
      >
        {displayFieldContent}
      </Box>
    </Box>
  );
};
