import { Box } from '@mui/material';

import {
  FormatEmailLinkCell,
  FormatLinkCell,
} from '../../../components/DataTable/components/SlabTableRowCells';
import { DataTable } from '../../../components/DataTable/DataTable';
import { ColumnConfig } from '../../../components/DataTable/TableDataModel';
import {
  InitialTableData,
  useLocalTableData,
} from '../../../components/DataTable/useLocalTableData';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { Contact } from '../../../generated-types/Contact/Contact';
import { useSlabQuery } from '../../../hooks/useSlabQuery';
import { PossiblyAsyncResult } from '../../../utils/Query';
import { NestedKeyOf } from '../../../utils/Types';

type CompanyContactListRow = {
  id: string;
  name: string;
  jobTitle: string | null;
  email: string | null;
  phone: string | null;
};

const buildTableDataModelConfig = ({
  contactsResult,
}: {
  contactsResult: PossiblyAsyncResult<Contact[] | undefined>;
}): InitialTableData<CompanyContactListRow, { id: string }, Contact[] | undefined> => {
  const transformRows = (companyContacts: Contact[] = []): CompanyContactListRow[] =>
    companyContacts.map(
      (c): CompanyContactListRow => ({
        id: c.id,
        name: c.fullName(),
        jobTitle: c.jobTitle,
        email: c.emailAddress,
        phone: c.phoneNumber,
      }),
    );

  const columns: ColumnConfig<CompanyContactListRow, NestedKeyOf<CompanyContactListRow>>[] = [
    {
      id: 'name',
      label: 'Name',
      type: 'string',
      isDisplayed: true,
      formatValueForWeb: (label, row) => FormatLinkCell({ label, link: `/contacts/${row.id}` }),
    },
    {
      id: 'jobTitle',
      label: 'Job title',
      type: 'string',
      isDisplayed: true,
    },
    {
      id: 'email',
      label: 'Email',
      type: 'string',
      isDisplayed: true,
      formatValueForWeb: (label, row) =>
        FormatEmailLinkCell({ label, link: `mailto:${row.email}`, email: row.email }),
    },
    {
      id: 'phone',
      label: 'Phone',
      type: 'string',
      isDisplayed: true,
    },
  ];

  return {
    rowData: contactsResult,
    transformRows,
    columns,
    initialSortPath: 'name',
  };
};

type ContactTableProps = {
  companyId: string;
};

export const ContactTable = ({ companyId }: ContactTableProps): JSX.Element => {
  const contactsResult = useSlabQuery('GET company contacts', {
    pathParams: {
      id: companyId,
    },
  });
  const { isLoading, isError, data: companyContacts } = contactsResult;

  const tableModel = useLocalTableData(buildTableDataModelConfig({ contactsResult }));

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError || companyContacts === undefined) {
    return <div>ERROR</div>;
  }

  return (
    <Box padding='1rem'>
      <Box height='400px'>
        <DataTable tableName='company-contacts' tableModel={tableModel} />
      </Box>
    </Box>
  );
};
