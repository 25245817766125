import { Status } from '@googlemaps/react-wrapper';
import { isLatLngLiteral } from '@googlemaps/typescript-guards';
import { createCustomEqual, deepEqual, TypeEqualityComparator } from 'fast-equals';
import {
  Children,
  cloneElement,
  EffectCallback,
  FunctionComponent,
  isValidElement,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';

import { SlabConfig } from '../../utils/SlabConfig';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';

export const MAP_ZOOM_CLOSE: number = 11;
export const MAP_ZOOM_MID: number = 9;
export const MAP_ZOOM_FAR: number = 4;

/**
 * These custom deep equality components are needed so that the map object can optimize its
 * loading hooks by allowing near equal latitudes and longitudes to act as functionally equal
 * for caching purposes.
 */
const deepEqualForGMaps: TypeEqualityComparator<
  google.maps.Map[keyof google.maps.Map],
  undefined
> = (a, b): boolean => {
  if (
    (isLatLngLiteral(a) || a instanceof google.maps.LatLng) &&
    (isLatLngLiteral(b) || b instanceof google.maps.LatLng)
  ) {
    return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
  }

  return deepEqual(a, b);
};

const deepCompareEqualsForMaps = createCustomEqual({
  createCustomConfig: () => ({ areObjectsEqual: deepEqualForGMaps }),
});

const useDeepCompareMemoize = (value: HTMLDivElement | null): HTMLDivElement | null => {
  const ref = useRef<HTMLDivElement | null>(null);

  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
};

export const UseDeepCompareEffectForMaps = (callback: EffectCallback, dependencies: any[]): void =>
  useEffect(callback, dependencies.map(useDeepCompareMemoize));
/** End of custom equality components */

/**
 * HasMap is an interface describing any components that are a child of a Map
 * component and have a map property.
 */
interface HasMap {
  map: google.maps.Map;
}

/**
 * MapProps is an extention of the normal Properties passed to a map object
 * with properties used to control the embedded custom behavior of this map.
 */
interface MapProps extends google.maps.MapOptions {
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (map: google.maps.Map) => void;
  children?: ReactElement<HasMap>;
  zoom: number;
}

/**
 * Map is the React component that houses the map.
 *
 * The returned component is a fragment that contains a div element and the
 * Map's children. Because of how dom is loaded in react and how the Google
 * Maps React wrapper library is set up, the map must be inserted into the
 * div element via the div's ref property.
 */
export const Map = ({ onClick, onIdle, children, zoom, ...options }: MapProps): JSX.Element => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [map, setMap] = useState<google.maps.Map | null>(null);

  // React doesn't use deep comparisons, so it needs a custom hook
  // see https://github.com/googlemaps/js-samples/issues/946
  UseDeepCompareEffectForMaps(() => {
    if (ref.current !== null && map === null) {
      const newMap = new window.google.maps.Map(ref.current, {
        zoom,
        mapId: SlabConfig.googleMaps.id,
      });
      setMap(newMap);
    }
    map?.setOptions({ ...options, center: options.center ?? { lat: 40, lng: -97 } });
  }, [ref, map]);

  UseDeepCompareEffectForMaps(() => {
    map?.setOptions({ ...options, center: map?.getCenter() });
    if (options.center !== undefined && options.center !== null) {
      map?.panTo(options.center);
    } else {
      map?.panTo({ lat: 40, lng: -97 });
      map?.setZoom(MAP_ZOOM_FAR);
    }
  }, [map, options]);

  UseDeepCompareEffectForMaps(() => {
    if (map !== null) {
      map.setZoom(zoom);
    }
  }, [zoom]);

  UseDeepCompareEffectForMaps(() => {
    if (onClick !== null && onClick !== undefined) {
      map?.addListener('click', onClick);
    }

    if (onIdle !== null && onIdle !== undefined) {
      map?.addListener('idle', () => onIdle(map));
    }
  }, [map]);

  return (
    <>
      <div style={{ minHeight: '30rem' }} id='map' ref={ref} />
      {Children.map(children, (child) => {
        if (isValidElement(child) && map !== null) {
          // set the map prop on the child component
          return cloneElement(child, { map });
        }
        return undefined;
      })}
    </>
  );
};

/**
 * Marker is the React component that displays the standard map marker on Map.
 *
 * Marker must be a child component of Map so that Map can clone itself into the
 * map property of Marker after the map has initialized.
 */
type ClickOptions = {
  location: google.maps.LatLngLiteral | google.maps.LatLng | undefined;
};

export const Marker: FunctionComponent<
  google.maps.marker.AdvancedMarkerElementOptions & ClickOptions
> = ({ location, ...options }: google.maps.marker.AdvancedMarkerElementOptions & ClickOptions) => {
  const [marker, setMarker] = useState<google.maps.marker.AdvancedMarkerElement | null>(null);

  useEffect(() => {
    if (marker != null) {
      marker.map = null;
    }

    const newMarker = new google.maps.marker.AdvancedMarkerElement({
      position: location,
      ...options,
    });
    setMarker(newMarker);

    return (): void => {
      newMarker.map = null;
    };
  }, [location]);

  return null;
};

export const MapRenderer = (status: Status): JSX.Element => {
  if (status === Status.LOADING) {
    return <LoadingSpinner />;
  }
  if (status === Status.FAILURE) {
    return <div>Google Map failed to load</div>;
  }
  return <div />;
};
