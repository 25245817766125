// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import { NIL_UUID } from '../../utils/UUID';
import { Market, NewMarket } from '../Market/Market';
import {
  NewQuotePolicyThreshold,
  QuotePolicyThreshold,
} from '../QuotePolicyThreshold/QuotePolicyThreshold';
import { NewUser, User } from '../User/User';

export class QuotePolicy {
  readonly id: string;
  readonly name: string;
  readonly allMarkets: boolean;
  readonly markets: Market[];
  readonly reviewers: User[];
  readonly thresholds: QuotePolicyThreshold[];

  constructor(data: { [key: string]: any } = {}) {
    this.id = data.id === undefined ? NIL_UUID : data.id;
    this.name = data.name === undefined ? '' : data.name;
    this.allMarkets = data.allMarkets === undefined ? false : data.allMarkets;
    this.markets = (data.markets ?? []).map((markets: any) => NewMarket(markets));
    this.reviewers = (data.reviewers ?? []).map((reviewers: any) => NewUser(reviewers));
    this.thresholds = (data.thresholds ?? []).map((thresholds: any) =>
      NewQuotePolicyThreshold(thresholds),
    );
  }

  static zero(): QuotePolicy {
    const zeroValues: ExcludeMethodsDeep<QuotePolicy> = {
      id: NIL_UUID,
      name: '',
      allMarkets: false,
      markets: [],
      reviewers: [],
      thresholds: [],
    };
    return new QuotePolicy(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  /**
   * reviewerNamesDisplay returns a string containing the full name of every
   * reviewer as a human readable list.
   */
  reviewerNamesDisplay(conjunction: string = 'or'): string {
    const reviewerNames = this.reviewers.map((r) => r.fullName()).sort();
    if (reviewerNames.length < 3) {
      return reviewerNames.join(` ${conjunction} `);
    }
    const allButLast = reviewerNames.slice(0, reviewerNames.length - 1);
    const last = reviewerNames[reviewerNames.length - 1];
    return `${allButLast.join(', ')}, ${conjunction} ${last}`;
  }

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewQuotePolicy = (
  props: PartialDeep<QuotePolicy, { recurseIntoArrays: true }>,
): QuotePolicy => new QuotePolicy(props);

export const NewQuotePolicyFromDomainObject = (
  props: PartialDeep<DomainObject<QuotePolicy>, { recurseIntoArrays: true }>,
): QuotePolicy => new QuotePolicy(props);
