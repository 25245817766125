import { Box, Button, Typography, useTheme } from '@mui/material';
import LogRocket from 'logrocket';

import { FilterByOpts } from '../../../utils/ApiClient';
import { MakeImmediateResult, PossiblyAsyncResult } from '../../../utils/Query';
import { randomUUID } from '../../../utils/UUID';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';

export type FilterLabel<TRow extends { id: string }, TAgg extends { id: string }> = {
  /** The label to display on each tab */
  label: string;
  additionalLabels?: (aggregations: { [id: string]: TAgg }) => string[];
  /** The value to filter against; if null then no filter will be applied */
  filter: FilterByOpts<TRow> | null;
  resultCount?: (aggregations: { [id: string]: TAgg }) => number;
};

export type FilterTabsProps<TRow extends { id: string }, TAgg extends { id: string }> = {
  filters: FilterLabel<TRow, TAgg>[];
  aggregations?: PossiblyAsyncResult<{ [id: string]: TAgg }>;
  handleChangeTab: (filter: FilterLabel<TRow, TAgg>, index: number) => void;
  activeFilter: FilterByOpts<TRow> | null;
};

export const FilterTabs = <TRow extends { id: string }, TAgg extends { id: string }>({
  filters,
  aggregations = MakeImmediateResult({}),
  handleChangeTab,
  activeFilter,
}: FilterTabsProps<TRow, TAgg>): JSX.Element => {
  const theme = useTheme();

  const renderAdditionalLabels = ({
    filter,
    color,
  }: {
    filter: FilterLabel<TRow, TAgg>;
    color: string;
  }): JSX.Element | JSX.Element[] | null => {
    if (filter.additionalLabels === undefined) {
      return null;
    }
    const loadingSpinner = (
      <Box sx={{ lineHeight: 1, marginTop: '4px', marginBottom: '2px' }}>
        <LoadingSpinner size={20} sx={{ color }} color='inherit' wrapInBox={false} />
      </Box>
    );
    // If we are still loading from an API response, show a spinner
    if (aggregations.isLoading || aggregations.data === undefined) {
      return loadingSpinner;
    }
    if (aggregations.isError) {
      // If an error occurred, hide it since it's unclear how to best display it
      // to a user.
      return null;
    }

    // The aggregated data is an empty object. This is an unexpected state.
    // See https://app.shortcut.com/slabstack/story/2821/fix-possible-undefined-in-additionallabels
    if (Object.keys(aggregations.data).length === 0) {
      // Hit a spot where data is not ready, so we don't know what to display
      // This is a temporary state, so we don't want to like, load, and LogRocket.
      // We use error because it will alert in the logrockets alert channel.
      LogRocket.error('Aggregated data is unexpectedly empty', { filter, aggregations });
      return loadingSpinner;
    }

    return filter.additionalLabels(aggregations.data).map((subLabel) => (
      <Typography variant='body2' color={color} key={randomUUID()} noWrap>
        {subLabel}
      </Typography>
    ));
  };

  const renderResultCount = ({
    filter,
    color,
    backgroundColor,
  }: {
    filter: FilterLabel<TRow, TAgg>;
    color: string;
    backgroundColor: string;
  }): JSX.Element | JSX.Element[] | null => {
    if (filter.resultCount === undefined || aggregations.isError) {
      return null;
    }
    return (
      <Box paddingX='0.875rem' borderRadius='0.5rem' height='fit-content' sx={{ backgroundColor }}>
        {aggregations.isLoading || aggregations.data === undefined ? (
          <LoadingSpinner
            size={14}
            sx={{
              margin: 'auto',
              verticalAlign: 'middle',
              color,
            }}
            wrapInBox={false}
          />
        ) : (
          <Typography variant='body3' fontSize='0.875rem' color={color}>
            {filter.resultCount(aggregations.data)}
          </Typography>
        )}
      </Box>
    );
  };

  return (
    // NOTE: We do not use MUI `Tabs` here because:
    //   1. It must have `Tab`s nested underneath, which do not have enough customization
    //   2. If used without `Tab`s, throws prop-injection warnings
    <Box
      display='flex'
      sx={{
        borderBottom: `1px solid ${theme.palette.SlabGray.Stroke}`,
        // -webkit-scrollbar properties for Safari
        '::-webkit-scrollbar': {
          background: theme.palette.background.default,
          height: '10px',
        },
        '::-webkit-scrollbar-thumb': {
          border: `2px solid ${theme.palette.background.default}`,
          borderRadius: '5px',
          background: theme.palette.SlabGray['400'],
          padding: '2px',
        },
        // overflow-x, not overflow, so that we don't get a vertical scrollbar :-D
        overflowX: 'auto',
        // standard scrollbar properties for everyone else
        scrollbarColor: `${theme.palette.SlabGray['400']} ${theme.palette.background.default}`,
        scrollbarWidth: 'thin',
      }}
    >
      {filters.map((f, idx) => {
        const isActive =
          f.filter === null || activeFilter === null
            ? f.filter === activeFilter
            : JSON.stringify(f.filter) === JSON.stringify(activeFilter);
        const additionalLabelColor = isActive
          ? theme.palette.SlabGray['600']
          : theme.palette.SlabGray['500'];
        const resultCountColor = isActive
          ? theme.palette.SlabGray[0]
          : theme.palette.SlabGray['600'];
        const resultCountBackground = isActive
          ? theme.palette.SlabIndigo['500']
          : theme.palette.SlabGray.Stroke;
        return (
          <Button
            key={randomUUID()}
            disableRipple
            id={`project-filter-tab-${idx}`}
            onClick={(): void => handleChangeTab(f, idx)}
            sx={{
              minWidth: 'auto',
              borderRadius: 0,
              borderBottom: isActive ? `1px solid ${theme.palette.SlabGray['800']}` : 'none',
              textTransform: 'none',
            }}
          >
            <Box paddingX='1rem' display='flex' gap='0.625rem'>
              <Box display='flex' flexDirection='column' textAlign='left'>
                <Typography
                  variant='body1'
                  noWrap
                  color={isActive ? theme.palette.SlabGray['800'] : theme.palette.SlabGray['600']}
                >
                  {f.label}
                </Typography>
                {renderAdditionalLabels({ filter: f, color: additionalLabelColor })}
              </Box>
              {renderResultCount({
                filter: f,
                color: resultCountColor,
                backgroundColor: resultCountBackground,
              })}
            </Box>
          </Button>
        );
      })}
    </Box>
  );
};
