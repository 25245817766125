import { Box, Typography } from '@mui/material';

import { ButtonPill } from '../../../components/ButtonPill/ButtonPill';
import {
  FormatErrorCell,
  FormatLinkCell,
} from '../../../components/DataTable/components/SlabTableRowCells';
import { DataTable } from '../../../components/DataTable/DataTable';
import { ColumnConfig } from '../../../components/DataTable/TableDataModel';
import {
  InitialTableData,
  useLocalTableData,
} from '../../../components/DataTable/useLocalTableData';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { Currency } from '../../../generated-types/Currency/Currency';
import Enums from '../../../generated-types/Enums';
import { Units } from '../../../generated-types/generated-units';
import { MixMaterial } from '../../../generated-types/MixMaterial/MixMaterial';
import { useSlabQuery } from '../../../hooks/useSlabQuery';
import { PossiblyAsyncResult } from '../../../utils/Query';
import { NestedKeyOf } from '../../../utils/Types';

type MixMaterialListRow = {
  id: string;
  name: string;
  materialType: Enums.MaterialType;
  quantity: number;
  measurementUnit: string;
  extendedCost: Currency;
  incompatibleBatchUnit: string;
};

const buildTableDataModelConfig = ({
  materialsResult,
}: {
  materialsResult: PossiblyAsyncResult<MixMaterial[] | undefined>;
}): InitialTableData<MixMaterialListRow, { id: string }, MixMaterial[] | undefined> => {
  const transformRows = (mixMaterials: MixMaterial[] = []): MixMaterialListRow[] =>
    mixMaterials.map(
      (mm): MixMaterialListRow => ({
        id: mm.material.id,
        name: mm.material.name,
        materialType: mm.material.materialType,
        quantity: parseFloat(mm.quantity),
        measurementUnit: Units[mm.batchUnit].label,
        extendedCost: mm.extendedCost,
        incompatibleBatchUnit: mm.incompatibleBatchUnit ? 'UOM issue' : '',
      }),
    );

  const columns: ColumnConfig<MixMaterialListRow, NestedKeyOf<MixMaterialListRow>>[] = [
    {
      id: 'name',
      label: 'Name',
      type: 'string',
      isDisplayed: true,
      formatValueForWeb: (label, row) => FormatLinkCell({ label, link: `/materials/${row.id}` }),
    },
    {
      id: 'materialType',
      label: 'Material type',
      type: 'string',
      isDisplayed: true,
    },
    {
      id: 'quantity',
      label: 'Quantity',
      type: 'number',
      isDisplayed: true,
    },
    {
      id: 'measurementUnit',
      label: 'Unit',
      type: 'string',
      isDisplayed: true,
    },
    {
      id: 'extendedCost',
      label: 'Ext. cost',
      type: 'Currency',
      isDisplayed: true,
    },
    {
      id: 'incompatibleBatchUnit',
      label: 'Incompatible batch unit',
      tooltipText:
        'A "UOM issue" mix material’s batch unit is incompatible with its underlying material’s cost unit.',
      type: 'string',
      isDisplayed: true,
      formatValueForWeb: (v) => FormatErrorCell(v),
    },
  ];

  return {
    rowData: materialsResult,
    transformRows,
    columns,
    initialSortPath: 'name',
  };
};

type MaterialTableProps = {
  mixId: string;
  onEditMaterials: () => void;
};

export const MaterialTable = ({ mixId, onEditMaterials }: MaterialTableProps): JSX.Element => {
  const materialsResult = useSlabQuery('GET materials by mix ID', {
    pathParams: {
      id: mixId,
    },
  });
  const { isLoading, isError, data: mixMaterials } = materialsResult;

  const tableModel = useLocalTableData(buildTableDataModelConfig({ materialsResult }));

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError || mixMaterials === undefined) {
    return <div>ERROR</div>;
  }

  return (
    <Box>
      <Box display='flex' justifyContent='space-between' textAlign='center' paddingBottom='1.25rem'>
        <Box alignSelf='center'>
          <Typography variant='h4'>Materials</Typography>
        </Box>
        <ButtonPill
          text='edit materials'
          variant='primary'
          size='small'
          onClick={onEditMaterials}
          icon='edit'
        />
      </Box>

      <Box>
        <DataTable tableName='mix-designs-materials' tableModel={tableModel} />
      </Box>
    </Box>
  );
};
