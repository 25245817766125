// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import { NIL_UUID } from '../../utils/UUID';
import { NewRole, Role } from '../Role/Role';

export class User {
  readonly id: string;
  readonly firstName: string;
  readonly lastName: string | null;
  readonly emailAddress: string;
  readonly phoneNumber: string | null;
  readonly canReceiveEmail: boolean;
  readonly isDeactivated: boolean;
  readonly roles: Role[];

  constructor(data: { [key: string]: any } = {}) {
    this.id = data.id === undefined ? NIL_UUID : data.id;
    this.firstName = data.firstName === undefined ? '' : data.firstName;
    this.lastName = data.lastName === undefined ? null : data.lastName;
    this.emailAddress = data.emailAddress === undefined ? '' : data.emailAddress;
    this.phoneNumber = data.phoneNumber === undefined ? null : data.phoneNumber;
    this.canReceiveEmail = data.canReceiveEmail === undefined ? false : data.canReceiveEmail;
    this.isDeactivated = data.isDeactivated === undefined ? false : data.isDeactivated;
    this.roles = (data.roles ?? []).map((roles: any) => NewRole(roles));
  }

  static zero(): User {
    const zeroValues: ExcludeMethodsDeep<User> = {
      id: NIL_UUID,
      firstName: '',
      lastName: null,
      emailAddress: '',
      phoneNumber: null,
      canReceiveEmail: false,
      isDeactivated: false,
      roles: [],
    };
    return new User(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  fullName(): string {
    return `${this.firstName}${this.lastName === null ? '' : ` ${this.lastName}`}`;
  }

  /**
   * isSelectable decides if this user is able to be selected in either
   * a dropdown, lookup, or multi-lookup component.
   */
  isSelectable(): boolean {
    if (this.firstName === 'Slabstack' && this.lastName === 'Admin') {
      return false;
    }
    if (this.isDeactivated) {
      return false;
    }
    return true;
  }

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewUser = (props: PartialDeep<User, { recurseIntoArrays: true }>): User =>
  new User(props);

export const NewUserFromDomainObject = (
  props: PartialDeep<DomainObject<User>, { recurseIntoArrays: true }>,
): User => new User(props);
