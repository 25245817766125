import { Box, CircularProgress, SxProps, Theme, useTheme } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

type LoadingSpinnerProps = {
  size?: number;
  unit?: 'px' | 'rem';
  color?: string;
  wrapInBox?: boolean;
  sx?: SxProps<Theme>;
};

export const LoadingSpinner = ({
  size = 160,
  unit = 'px',
  color,
  wrapInBox = true,
  sx = {},
}: LoadingSpinnerProps): JSX.Element => {
  const theme = useTheme();
  const c = color === undefined ? theme.palette.SlabGray['950'] : color;
  const s = unit === 'rem' ? size * 16 : size;
  // Constant-in-scaled-pixels line thickness below 24px to ensure legibility,
  // otherwise very slowly increase the line thickness as size increases.
  const t = s < 24 ? (16 / s) * 3.2 : 4.25 - Math.log2(s) / 2.2;
  if (!wrapInBox) {
    return <CircularProgress size={s} sx={{ color: c, ...sx }} thickness={t} />;
  }
  return (
    <Box display='flex' alignItems='center' justifyContent='center' p='1rem' sx={sx}>
      <CircularProgress size={s} sx={{ color: c }} thickness={t} />
      <Box component='span' sx={visuallyHidden}>
        Loading...
      </Box>
    </Box>
  );
};
