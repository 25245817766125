import * as Yup from 'yup';

import { Currency } from '../../generated-types/Currency/Currency';
import Enums from '../../generated-types/Enums';
import { Product } from '../../generated-types/Product/Product';
import { ProjectProduct } from '../../generated-types/ProjectProduct/ProjectProduct';
import { QuoteProduct } from '../../generated-types/QuoteProduct/QuoteProduct';
import { CalculateAggregateTotalPrice } from '../../utils/AggregateCalculations';
import { DomainObject } from '../../utils/ApiClient';
import { DineroString, NullableCurrencyString } from '../../utils/Currency';
import {
  FormikCurrency,
  FormikDeliveryCosts,
  FormikNullableCost,
  FormikNullableCurrency,
} from '../../utils/FormikHelpers';
import {
  nonNegativeCurrency,
  YupCurrency,
  YupDeliveryCosts,
  YupEnum,
  YupNullableCost,
  YupNullableCurrency,
  YupNullableEnum,
  YupNullableNumber,
  YupNullablePercentage,
  YupNullableReference,
  YupNullableString,
  YupNumber,
  YupReference,
  YupSchemaDeliveryCostsType,
  YupSchemaNullableCostType,
  YupSchemaNullableCurrencyType,
  YupSchemaNullableReferenceType,
  YupSchemaReferenceType,
} from '../../utils/YupHelpers';

/**
 * ProjectOrQuoteProductFormikType is a shared formik type that contains data
 * required for both ProjectProduct and QuoteProduct.
 */
export type ProjectOrQuoteProductFormikType = {
  // Fields shared between ProjectProduct and QuoteProduct
  usage: ProjectProduct['usage'] | QuoteProduct['usage'];
  quantity: ProjectProduct['quantity'] | QuoteProduct['quantity'];
  price: DomainObject<ProjectProduct['price'] | QuoteProduct['price']>;

  // QuoteProduct-specific fields
  kind: QuoteProduct['kind'];
  class: QuoteProduct['class'];
  externalName: QuoteProduct['externalName'];

  // Fields shared between ProjectProduct and QuoteProduct, with different form vs domain types
  product: YupSchemaReferenceType & {
    incompatibleMixBatchUnits: boolean;
    allowIncompatibleMixBatchUnits: boolean;
  };
  deliveryCosts: YupSchemaDeliveryCostsType;

  // Manual form-state lever for toggling between money and percentage display.
  // Specifically out of displayOnly to avoid recalculation logic.
  isMoneySwitchOn: boolean;

  // Aggregate-specific fields
  aggHaulTaxable: ProjectProduct['aggHaulTaxable'] & QuoteProduct['aggHaulTaxable'];
  deliveryType: ProjectProduct['deliveryType'] & QuoteProduct['deliveryType'];
  haulOverride: YupSchemaNullableCostType;
  haulRate: YupSchemaNullableCurrencyType;
  minimumHaulCharge: ProjectProduct['minimumHaulCharge'] & QuoteProduct['minimumHaulCharge'];
  taxRate: ProjectProduct['taxRate'] & QuoteProduct['taxRate'];
  truckingType: YupSchemaNullableReferenceType;

  // Aggregate-displayOnly fields
  aggSubtotal: string | null;
  aggTotal: string | null;

  // Display-only formik fields
  displayOnly: {
    suggestedPrice: string | null;
    listPrice: string | null;
    unitCost: string | null;
    actualMarginPercentage: string | null;
    actualMarginCurrency: string | null;
    marginOverMaterialsPercentage: string | null;
    marginOverMaterialsCurrency: string | null;
    targetMargin: string | null;
  } | null;
};

export type ProjectOrQuoteProductEditableFieldsFormikType = Omit<
  ProjectOrQuoteProductFormikType,
  'displayOnly' | 'isMoneySwitchOn' | 'aggSubtotal' | 'aggTotal'
>;

/**
 * MainProjectOrQuoteProductsFormikSchema is the formik schema for the main product
 * section of a Quote or Project. It is used to validate the form data.
 * It operates properly for Primary products.
 */
export const MainProjectOrQuoteProductsFormikSchema: Yup.SchemaOf<
  ProjectOrQuoteProductEditableFieldsFormikType[]
> = Yup.array().of(
  Yup.object({
    product: YupReference('Product', {
      incompatibleMixBatchUnits: Yup.bool().default(false),
      allowIncompatibleMixBatchUnits: Yup.bool().default(false),
    }).test({
      name: 'products have incompatible mix batch units',
      test: (item) => !item.incompatibleMixBatchUnits || item.allowIncompatibleMixBatchUnits,
    }),
    kind: YupEnum(Enums.QuoteProductKind, 'Kind'),
    externalName: YupNullableString('External name'),
    class: YupNullableNumber({ label: 'Class' }),
    quantity: YupNumber({
      label: 'Quantity',
      integer: true,
      positive: true,
    }),
    price: YupCurrency('Price').test(nonNegativeCurrency),
    deliveryCosts: YupDeliveryCosts('Delivery costs'),
    usage: YupNullableString('Usage'),

    // Aggregate-specific fields
    aggHaulTaxable: Yup.bool().default(false),
    deliveryType: YupNullableEnum(Enums.ProductDeliveryType, 'Delivery type'),
    haulOverride: YupNullableCost('Haul override'),
    haulRate: YupNullableCurrency('Haul rate'),
    minimumHaulCharge: YupNullableNumber({ label: 'Minimum haul charge' }),
    taxRate: YupNullablePercentage('Tax rate', 2).test({
      name: 'taxRate-must-not-exceed-999.99',
      test: (item) => (item ?? '') === '' || parseFloat(item ?? '0') <= 999.99,
      message: 'Must not exceed 999.99',
    }),
    truckingType: YupNullableReference('TruckingType').test({
      name: 'truckingType-required-when-delivery-type-is-hauling',
      test: (item, ctx) => {
        if (ctx.parent.deliveryType === Enums.ProductDeliveryType.Hauling) {
          return (item?.id ?? null) !== null;
        }
        return true;
      },
      message: 'Required',
    }),
  }),
);

/**
 * AdditionalProjectOrQuoteProductsFormikSchema is the formik schema for the additional service
 * section of a Quote. It is used to validate the form data.
 * It operates properly for non-Primary products.
 */
export const AdditionalProjectOrQuoteProductsFormikSchema: Yup.SchemaOf<
  ProjectOrQuoteProductEditableFieldsFormikType[]
> = Yup.array().of(
  Yup.object({
    product: YupReference('Product', {
      incompatibleMixBatchUnits: Yup.bool().default(false),
      allowIncompatibleMixBatchUnits: Yup.bool().default(false),
    }).test({
      name: 'products have incompatible mix batch units',
      test: (item) => !item.incompatibleMixBatchUnits || item.allowIncompatibleMixBatchUnits,
    }),
    kind: YupEnum(Enums.QuoteProductKind, 'Kind'),
    externalName: YupNullableString('External name'),
    class: YupNullableNumber({ label: 'Class' }),
    quantity: YupNullableNumber({ label: 'Quantity' }),
    price: YupCurrency('Price').test(nonNegativeCurrency),
    deliveryCosts: YupDeliveryCosts('Delivery costs'),
    usage: YupNullableString('Usage'),

    // Aggregate-specific fields
    aggHaulTaxable: Yup.bool().default(false),
    deliveryType: YupNullableEnum(Enums.ProductDeliveryType, 'Delivery type'),
    haulOverride: YupNullableCost('Haul override'),
    haulRate: YupNullableCurrency('Haul rate'),
    minimumHaulCharge: YupNullableNumber({ label: 'Minimum haul charge' }),
    taxRate: YupNullablePercentage('Tax rate', 2).test({
      name: 'taxRate-must-not-exceed-999.99',
      test: (item) => (item ?? '') === '' || parseFloat(item ?? '0') <= 999.99,
      message: 'Must not exceed 999.99',
    }),
    truckingType: YupNullableReference('TruckingType').test({
      name: 'truckingType-required-when-delivery-type-is-hauling',
      test: (item, ctx) => {
        if (ctx.parent.deliveryType === Enums.ProductDeliveryType.Hauling) {
          return (item?.id ?? null) !== null;
        }
        return true;
      },
      message: 'Required',
    }),
  }),
);

/** Returns the form data for a QuoteProduct's reference to its underlying Product. */
export const FormikProductReference = (
  product: Product,
): ProjectOrQuoteProductFormikType['product'] => {
  const labelPrefix = product.incompatibleMixBatchUnits ? '[UOM issue] ' : '';
  const optionLabel = `${labelPrefix}${product.alternateID}`;
  return {
    id: product.id,
    // If you change this option data, you'll probably also want to make corresponding changes
    // to the lookup transform in ProductSection.
    option: {
      value: product.id,
      label: optionLabel,
      sublabels: [product.name],
    },
    incompatibleMixBatchUnits: product.incompatibleMixBatchUnits,
    allowIncompatibleMixBatchUnits: false,
  };
};

/** Returns the form data for a QuoteProduct. */
export const QuoteProductToProjectOrQuoteProductFormik = (
  qp: QuoteProduct,
): ProjectOrQuoteProductFormikType => {
  const taxRate = parseFloat(qp.taxRate ?? '0') / 100;
  const haulRate = qp.haulRate ?? Currency.zero();
  const aggSubtotal = haulRate.add(qp.price);
  const aggTotal = CalculateAggregateTotalPrice({
    unitPrice: qp.price,
    haulRate,
    taxRate,
    aggHaulTaxable: qp.aggHaulTaxable,
  });

  return {
    product: FormikProductReference(qp.product),
    kind: qp.kind,
    externalName: qp.externalName,
    class: qp.class,
    quantity: qp.quantity,
    price: FormikCurrency(qp.price),
    deliveryCosts: FormikDeliveryCosts(qp.deliveryCosts ?? undefined),
    isMoneySwitchOn: false,
    displayOnly: {
      suggestedPrice: NullableCurrencyString({ cur: qp.calculateSuggestedPrice() }),
      listPrice: NullableCurrencyString({ cur: qp.product.listPrice }),
      actualMarginPercentage: qp.marginAsPercentage(),
      targetMargin: qp.product.targetMarginDisplay(),
      unitCost: DineroString({ dinero: qp.costPerUnit() }),
      actualMarginCurrency: qp.marginAsFormattedCurrency(),
      marginOverMaterialsCurrency: qp.marginOverMaterialAsFormattedCurrency(),
      marginOverMaterialsPercentage: qp.marginOverMaterialAsPercentage(),
    },
    usage: qp.usage,

    // Aggregate-displayOnly fields
    aggSubtotal: NullableCurrencyString({ cur: aggSubtotal }),
    aggTotal: NullableCurrencyString({ cur: aggTotal }),

    // Aggregate-specific fields
    aggHaulTaxable: qp.aggHaulTaxable,
    deliveryType: qp.deliveryType ?? null,
    haulOverride: FormikNullableCost({
      ...qp.haulOverride,
      unit: qp.product.aggregateMaterial?.rawMaterialCost?.unit ?? Enums.Unit.Tn,
    }),
    haulRate: FormikNullableCurrency(qp.haulRate),
    minimumHaulCharge: qp.minimumHaulCharge,
    taxRate: qp.taxRate !== null ? parseFloat(qp.taxRate).toFixed(2) : null,
    truckingType: {
      id: qp.truckingType?.id ?? null,
      option:
        qp.truckingType === null
          ? null
          : {
              value: qp.truckingType.id,
              label: qp.truckingType.name,
            },
    },
  };
};
