import * as Yup from 'yup';

import { Company } from '../../generated-types/Company/Company';
import Enums from '../../generated-types/Enums';
import { DomainObject } from '../../utils/ApiClient';
import { FormikAddress } from '../../utils/FormikHelpers';
import { NIL_UUID } from '../../utils/UUID';
import {
  TestForUndefined,
  YupAddress,
  YupEnum,
  YupNullableReference,
  YupNullableString,
  YupSchemaNullableReferenceType,
  YupString,
} from '../../utils/YupHelpers';

export type CompanyFormikType = DomainObject<Omit<Company, 'dispatchCustomer' | 'market'>> & {
  dispatchCustomer: YupSchemaNullableReferenceType;
  market: YupSchemaNullableReferenceType;
  displayOnly: {
    creditStatusCode: string | null;
    creditStatusDescription: string | null;
  };
};

export const CompanySchema: Yup.SchemaOf<Omit<CompanyFormikType, 'id' | 'externalID'>> =
  Yup.object()
    .shape({
      name: YupString('Name'),
      alternateID: YupNullableString('Alternate ID'),
      address: YupAddress('Address'),
      category: YupEnum(Enums.CompanyCategory, 'Category'),
      notes: YupNullableString('Notes'),
      dispatchCustomer: YupNullableReference('Dispatch Customer'),
      market: YupNullableReference('Market'),
      displayOnly: Yup.object({
        creditStatusCode: YupNullableString('Credit status code'),
        creditStatusDescription: YupNullableString('Credit status description'),
      }),
    })
    .test(TestForUndefined('CompanySchema'));

export const FormikCompany = (companyData: Partial<Company> | undefined): CompanyFormikType => ({
  id: companyData?.id ?? NIL_UUID,
  externalID: companyData?.externalID ?? null,
  name: companyData?.name ?? '',
  alternateID: companyData?.alternateID ?? null,
  address: FormikAddress(companyData?.address),
  category: companyData?.category ?? Enums.CompanyCategory.Contractor,
  notes: companyData?.notes ?? null,
  dispatchCustomer: {
    id: companyData?.dispatchCustomer?.id ?? null,
    option:
      companyData?.dispatchCustomer === undefined || companyData?.dispatchCustomer === null
        ? null
        : {
            value: companyData.dispatchCustomer.id,
            label: companyData.dispatchCustomer.name,
            sublabels: [companyData.dispatchCustomer.code],
          },
  },
  market: {
    id: companyData?.market?.id ?? null,
    option:
      companyData?.market === undefined || companyData?.market === null
        ? null
        : {
            value: companyData.market.id,
            label: companyData.market.name,
          },
  },
  displayOnly: {
    creditStatusCode: companyData?.dispatchCustomer?.creditStatusCode ?? null,
    creditStatusDescription: companyData?.dispatchCustomer?.creditStatusDescription ?? null,
  },
});
