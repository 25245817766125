import * as Yup from 'yup';

import { Contact } from '../../generated-types/Contact/Contact';
import { DomainObject } from '../../utils/ApiClient';
import { FormikTel } from '../../utils/FormikHelpers';
import { NIL_UUID } from '../../utils/UUID';
import {
  TestForUndefined,
  YupNullableEmail,
  YupNullableString,
  YupNullableTel,
  YupReference,
  YupSchemaReferenceType,
  YupString,
} from '../../utils/YupHelpers';

export type ContactFormikType = DomainObject<Omit<Contact, 'company'>> & {
  company: YupSchemaReferenceType;
};

export const ContactSchema: Yup.SchemaOf<Omit<ContactFormikType, 'id'>> = Yup.object({
  firstName: YupString('First name'),
  lastName: YupString('Last name'),
  emailAddress: YupNullableEmail('Email'),
  phoneNumber: YupNullableTel('Phone'),
  jobTitle: YupNullableString('Job title'),
  notes: YupNullableString('notes'),

  company: YupReference('Company'),
}).test(TestForUndefined('ContactSchema'));

export const FormikContact = (
  contactData: Partial<Contact> | undefined,
  usesDispatchCustomer: boolean,
): ContactFormikType => ({
  id: contactData?.id ?? NIL_UUID,
  firstName: contactData?.firstName ?? '',
  lastName: contactData?.lastName ?? '',
  emailAddress: contactData?.emailAddress ?? null,
  phoneNumber: FormikTel(contactData?.phoneNumber ?? undefined),
  jobTitle: contactData?.jobTitle ?? null,
  notes: contactData?.notes ?? null,
  company: {
    id: contactData?.company?.id ?? null,
    option:
      contactData?.company === undefined
        ? null
        : {
            value: contactData.company.id,
            label: contactData.company.name,
            sublabels: contactData.company.lookupSublabels(usesDispatchCustomer),
          },
  },
});
