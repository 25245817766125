import { Button, FormControlLabel, TableFooter, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Edit2 } from 'iconsax-react';
import _ from 'lodash';
import { DateTime } from 'luxon';
import React, { useContext, useEffect, useState } from 'react';

import Enums from '../../generated-types/Enums';
import { useDebouncedEffect } from '../../hooks/useDebouncedEffect';
import { SlabContext } from '../../SlabContext';
import { FilterByOpts } from '../../utils/ApiClient';
import { NestedKeyOf, Path } from '../../utils/Types';
import { NIL_UUID, randomUUID } from '../../utils/UUID';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { useToast } from '../Toast/useToast';
import { FilterLabel, FilterTabs } from './components/FilterTabs';
import { SlabTableRowCells } from './components/SlabTableRowCells';
import { EnhancedTableHead } from './components/TableHead';
import { EnhancedTableToolbar } from './components/Toolbar';
import { Column, ColumnConfig, TableDataModel } from './TableDataModel';

const ROWS_PER_PAGE_OPTS = [10, 25, 50];

const getInitialSortOrder = <TRow extends { id: string }>(
  columns: ReadonlySet<Column<TRow>>,
  id: NestedKeyOf<TRow>,
): Enums.SortDirection => {
  const column = [...columns].find((col) => col.id === id);
  return column?.initialSortOrder ?? Enums.SortDirection.Ascending;
};

export type FilterCheckbox<TRow extends { id: string }> = {
  /** Labels must be unique amongst the array. */
  label: string;
  filter: FilterByOpts<TRow>;
  /** @default false */
  defaultChecked?: boolean;
};

type DataTableProps<TRow extends { id: string }, TAgg extends { id: string }> = {
  tableName?: string;
  tableModel: TableDataModel<TRow, TAgg>;
  /**
   * Given the currently filtered rows (after tab + free search filtering is applied),
   * construct a set of rows to keep sticky at the bottom of the table.
   */
  constructStickyRows?: (aggregations: { [id: string]: TAgg }) => TRow[];
  /**
   * If true, include select checkboxes.
   *
   * @default false
   */
  includeSelect?: boolean;
  /**
   * If true, include tight edit button.
   *
   * @default false
   */
  onEditPress?: (value: TRow) => void;

  /**
   * @default []
   */
  tabFilters?: FilterLabel<TRow, TAgg>[];
  /**
   * The initially selected FilterLabel. If none is specified, the first label
   * is the default. If specified, tabFilters must also be specified and this
   * initialTabFilter must be an element of tabFilters.
   */
  initialTabFilter?: FilterLabel<TRow, TAgg>;
  initialSortBy?: { name: NestedKeyOf<TRow>; direction: Enums.SortDirection };
  initialFilterBy?: FilterByOpts<TRow>[];

  additionalTabAggregationFilters?: FilterByOpts<TRow>[];
  additionalStickyRowAggregationFilters?: FilterByOpts<TRow>[];

  /**
   * `marketId` MUST be an included hidden cell value to use the market selector.
   * If it is not provided, the filter will not render.
   */
  marketSelectorOpts?: Omit<
    NonNullable<Parameters<typeof EnhancedTableToolbar>[0]['marketSelectorOpts']>,
    'selectedMarketId'
  >;

  /**
   * `plantId` MUST be an included hidden cell value to use the plant selector.
   * If it is not provided, the filter will not render.
   */
  plantSelectorOpts?: Omit<
    NonNullable<Parameters<typeof EnhancedTableToolbar>[0]['plantSelectorOpts']>,
    'selectedPlantId'
  >;

  /** @default '65vh' */
  tableMaxHeight?: string;
  /** @default undefined */
  includePrint?: {
    title: string;
  };

  /** Optional array of filter-capable checkboxes. */
  filterCheckboxes?: Array<FilterCheckbox<TRow>>;
  onClearFilter?: () => void;
  /**
   * If true, include No markets.
   *
   * @default false
   */
  includeNoMarkets?: boolean;
};

type DataTablePreservedState<TRow extends { id: string }, TAgg extends { id: string }> = {
  name: string;
  sort?: { name: string; direction: Enums.SortDirection };
  searchFilter?: FilterByOpts<TRow>;
  marketFilter?: FilterByOpts<TRow>;
  plantFilter?: FilterByOpts<TRow>;
  yearFilter?: FilterByOpts<TRow>;
  tabFilterLabel?: FilterLabel<TRow, TAgg>;
  renderedColumns?: Column<TRow>[];
};

export type SharedDataTableProps<TRow extends { id: string }, TAgg extends { id: string }> = Omit<
  DataTableProps<TRow, TAgg>,
  'tableModel'
> & {
  /**
   * Optional column override to update the label or isDisplayed property.
   *
   * If `null` is passed in, the column is omitted.
   */
  optionalColumnOverrides?: ColumnConfig<TRow, NestedKeyOf<TRow>>[];
  optionalColumnRemovals?: NestedKeyOf<TRow>[];
};

type ApplyColumnChangesParams<TRow extends { id: string }, TAgg extends { id: string }> = Pick<
  SharedDataTableProps<TRow, TAgg>,
  'optionalColumnOverrides' | 'optionalColumnRemovals'
> & {
  columns: ColumnConfig<TRow, NestedKeyOf<TRow>>[];
};

/**
 * Given an array of columns, overrides, and omissions, return the expected columns
 * using the override and omission values passed.
 */
export const ApplyColumnChanges = <TRow extends { id: string }, TAgg extends { id: string }>({
  columns,
  optionalColumnOverrides,
  optionalColumnRemovals,
}: ApplyColumnChangesParams<TRow, TAgg>): ColumnConfig<TRow, NestedKeyOf<TRow>>[] =>
  columns
    .map((col) => {
      if (optionalColumnRemovals !== undefined && optionalColumnRemovals.includes(col.id)) {
        return null;
      }
      const matchingColOverride = optionalColumnOverrides?.find(
        (colOverride) => colOverride?.id !== undefined && colOverride.id === col.id,
      );
      // Null was passed in, so omit it.
      if (matchingColOverride === null) {
        return null;
      }
      if (matchingColOverride !== undefined && matchingColOverride.id === col.id) {
        return matchingColOverride;
      }
      // No override so use the default value
      return col;
    })
    .filter((col): col is ColumnConfig<TRow, NestedKeyOf<TRow>> => col !== null);

export const DataTable = <TRow extends { id: string }, TAgg extends { id: string }>({
  tableName,
  tableModel: tableData,
  constructStickyRows,
  includeSelect = false,
  onEditPress,
  tabFilters = [],
  initialTabFilter,
  initialSortBy,
  initialFilterBy,
  additionalTabAggregationFilters = [],
  additionalStickyRowAggregationFilters = [],
  marketSelectorOpts,
  plantSelectorOpts,
  tableMaxHeight = '65vh',
  includePrint,
  filterCheckboxes = [],
  onClearFilter = undefined,
  includeNoMarkets,
}: DataTableProps<TRow, TAgg>): JSX.Element => {
  const theme = useTheme();

  const ctx = useContext(SlabContext);
  const isPrintingEnabled = ctx.userInfo.hasFlags([Enums.FeatureFlagName.DataTablePrinting]);
  const usesLocalStorage = ctx.userInfo.hasFlags([Enums.FeatureFlagName.FeatureFlagLocalStorage]);

  const [filterMarketId, setFilterMarketId] = useState<string | null>('');
  const [selectedRowIds, setSelectedRowIds] = useState<readonly string[]>([]);
  // The rawSearchString is immediately updated to reflect the current value of the search text input, and then a
  // debounced update effect propagates its changes into tableData's filters.
  const [rawSearchString, setRawSearchString] = useState('');
  const [checkBoxMap, setCheckBoxMap] = useState(() => {
    // When initializing the checkBoxMap (AKA only on first render), asynchronously add the default-enabled checkbox
    // filters to the table model for processing.  This is asynchronous to defer the filter change until immediately
    // after the current rendering pass finishes, because React assumes this change might affect the containing node's
    // rendering and that containing node has already been (partially) rendered by the time we get here.
    setTimeout(async () => {
      await tableData.addFilters(
        ...filterCheckboxes.filter((fcb) => fcb.defaultChecked).map((fcb) => fcb.filter),
      );
    }, 0);
    // Return the initial state of checkBoxMap.
    return Object.fromEntries(
      filterCheckboxes.map((fcb) => [
        fcb.label,
        { config: fcb, enabled: fcb.defaultChecked ?? false },
      ]),
    );
  });
  const [tabFilterLabel, setTabFilterLabel] = useState<FilterLabel<TRow, TAgg> | undefined>(
    initialTabFilter ?? tabFilters[0],
  );
  const { showToast, toast } = useToast('');

  // Debounce search input text
  useDebouncedEffect(
    async (): Promise<void> => {
      const searchStringFilter: FilterByOpts<TRow> = {
        operation: Enums.FilterOperation.Search,
        value: rawSearchString,
      };
      if (rawSearchString === '') {
        await tableData.removeFilters(searchStringFilter);
      } else {
        await tableData.addFilters(searchStringFilter);
      }
    },
    [rawSearchString],
    200,
  );

  // checkbox filters: initial value
  const rowFilters: FilterByOpts<TRow>[] = [...Object.values(checkBoxMap)]
    .filter(({ enabled }) => enabled)
    .map(({ config }) => config.filter);
  const tabAggregationFilters = [...rowFilters, ...additionalTabAggregationFilters];
  const stickyRowAggregationFilters = [...rowFilters, ...additionalStickyRowAggregationFilters];

  // tab filter: initial value
  if (tabFilterLabel === undefined || tabFilterLabel.filter === null) {
    // Remove all tabs' filters.
    const filters = tabFilters
      .filter((tf) => tf.filter !== null)
      .map((tf) => tf.filter) as FilterByOpts<TRow>[];
    // Note: removeFilters may process asynchronously, but we can't `await` at the top level of a React component.
    tableData.removeFilters(...filters);
  } else {
    // Note: addFilters may process asynchronously, but we can't `await` at the top level of a React component.
    tableData.addFilters(tabFilterLabel.filter);
    rowFilters.push(tabFilterLabel.filter);
    stickyRowAggregationFilters.push(tabFilterLabel.filter);
    // Don't include the active tab's filter when calculating aggregations to be displayed in the tabs, since each tab
    // wants to show an aggregation of the results that would be visible if the tab filter was changed to that tab.
  }

  // Year lookup filter
  const yearFilter = tableData.getFilter({
    name: 'year' as Path<TRow>,
    operation: Enums.FilterOperation.Equals,
  });

  // Market lookup filter
  const currentMarketIdFilter = tableData.getFilter({
    name: 'marketId' as Path<TRow>,
    operation: Enums.FilterOperation.Equals,
  });
  useEffect(() => {
    if (currentMarketIdFilter !== undefined) {
      stickyRowAggregationFilters.push(currentMarketIdFilter);
      tabAggregationFilters.push(currentMarketIdFilter);
    }
    const filteredMarketId = (currentMarketIdFilter as { value?: string | null })?.value ?? null;
    setFilterMarketId(filteredMarketId);
  }, []);

  const createMarketFilter = (
    marketId: string | null,
    operation: Enums.FilterOperation,
  ): FilterByOpts<TRow> => ({
    name: 'marketId' as Path<TRow>,
    operation,
    value: marketId ?? '',
  });

  const setFilteredMarketId = async (marketId: string | null): Promise<void> => {
    setFilterMarketId(marketId);

    const marketFilter = createMarketFilter(marketId, Enums.FilterOperation.Equals);
    const marketNullFilter = createMarketFilter(marketId, Enums.FilterOperation.EqualsOrNull);

    if (marketId === null) {
      await tableData.removeFilters(marketFilter);
      await tableData.removeFilters(marketNullFilter);
    } else if (marketId === NIL_UUID) {
      await tableData.replaceFilters([marketFilter], [marketNullFilter]);
    } else {
      await tableData.replaceFilters([marketNullFilter], [marketFilter]);
    }
  };

  // Plant lookup filter
  const currentPlantIdFilter = tableData.getFilter({
    name: 'plantId' as Path<TRow>,
    operation: Enums.FilterOperation.Equals,
  });
  if (currentPlantIdFilter !== undefined) {
    stickyRowAggregationFilters.push(currentPlantIdFilter);
    tabAggregationFilters.push(currentPlantIdFilter);
  }
  const filteredPlantId = (currentPlantIdFilter as { value?: string | null })?.value ?? null;
  const setFilteredPlantId = async (plantId: string | null): Promise<void> => {
    const plantFilter: FilterByOpts<TRow> = {
      name: 'plantId' as Path<TRow>,
      operation: Enums.FilterOperation.Equals,
      value: plantId ?? '',
    };
    if (plantId === null) {
      await tableData.removeFilters(plantFilter);
    } else {
      await tableData.addFilters(plantFilter);
    }
  };

  const { data: pageRows, isFetched, isFetching, isLoading, isSuccess } = tableData.currentPage;

  const setCheckBoxFilterState = (map: {
    [key: string]: { config: FilterCheckbox<TRow>; enabled: boolean };
  }): void => {
    setCheckBoxMap({ ...checkBoxMap, ...map });
    const filtersToAdd: FilterByOpts<TRow>[] = [];
    const filtersToRemove: FilterByOpts<TRow>[] = [];
    Object.entries(map).forEach(([, { config, enabled }]) => {
      if (enabled) {
        filtersToAdd.push(config.filter);
      } else {
        filtersToRemove.push(config.filter);
      }
    });
    tableData.replaceFilters(filtersToRemove, filtersToAdd);
  };

  const setTabFilter = async (filterLabel: FilterLabel<TRow, TAgg>): Promise<void> => {
    const { filter } = filterLabel;
    if (filter === null && tabFilterLabel !== undefined && tabFilterLabel.filter !== null) {
      await tableData.removeFilters(tabFilterLabel.filter);
    } else if (filter === null) {
      // don't change tableData's filters: transitioning from null filter to null filter
    } else if (tabFilterLabel !== undefined && tabFilterLabel.filter !== null) {
      await tableData.replaceFilters([tabFilterLabel.filter], [filter]);
    } else {
      await tableData.addFilters(filter);
    }
    setTabFilterLabel(filterLabel);
  };

  const toggleOrder = (current: Enums.SortDirection): Enums.SortDirection =>
    current === Enums.SortDirection.Descending
      ? Enums.SortDirection.Ascending
      : Enums.SortDirection.Descending;

  const handleRequestSort = async (
    event: React.MouseEvent<unknown>,
    property: NestedKeyOf<TRow>,
  ): Promise<void> => {
    const order =
      tableData.sortConfig?.name === property
        ? toggleOrder(tableData.sortConfig.direction)
        : getInitialSortOrder(tableData.renderedColumns, property);
    await tableData.setSortConfig({ direction: order, name: property });
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.checked) {
      const newSelected = (pageRows ?? []).map((r) => r.id);
      setSelectedRowIds(newSelected);
      return;
    }
    setSelectedRowIds([]);
  };

  const handleSelectClick = (event: React.MouseEvent<unknown>, rowUuid: string): void => {
    let newSelected: string[];
    if (selectedRowIds.includes(rowUuid)) {
      newSelected = selectedRowIds.filter((uuid) => uuid !== rowUuid);
    } else {
      newSelected = [...selectedRowIds, rowUuid];
    }

    setSelectedRowIds(newSelected);
  };

  const handleChangePage = async (event: unknown, newPage: number): Promise<void> => {
    await tableData.setPageIndex(newPage);
  };

  const handleChangeRowsPerPage = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    await tableData.setRowCountPerPage(parseInt(event.target.value, 10));
  };

  const isSelected = (name: string | number): boolean =>
    selectedRowIds.indexOf(String(name)) !== -1;

  let stickyRows: TRow[] | undefined;
  if (constructStickyRows !== undefined) {
    stickyRows = constructStickyRows(tableData.aggregations.data);
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    tableData.pageIndex === 0 ? 0 : tableData.rowCountPerPage - (pageRows ?? []).length;

  // The number of logical columns in the table, including special built-in columns for selection checkboxes
  // or edit buttons.  Note that the necessary colSpan for a full-width table row is twice as large.
  const columnCount =
    tableData.renderedColumns.size + (includeSelect ? 1 : 0) + (onEditPress !== undefined ? 1 : 0);

  const renderMessageBody = ({
    message,
    height = '15rem',
  }: {
    message: string | JSX.Element;
    height?: string;
  }): JSX.Element => (
    <TableBody>
      <TableRow>
        <TableCell sx={{ height, textAlign: 'center' }} colSpan={2 * columnCount}>
          {typeof message === 'string' ? <Typography variant='h5'>{message}</Typography> : message}
        </TableCell>
      </TableRow>
    </TableBody>
  );

  const renderTableBody = (): JSX.Element => {
    if (isLoading && !isFetched) {
      const heightInRows = Math.max(8, emptyRows + (pageRows ?? []).length);
      return renderMessageBody({ message: <LoadingSpinner />, height: `${2.5 * heightInRows}rem` });
    }
    if (!isSuccess || pageRows === undefined) {
      // Looking at isSuccess to detect errors instead of isError, because isError is false when react-query
      // retries the request if the window loses and regains focus.
      return renderMessageBody({ message: 'Failed to load this page of the table' });
    }
    if (tableData.renderedColumns.size <= 0) {
      return renderMessageBody({ message: 'No columns selected' });
    }
    if (pageRows.length === 0) {
      return renderMessageBody({ message: 'No rows' });
    }
    return (
      <TableBody>
        {pageRows.map((row, index) => {
          const isItemSelected = isSelected(row.id);
          const labelId = `enhanced-table-checkbox-${index}`;

          return (
            <TableRow
              hover
              role='checkbox'
              aria-checked={isItemSelected}
              tabIndex={-1}
              key={row.id}
              selected={isItemSelected}
            >
              {includeSelect === true && (
                <React.Fragment key={randomUUID()}>
                  <TableCell padding='checkbox'>
                    <Checkbox
                      color='primary'
                      checked={isItemSelected}
                      inputProps={{
                        'aria-labelledby': labelId,
                      }}
                      onClick={(event): void => handleSelectClick(event, row.id)}
                    />
                  </TableCell>
                  <TableCell />
                </React.Fragment>
              )}
              {onEditPress !== undefined && (
                <React.Fragment key={randomUUID()}>
                  <TableCell padding='checkbox'>
                    <Button
                      disableRipple
                      sx={{ minWidth: 'auto', position: 'unset' }}
                      onClick={(): void => onEditPress(row)}
                    >
                      <Edit2 />
                    </Button>
                  </TableCell>
                  <TableCell padding='none' />
                </React.Fragment>
              )}

              <SlabTableRowCells
                renderedColumns={tableData.renderedColumns}
                row={row}
                labelId={labelId}
              />
            </TableRow>
          );
        })}
        {emptyRows > 0 && renderMessageBody({ message: '', height: `${2.5 * emptyRows}rem` })}
      </TableBody>
    );
  };

  useEffect(() => {
    if (tableName !== undefined && usesLocalStorage) {
      const tablePreservedState = localStorage.getItem(tableName);
      if (tablePreservedState !== null) {
        const {
          sort,
          searchFilter,
          marketFilter,
          plantFilter,
          tabFilterLabel: preservedTabFilterLabel,
          renderedColumns,
          yearFilter: preservedYearFilter,
        }: DataTablePreservedState<TRow, TAgg> = JSON.parse(tablePreservedState);

        if (sort !== undefined) {
          tableData.setSortConfig({ ...sort, name: sort.name as Path<TRow> });
        }

        const newFilters: FilterByOpts<TRow>[] = [];
        if (marketFilter !== undefined) {
          newFilters.push(marketFilter);
          const filteredMarketId = (marketFilter as { value?: string | null })?.value ?? null;
          setFilterMarketId(filteredMarketId);
        }
        if (plantFilter !== undefined) {
          newFilters.push(plantFilter);
        }
        if (preservedYearFilter !== undefined) {
          newFilters.push(preservedYearFilter);
        }
        if (preservedTabFilterLabel !== undefined) {
          const { filter } = preservedTabFilterLabel;
          if (filter !== null) {
            newFilters.push(filter);
          }
          setTabFilterLabel(preservedTabFilterLabel);
        }
        if (searchFilter !== undefined) {
          newFilters.push(searchFilter);
          setRawSearchString(searchFilter.value as string);
        }
        if (newFilters.length > 0) {
          tableData.addFilters(...newFilters);
        }

        if (renderedColumns !== undefined && renderedColumns.length > 0) {
          const columns: string[] = [];
          renderedColumns.forEach((value) => {
            columns.push(value.id);
          });

          tableData.allColumns.forEach((valueAll) => {
            tableData.setIsColumnRendered(valueAll.id, _.includes(columns, valueAll.id));
          });
        }
      }
    }
  }, []);

  const onSave = (): void => {
    if (tableName !== undefined) {
      const searchFilter = tableData.getFilter({
        operation: Enums.FilterOperation.Search,
      });

      const columns: Column<TRow>[] = [];
      tableData.renderedColumns.forEach((value) => {
        columns.push(value);
      });
      const tablePreservedState: DataTablePreservedState<TRow, TAgg> = {
        name: tableName,
        sort: tableData.sortConfig,
        searchFilter,
        marketFilter: currentMarketIdFilter,
        plantFilter: currentPlantIdFilter,
        yearFilter,
        tabFilterLabel,
        renderedColumns: columns,
      };
      localStorage.setItem(`${tableName}`, JSON.stringify(tablePreservedState));
      showToast('success', 'Filters saved');
    }
  };

  const onClear = (): void => {
    if (tableName !== undefined) {
      localStorage.removeItem(tableName);
      setRawSearchString('');
      tableData.setSortConfig(tableData.initialSortConfig);
      setTabFilterLabel(initialTabFilter ?? tabFilters[0]);
      if (onClearFilter !== undefined) onClearFilter();

      const columns: Column<TRow>[] = [];
      tableData.renderedColumns.forEach((value) => {
        columns.push(value);
      });

      const removeFilters: FilterByOpts<TRow>[] = [];
      if (currentMarketIdFilter !== undefined) {
        removeFilters.push(currentMarketIdFilter);
        setFilterMarketId(null);
      }
      if (currentPlantIdFilter !== undefined) {
        removeFilters.push(currentPlantIdFilter);
      }
      if (tabFilterLabel !== undefined && tabFilterLabel.filter !== null) {
        removeFilters.push(tabFilterLabel.filter);
      }
      if (removeFilters.length > 0) {
        tableData.removeFilters(...removeFilters);
      }

      const newFilters: FilterByOpts<TRow>[] = [];
      if (yearFilter !== undefined) {
        newFilters.push({ ...yearFilter, value: DateTime.now().year.toString() });
      }

      if (initialFilterBy !== undefined) {
        newFilters.push(...initialFilterBy);
      }
      if (newFilters.length > 0) {
        tableData.addFilters(...newFilters);
      }
      if (initialSortBy !== undefined) {
        tableData.setSortConfig(initialSortBy);
      }
      if (tableData.allColumns.size > 0) {
        tableData.allColumns.forEach((value) => {
          tableData.setIsColumnRendered(value.id, value.isDisplayed);
        });
      }
    }
  };

  return (
    <>
      {toast}
      {tabFilters.length > 0 && (
        <>
          <FilterTabs
            filters={tabFilters}
            aggregations={tableData.aggregations}
            activeFilter={tabFilterLabel?.filter ?? null}
            handleChangeTab={(f): void => {
              if (f !== tabFilterLabel) {
                setTabFilter(f);
              }
            }}
          />
          {filterCheckboxes.length === 0 && <Box paddingBottom='1.25rem' />}
        </>
      )}
      {filterCheckboxes.length !== 0 && (
        <Box display='flex' justifyContent='flex-end'>
          <Box display='flex' flexDirection='row'>
            {filterCheckboxes.map((fcb) => (
              <Box key={`datatable-checkbox-${fcb.label}`} display='flex' alignItems='center'>
                <FormControlLabel
                  id={fcb.label}
                  label={fcb.label}
                  control={
                    <Checkbox
                      color='primary'
                      checked={checkBoxMap[fcb.label].enabled}
                      inputProps={{
                        'aria-labelledby': fcb.label,
                      }}
                      onClick={(): void => {
                        setCheckBoxFilterState({
                          ...checkBoxMap,
                          [fcb.label]: {
                            config: fcb,
                            enabled: !checkBoxMap[fcb.label].enabled,
                          },
                        });
                      }}
                    />
                  }
                />
              </Box>
            ))}
          </Box>
        </Box>
      )}
      <Box
        sx={{
          th: {
            whiteSpace: 'nowrap',
          },
        }}
      >
        <EnhancedTableToolbar
          rawSearchString={rawSearchString}
          onSearch={(v): void => {
            setRawSearchString(v.toLowerCase());
          }}
          allColumns={tableData.allColumns}
          renderedColumns={tableData.renderedColumns}
          isFetchingRows={isFetching}
          includeNoMarkets={includeNoMarkets}
          printOpts={
            isPrintingEnabled && includePrint !== undefined
              ? {
                  getAllRows: tableData.getRowsForAllPagesWithPotentiallyPoorPerformance,
                  tableName: includePrint.title,
                }
              : undefined
          }
          onColumnChange={tableData.setIsColumnRendered}
          plantSelectorOpts={
            plantSelectorOpts === undefined
              ? undefined
              : {
                  enabled: plantSelectorOpts.enabled,
                  selectedPlantId: filteredPlantId,
                  onPlantChange: (curPlantId: string | null): void => {
                    setFilteredPlantId(curPlantId);
                    plantSelectorOpts?.onPlantChange?.(curPlantId);
                  },
                  plantOpts: plantSelectorOpts?.plantOpts,
                }
          }
          marketSelectorOpts={
            marketSelectorOpts === undefined
              ? undefined
              : {
                  enabled: marketSelectorOpts.enabled,
                  selectedMarketId: filterMarketId,
                  onMarketChange: (curMarketId: string | null): void => {
                    setFilteredMarketId(curMarketId);
                    marketSelectorOpts?.onMarketChange?.(curMarketId);
                  },
                  marketOpts: marketSelectorOpts?.marketOpts,
                }
          }
          {...(tableName !== undefined && { tableName })}
          onSave={onSave}
          onClear={onClear}
        />
        <Paper sx={{ overflow: 'auto', mb: 2 }}>
          <TableContainer sx={{ maxHeight: tableMaxHeight }}>
            <Table
              aria-labelledby='tableTitle'
              sx={{
                borderCollapse: 'separate',
              }}
            >
              <EnhancedTableHead
                headCells={tableData.renderedColumns}
                numSelected={selectedRowIds.length}
                sortConfig={tableData.sortConfig}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={tableData.rowCount.data}
                includeSelect={includeSelect}
                includeEdit={onEditPress !== undefined}
              />
              {renderTableBody()}
              {stickyRows !== undefined && !!pageRows.length && (
                <TableFooter>
                  {stickyRows.map((stickyRow) => (
                    <TableRow key={randomUUID()}>
                      {includeSelect === true && (
                        <React.Fragment key={randomUUID()}>
                          <TableCell padding='checkbox' />
                          <TableCell padding='checkbox' />
                        </React.Fragment>
                      )}
                      {onEditPress !== undefined && (
                        <React.Fragment key={randomUUID()}>
                          <TableCell padding='checkbox' />
                          <TableCell padding='checkbox' />
                        </React.Fragment>
                      )}
                      <SlabTableRowCells
                        renderedColumns={tableData.renderedColumns}
                        row={stickyRow}
                        labelId={`sticky-table-header-${randomUUID()}`}
                      />
                    </TableRow>
                  ))}
                </TableFooter>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={ROWS_PER_PAGE_OPTS}
            component='div'
            count={tableData.rowCount.data}
            rowsPerPage={tableData.rowCountPerPage}
            page={tableData.pageIndex}
            labelDisplayedRows={({ from, to, count }): string =>
              `${from.toLocaleString()}–${to.toLocaleString()} of ${count.toLocaleString()}`
            }
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              backgroundColor: theme.palette.SlabGray['50'],
              '> .MuiToolbar-root': {
                alignItems: 'baseline',
                minHeight: 0,
              },
              '& p': {
                fontSize: 'inherit',
                lineHeight: 1,
                margin: 0,
              },
            }}
          />
        </Paper>
        {/* Necessary box padding - if row count increases, margin + padding are not respected during the expansion */}
        <Box height='2rem' />
      </Box>
    </>
  );
};
