// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

// webgen:import { "defaultName": "Dinero", "path": "dinero.js" }
// webgen:import { "names": ["CalculateOtherCost", "CalculateSuggestedPrice"], "path": "../../utils/CostCalculations" }
// webgen:import { "wildcardName": "CurrencyHelpers", "path": "../../utils/Currency" }

import Dinero from 'dinero.js';
import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { CalculateOtherCost, CalculateSuggestedPrice } from '../../utils/CostCalculations';
import * as CurrencyHelpers from '../../utils/Currency';
import { ExcludeMethodsDeep } from '../../utils/Types';
import { NIL_UUID } from '../../utils/UUID';
import { Currency, NewCurrency } from '../Currency/Currency';
import Enums from '../Enums';
import { Material, NewMaterial } from '../Material/Material';
import { Mix, NewMix } from '../Mix/Mix';
import { NewPlant, Plant } from '../Plant/Plant';

export class Product {
  readonly id: string;
  readonly alternateID: string;
  readonly name: string;
  readonly category: Enums.ProductCategory;
  readonly cuydVolume: number | null;
  readonly measurementUnit: Enums.Unit;
  readonly listPrice: Currency | null;
  readonly targetMargin: string;
  readonly minimumMargin: string;
  readonly materialCostOverride: Currency | null;
  readonly otherCostOverride: Currency | null;
  readonly mix: Mix | null;
  readonly resaleMaterial: Material | null;
  readonly aggregateMaterial: Material | null;
  readonly plant: Plant;
  readonly externalID: string | null;
  readonly materialCost: Currency;
  readonly otherCost: Currency;
  readonly incompatibleMixBatchUnits: boolean;

  constructor(data: { [key: string]: any } = {}) {
    this.id = data.id === undefined ? NIL_UUID : data.id;
    this.alternateID = data.alternateID === undefined ? '' : data.alternateID;
    this.name = data.name === undefined ? '' : data.name;
    this.category = data.category === undefined ? Enums.ProductCategory.Other : data.category;
    this.cuydVolume = data.cuydVolume === undefined ? null : data.cuydVolume;
    this.measurementUnit =
      data.measurementUnit === undefined ? Enums.Unit.Ac : data.measurementUnit;
    this.listPrice = (data.listPrice ?? null) === null ? null : NewCurrency(data.listPrice);
    this.targetMargin = data.targetMargin === undefined ? '0' : data.targetMargin;
    this.minimumMargin = data.minimumMargin === undefined ? '0' : data.minimumMargin;
    this.materialCostOverride =
      (data.materialCostOverride ?? null) === null ? null : NewCurrency(data.materialCostOverride);
    this.otherCostOverride =
      (data.otherCostOverride ?? null) === null ? null : NewCurrency(data.otherCostOverride);
    this.mix =
      // eslint-disable-next-line no-nested-ternary
      (data.mix ?? null) === null ? null : NewMix(data.mix);
    this.resaleMaterial =
      // eslint-disable-next-line no-nested-ternary
      (data.resaleMaterial ?? null) === null ? null : NewMaterial(data.resaleMaterial);
    this.aggregateMaterial =
      // eslint-disable-next-line no-nested-ternary
      (data.aggregateMaterial ?? null) === null ? null : NewMaterial(data.aggregateMaterial);
    this.plant = NewPlant(data.plant);
    this.externalID =
      // eslint-disable-next-line no-nested-ternary
      (data.externalID ?? null) === null
        ? null
        : data.externalID === undefined
          ? ''
          : data.externalID;
    this.materialCost = NewCurrency(data.materialCost);
    this.otherCost = NewCurrency(data.otherCost);
    this.incompatibleMixBatchUnits =
      data.incompatibleMixBatchUnits === undefined ? false : data.incompatibleMixBatchUnits;
  }

  static zero(): Product {
    const zeroValues: ExcludeMethodsDeep<Product> = {
      id: NIL_UUID,
      alternateID: '',
      name: '',
      category: Enums.ProductCategory.Other,
      cuydVolume: null,
      measurementUnit: Enums.Unit.Ac,
      listPrice: null,
      targetMargin: '0',
      minimumMargin: '0',
      materialCostOverride: null,
      otherCostOverride: null,
      mix: null,
      resaleMaterial: null,
      aggregateMaterial: null,
      plant: Plant.zero(),
      externalID: null,
      materialCost: Currency.zero(),
      otherCost: Currency.zero(),
      incompatibleMixBatchUnits: false,
    };
    return new Product(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  /** Returns materialCostOverride if set, otherwise materialCost. */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getMaterialCost() {
    return this.materialCostOverride ?? this.materialCost;
  }

  /** Returns the otherCostOverride if set, otherwise the server-calculated otherCost. */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getOtherCost() {
    return this.otherCostOverride ?? this.otherCost;
  }

  /** Returns the material costs + other costs as a Dinero. This uses the server-calculated other costs. */
  costPerUnit(): Dinero.Dinero {
    const materialCostDinero = CurrencyHelpers.CurrencyDinero(this.getMaterialCost());
    const otherCostDinero = CurrencyHelpers.CurrencyDinero(this.getOtherCost());
    return materialCostDinero.add(otherCostDinero);
  }

  /** Returns target margin as a string with 3-digit precision && trailing zeros trimmed. */
  targetMarginDisplay(): string {
    // TODO: Replace this with decimal precision in #492
    return `${String(parseFloat((parseFloat(this.targetMargin) * 100).toFixed(3)))}%`;
  }

  /** Returns minimum margin as a string with 3-digit precision && trailing zeros trimmed. */
  minimumMarginDisplay(): string {
    // TODO: Replace this with decimal precision in #492
    return `${String(parseFloat((parseFloat(this.minimumMargin) * 100).toFixed(3)))}%`;
  }

  /**
   * Returns the suggested material cost as a Currency | null.
   *
   * This checks the selected mix or material to dynamically
   * populate a suggested price during creation/editing of a Product.
   */
  suggestedMaterialCostCurrency(): Currency | null {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const materialCostCurrency = (() => {
      if (this.category === Enums.ProductCategory.Mix) {
        return this.mix?.materialCost ?? null;
      }
      // This product measurement unit must match the resale material's inProductCost unit.
      if (this.measurementUnit === this.resaleMaterial?.inProductCost?.unit) {
        return this.resaleMaterial?.inProductCost?.amount ?? null;
      }
      // This product measurement unit must match the resale material's inProductCost unit.
      if (this.measurementUnit === this.aggregateMaterial?.inProductCost?.unit) {
        return this.aggregateMaterial?.inProductCost?.amount ?? null;
      }

      // The product is not a mix, resale, or aggregate material.
      // Or if it is a resale/aggregate material, the units do not match.
      return null;
    })();

    return materialCostCurrency;
  }

  /**
   * Returns the suggested material cost as a string | null.
   *
   * This checks the selected mix or material to dynamically
   * populate a suggested price during creation/editing of a Product.
   */
  suggestedMaterialCostDisplay(): string | null {
    return CurrencyHelpers.NullableCurrencyString({
      cur: this.suggestedMaterialCostCurrency(),
      omitNull: false,
    });
  }

  /** Returns the product's non-material cost (this is otherCostOverride if set, otherwise the calculated cost). */
  calculateOtherCost(): Currency | null {
    return CalculateOtherCost({
      product: this,
      deliveryCosts: this.plant.deliveryCosts,
      otherCostOverride: this.otherCostOverride,
    });
  }

  /** Returns the product's calculated non-material cost, without respecting otherCostOverride. */
  calculateSuggestedOtherCost(): Currency | null {
    return CalculateOtherCost({
      product: this,
      deliveryCosts: this.plant.deliveryCosts,
      otherCostOverride: null,
    });
  }

  /** Returns a suggested price for the material, combining material and other costs with the target margin. */
  calculateSuggestedPrice(): Currency | null {
    return CalculateSuggestedPrice({
      product: this,
      deliveryCosts: this.plant.deliveryCosts,
      otherCostOverride: this.otherCostOverride,
    });
  }

  margin(): number | null {
    if (
      this.listPrice === undefined ||
      this.listPrice === null ||
      this.listPrice.number === '' ||
      parseFloat(this.listPrice.number) <= 0
    ) {
      return null;
    }
    const totalCost = this.costPerUnit().toUnit();
    const listPrice = CurrencyHelpers.CurrencyDinero(this.listPrice).toUnit();
    const marginNumber = (1 - totalCost / listPrice) * 100;

    // TODO: Replace this with decimal precision in #492
    return parseFloat(marginNumber.toFixed(3));
  }

  /** Returns the margin as a percentage string | null */
  marginDisplay(): string | null {
    const thisMargin = this.margin();
    return thisMargin === null ? null : `${thisMargin.toLocaleString()}%`;
  }

  /** Returns the margin over material as a percentage string | null */
  marginOverMaterialDisplay(): string | null {
    if (
      this.listPrice === undefined ||
      this.listPrice === null ||
      this.listPrice.number === '' ||
      parseFloat(this.listPrice.number) <= 0
    ) {
      return null;
    }
    const materialCost = CurrencyHelpers.CurrencyDinero(this.getMaterialCost()).toUnit();
    const listPrice = CurrencyHelpers.CurrencyDinero(this.listPrice).toUnit();
    const marginOverMaterialNumber = (1 - materialCost / listPrice) * 100;

    // TODO: Replace this with decimal precision in #492
    return `${parseFloat(marginOverMaterialNumber.toFixed(3)).toLocaleString()}%`;
  }

  /** externalName returns the product name in a user-friendly displayable format */
  externalName(): string {
    if (this.alternateID === '') {
      return this.name;
    }

    // [(] and [)]: Match the literal parentheses.
    // .*?: Match any character (except for line terminators)
    //      between zero and unlimited times, as few times as possible.
    // \\b${this.alternateID}\\b: Match the alternateID as a whole word.
    // no flags: only first instance and case-sensitive match.
    const pattern = new RegExp(` [(].*?\\b${this.alternateID}\\b.*?[)]`, '');

    return this.name.replace(pattern, '');
  }

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewProduct = (props: PartialDeep<Product, { recurseIntoArrays: true }>): Product =>
  new Product(props);

export const NewProductFromDomainObject = (
  props: PartialDeep<DomainObject<Product>, { recurseIntoArrays: true }>,
): Product => new Product(props);
