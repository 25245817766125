// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import { NIL_UUID } from '../../utils/UUID';
import Enums from '../Enums';
import { NewQuotePolicy, QuotePolicy } from '../QuotePolicy/QuotePolicy';

export class QuotePolicyViolation {
  readonly id: string;
  readonly state: Enums.QuotePolicyViolationState;
  readonly quotePolicy: QuotePolicy;

  constructor(data: { [key: string]: any } = {}) {
    this.id = data.id === undefined ? NIL_UUID : data.id;
    this.state = data.state === undefined ? Enums.QuotePolicyViolationState.Pending : data.state;
    this.quotePolicy = NewQuotePolicy(data.quotePolicy);
  }

  static zero(): QuotePolicyViolation {
    const zeroValues: ExcludeMethodsDeep<QuotePolicyViolation> = {
      id: NIL_UUID,
      state: Enums.QuotePolicyViolationState.Pending,
      quotePolicy: QuotePolicy.zero(),
    };
    return new QuotePolicyViolation(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************
  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewQuotePolicyViolation = (
  props: PartialDeep<QuotePolicyViolation, { recurseIntoArrays: true }>,
): QuotePolicyViolation => new QuotePolicyViolation(props);

export const NewQuotePolicyViolationFromDomainObject = (
  props: PartialDeep<DomainObject<QuotePolicyViolation>, { recurseIntoArrays: true }>,
): QuotePolicyViolation => new QuotePolicyViolation(props);
