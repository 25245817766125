import _ from 'lodash';
import LogRocket from 'logrocket';
import { useReducer, useState } from 'react';

import Enums from '../../generated-types/Enums';
import { FilterByOpts } from '../../utils/ApiClient';
import { HashSet } from '../../utils/HashSet';
import { MakeImmediateResult, PossiblyAsyncResult } from '../../utils/Query';
import { ExcludeFromUnion, NestedKeyOf } from '../../utils/Types';
import { getComparator, SortConfig } from './components/SortHelpers';
import {
  Column,
  ColumnConfig,
  ColumnFromConfig,
  HashFilter,
  TableDataModel,
  UseReducerParams,
} from './TableDataModel';

export type InitialTableData<TRow extends { id: string }, TAgg extends { id: string }, TApi> = {
  columns: ColumnConfig<TRow, NestedKeyOf<TRow>>[];
  rowData: PossiblyAsyncResult<TApi | undefined>;
  transformRows: (data: TApi) => TRow[];
  initialSortPath: SortConfig<TRow>['name'];

  /** @default 50 */
  rowCountPerPage?: number;
  /** @default [] */
  filters?: FilterByOpts<TRow>[];
  aggregateRows?: (data: { filteredRows: TRow[]; rawData: TApi | undefined }) => {
    [id: string]: TAgg;
  };
};

export type LocalTableData<TRow extends { id: string }, TAgg extends { id: string }, TApi> = {
  readonly allColumnsById: Map<string, Column<TRow>>;
  readonly allRows: TRow[];
  renderedColumns: Column<TRow>[];
  viewableRows: TRow[];
  rowCountPerPage: number;
  pageIndex: number;
  sortConfig: SortConfig<TRow>;
  initialSortConfig: SortConfig<TRow>;
  filters: HashSet<ExcludeFromUnion<FilterByOpts<TRow>, 'value'>, FilterByOpts<TRow>>;
  aggregateRows: (data: { filteredRows: TRow[]; rawData: TApi | undefined }) => {
    [id: string]: TAgg;
  };
};

export type Action<TRow extends { id: string }> =
  | {
      type: 'set_page_index';
      pageIndex: number;
    }
  | {
      type: 'set_rows_per_page';
      rowCountPerPage: number;
    }
  | {
      type: 'set_all_rows';
      allRows: TRow[];
    }
  | {
      type: 'set_sort_config';
      sortConfig: SortConfig<TRow>;
    }
  | {
      type: 'set_column_is_rendered';
      columnId: NestedKeyOf<TRow>;
      isRendered: boolean;
    }
  | {
      type: 'clear_filters';
    }
  | {
      type: 'add_filters';
      filters: FilterByOpts<TRow>[];
    }
  | {
      type: 'remove_filters';
      filters: FilterByOpts<TRow>[];
    }
  | {
      type: 'replace_filters';
      old: FilterByOpts<TRow>[];
      new: FilterByOpts<TRow>[];
    };

const applySorting = <TRow extends { id: string }, TAgg extends { id: string }, TApi>(
  state: LocalTableData<TRow, TAgg, TApi>,
): LocalTableData<TRow, TAgg, TApi> => {
  const column = [...state.allColumnsById.values()].find((col) => col.id === state.sortConfig.name);
  if (column === undefined) {
    return state;
  }
  const compare = getComparator(state.sortConfig.direction, column.toSort);
  return {
    ...state,
    viewableRows: [...state.viewableRows].sort((a, b) => compare(a, b)),
  };
};

const filterRows = <TRow extends { id: string }>(
  allColumns: Column<TRow>[],
  allRows: TRow[],
  filters: FilterByOpts<TRow>[],
): TRow[] =>
  filters.reduce((rows: TRow[], filter: FilterByOpts<TRow>) => {
    switch (filter.operation) {
      case Enums.FilterOperation.Equals:
        return rows.filter((row) => _.get(row, filter.name) === filter.value);
      case Enums.FilterOperation.NotEquals:
        return rows.filter((row) => _.get(row, filter.name) !== filter.value);
      case Enums.FilterOperation.Search: {
        const searchQuery = filter.value.toLocaleLowerCase();
        const formatters = allColumns.map((c) => c.toText);
        return rows.filter((row) =>
          formatters.some((format): boolean =>
            format(row).toLocaleLowerCase().includes(searchQuery),
          ),
        );
      }
      default:
        LogRocket.error(
          `unknown table filter operation: ${(filter as FilterByOpts<TRow>).operation}`,
        );
        return rows;
    }
  }, allRows);

const applyFilters = <TRow extends { id: string }, TAgg extends { id: string }, TApi>(
  state: LocalTableData<TRow, TAgg, TApi>,
): LocalTableData<TRow, TAgg, TApi> =>
  applySorting({
    ...state,
    viewableRows: filterRows([...state.allColumnsById.values()], state.allRows, [...state.filters]),
  });

const tableDataReducer = <TRow extends { id: string }, TAgg extends { id: string }, TApi>(
  state: LocalTableData<TRow, TAgg, TApi>,
  action: Action<TRow>,
): LocalTableData<TRow, TAgg, TApi> => {
  const returnValue = ((): LocalTableData<TRow, TAgg, TApi> => {
    switch (action.type) {
      case 'set_page_index':
        return { ...state, pageIndex: action.pageIndex };
      case 'set_rows_per_page':
        return { ...state, rowCountPerPage: action.rowCountPerPage, pageIndex: 0 };
      case 'set_all_rows':
        // Relies on the fact that applyFilters also sorts the resulting array.
        // This will need to be updated if that ever changes.
        return applyFilters({ ...state, allRows: action.allRows });
      case 'set_sort_config':
        return applySorting({ ...state, sortConfig: action.sortConfig, pageIndex: 0 });
      case 'set_column_is_rendered':
        if (action.isRendered) {
          return {
            ...state,
            // Filter from all columns instead of simply concatenating the newly visible column
            // in order to maintain the column ordering from the original column configs.
            renderedColumns: [...state.allColumnsById.values()].filter(
              (c) => c.id === action.columnId || state.renderedColumns.includes(c),
            ),
          };
        }
        return {
          ...state,
          renderedColumns: state.renderedColumns.filter((c) => c.id !== action.columnId),
        };
      case 'clear_filters':
        return applyFilters({ ...state, filters: new HashSet(HashFilter), pageIndex: 0 });
      case 'add_filters':
        return applyFilters({
          ...state,
          filters: new HashSet(HashFilter, [...state.filters, ...action.filters]),
          pageIndex: 0,
        });
      case 'remove_filters': {
        const filters = new HashSet<
          ExcludeFromUnion<FilterByOpts<TRow>, 'value'>,
          FilterByOpts<TRow>
        >(HashFilter, state.filters);
        if (
          action.filters.reduce<boolean>(
            (didDelete, filter) => filters.delete(filter) || didDelete,
            /* didDelete= */ false,
          )
        ) {
          return applyFilters({ ...state, filters, pageIndex: 0 });
        }
        // no changes actually occurred, so return the original state
        return state;
      }
      case 'replace_filters': {
        const filters = new HashSet<
          ExcludeFromUnion<FilterByOpts<TRow>, 'value'>,
          FilterByOpts<TRow>
        >(HashFilter, state.filters);
        action.old.forEach((filter) => {
          filters.delete(filter);
        });
        action.new.forEach((filter) => {
          filters.add(filter);
        });
        return applyFilters({ ...state, filters, pageIndex: 0 });
      }
      default:
        LogRocket.error(`unknown action supplied to useLocalTableData: ${JSON.stringify(action)}`);
        throw new Error('unknown action');
    }
  })();
  return returnValue;
};

const getInitialSortOrder = <TRow extends { id: string }>(
  columnConfigs: Column<TRow>[],
  id: NestedKeyOf<TRow>,
): Enums.SortDirection => {
  const column = columnConfigs.find((col) => col.id === id);
  return column?.initialSortOrder ?? Enums.SortDirection.Ascending;
};

const localTableDataFromInput = <TRow extends { id: string }, TAgg extends { id: string }, TApi>({
  columns,
  rowData,
  transformRows,
  rowCountPerPage = 50,
  initialSortPath,
  filters = [],
  aggregateRows = (): { [id: string]: TAgg } => ({}),
}: InitialTableData<TRow, TAgg, TApi>): LocalTableData<TRow, TAgg, TApi> => {
  const allColumnsById = new Map(columns.map(ColumnFromConfig).map((col) => [col.id, col]));
  // Don't rebuild duplicate columns so that folks can use reference equality on columns across visibility changes.
  const renderedColumns = columns
    .filter((col) => col.isDisplayed)
    .map((col) => allColumnsById.get(col.id) as Column<TRow>);
  const rows =
    rowData.isLoading || rowData.isError || rowData.data === undefined
      ? []
      : transformRows(rowData.data);
  // Relies on the fact that filter also always sorts.
  // If we optimize that away, then we'll need to explicitly sort here.
  return applyFilters({
    allColumnsById,
    renderedColumns,
    allRows: rows,
    viewableRows: rows,
    rowCountPerPage,
    pageIndex: 0,
    sortConfig: {
      name: initialSortPath,
      direction: getInitialSortOrder(renderedColumns, initialSortPath),
    },
    initialSortConfig: {
      name: initialSortPath,
      direction: getInitialSortOrder(renderedColumns, initialSortPath),
    },
    filters: new HashSet(HashFilter, filters),
    aggregateRows,
  });
};

export type UseLocalDataReducerParams<
  TRow extends { id: string },
  TAgg extends { id: string },
  TApi,
> = UseReducerParams<
  LocalTableData<TRow, TAgg, TApi>,
  Action<TRow>,
  InitialTableData<TRow, TAgg, TApi>
>;

export const LocalTableDataApi = <TRow extends { id: string }, TAgg extends { id: string }, TApi>(
  input: InitialTableData<TRow, TAgg, TApi>,
  setUpReducer: (
    ...args: UseLocalDataReducerParams<TRow, TAgg, TApi>
  ) => [LocalTableData<TRow, TAgg, TApi>, (action: Action<TRow>) => void],
  setUpState: (data: boolean) => [boolean, (value: boolean) => void],
): TableDataModel<TRow, TAgg> => {
  const [tableData, dispatchChange] = setUpReducer(
    tableDataReducer<TRow, TAgg, TApi>,
    input,
    localTableDataFromInput<TRow, TAgg, TApi>,
  );

  const [isFetchingRows, setIsFetchingRows] = setUpState(input.rowData.isLoading);
  if (!isFetchingRows && (input.rowData.isLoading || input.rowData.isRefetching)) {
    setIsFetchingRows(true);
  } else if (isFetchingRows && !input.rowData.isLoading && !input.rowData.isRefetching) {
    if (!input.rowData.isError && input.rowData.data !== undefined) {
      dispatchChange({ type: 'set_all_rows', allRows: input.transformRows(input.rowData.data) });
    }
    setIsFetchingRows(false);
  }

  return {
    get rowCount(): PossiblyAsyncResult<number> {
      return MakeImmediateResult(tableData.viewableRows.length);
    },
    get allColumns(): ReadonlySet<Column<TRow>> {
      return new Set([...tableData.allColumnsById.values()]);
    },
    get renderedColumns(): ReadonlySet<Column<TRow>> {
      return new Set([...tableData.renderedColumns]);
    },
    async setIsColumnRendered(columnId: NestedKeyOf<TRow>, isRendered: boolean): Promise<void> {
      dispatchChange({ type: 'set_column_is_rendered', columnId, isRendered });
    },
    get aggregations(): PossiblyAsyncResult<{ [id: string]: TAgg }> {
      const rows = filterRows([...tableData.allColumnsById.values()], tableData.allRows, []);
      return MakeImmediateResult(
        tableData.aggregateRows({ filteredRows: rows, rawData: input.rowData.data }),
      );
    },
    get pageIndex(): number {
      return tableData.pageIndex;
    },
    get currentPage(): PossiblyAsyncResult<TRow[]> {
      return MakeImmediateResult(
        tableData.viewableRows.slice(
          tableData.pageIndex * tableData.rowCountPerPage,
          (tableData.pageIndex + 1) * tableData.rowCountPerPage,
        ),
      );
    },
    async setPageIndex(pageIndex: number): Promise<void> {
      dispatchChange({ type: 'set_page_index', pageIndex });
    },
    async getRowsForAllPagesWithPotentiallyPoorPerformance(): Promise<TRow[]> {
      return [...tableData.viewableRows];
    },
    get sortConfig(): SortConfig<TRow> {
      return tableData.sortConfig;
    },
    get initialSortConfig(): SortConfig<TRow> {
      return tableData.initialSortConfig;
    },
    async setSortConfig(sortConfig: SortConfig<TRow>): Promise<void> {
      dispatchChange({ type: 'set_sort_config', sortConfig });
    },
    get rowCountPerPage(): number {
      return tableData.rowCountPerPage;
    },
    async setRowCountPerPage(rowCountPerPage: number): Promise<void> {
      dispatchChange({ type: 'set_rows_per_page', rowCountPerPage });
    },
    /** Get the filter that includes the specified configuration, if there is one. */
    getFilter(
      hashData: ExcludeFromUnion<FilterByOpts<TRow>, 'value'>,
    ): FilterByOpts<TRow> | undefined {
      return tableData.filters.get(hashData);
    },
    /** Add filters, or update reference values for existing filters with the same operation and path. */
    async addFilters(...filters: FilterByOpts<TRow>[]): Promise<void> {
      const addFilters = filters.filter((f) => {
        const existing = tableData.filters.get(f);
        return existing === undefined || JSON.stringify(existing) !== JSON.stringify(f);
      });
      if (addFilters.length) {
        dispatchChange({ type: 'add_filters', filters: addFilters });
      }
    },
    /** Remove filters, matching on operation and path. */
    async removeFilters(...filters: FilterByOpts<TRow>[]): Promise<void> {
      const removeFilters = filters.filter((f) => tableData.filters.get(f) !== undefined);
      if (removeFilters.length) {
        dispatchChange({ type: 'remove_filters', filters: removeFilters });
      }
    },
    /** Remove all filters. */
    async clearFilters(): Promise<void> {
      if (tableData.filters.size > 0) {
        dispatchChange({ type: 'clear_filters' });
      }
    },
    /**
     * Add and replace filters in a single update.
     *
     * This is semantically equivalent to calling removeFilters followed by addFilters, but it coalesces
     * the associated re-rendering into a single update.
     */
    async replaceFilters(
      removeFilters: Iterable<FilterByOpts<TRow>>,
      addFilters: Iterable<FilterByOpts<TRow>>,
    ): Promise<void> {
      const newFilters = [...addFilters].filter((f) => {
        const existing = tableData.filters.get(f);
        return existing === undefined || JSON.stringify(existing) !== JSON.stringify(f);
      });
      const oldFilters = [...removeFilters].filter((f) => tableData.filters.get(f) !== undefined);
      if (newFilters.length || oldFilters.length) {
        dispatchChange({ type: 'replace_filters', old: [...oldFilters], new: [...newFilters] });
      }
    },
  };
};

export const useLocalTableData = <TRow extends { id: string }, TAgg extends { id: string }, TApi>(
  input: InitialTableData<TRow, TAgg, TApi>,
): TableDataModel<TRow, TAgg> =>
  LocalTableDataApi(
    input,
    useReducer as Parameters<typeof LocalTableDataApi<TRow, TAgg, TApi>>[1],
    useState<boolean>,
  );
