import { Box, Typography } from '@mui/material';

import { FormatLinkCell } from '../../../components/DataTable/components/SlabTableRowCells';
import { DataTable } from '../../../components/DataTable/DataTable';
import { ColumnConfig } from '../../../components/DataTable/TableDataModel';
import {
  ApiTableDataWithoutAggregationsParams,
  useApiTableDataWithoutAggregations,
} from '../../../components/DataTable/useApiTableData';
import Enums from '../../../generated-types/Enums';
import { Mix } from '../../../generated-types/Mix/Mix';
import { MixSummary } from '../../../generated-types/MixSummary/MixSummary';
import { QueryRouteBarrelTypes } from '../../../utils/ApiClient';
import { NestedKeyOf } from '../../../utils/Types';

const buildTableDataModelConfig = ({
  mix,
}: {
  mix: Mix;
}): ApiTableDataWithoutAggregationsParams<
  MixSummary,
  'GET mix summaries',
  QueryRouteBarrelTypes['GET mix summaries']
> => {
  const columns: ColumnConfig<MixSummary, NestedKeyOf<MixSummary>>[] = [
    {
      id: 'alternateId',
      label: 'Mix ID',
      type: 'string',
      isDisplayed: true,
    },
    {
      id: 'name',
      label: 'Name',
      type: 'string',
      isDisplayed: true,
      formatValueForWeb: (label, row) => FormatLinkCell({ label, link: `/mixdesigns/${row.id}` }),
    },
    {
      id: 'plantName',
      label: 'Plant',
      type: 'string',
      isDisplayed: true,
    },
    {
      id: 'psi',
      label: 'PSI',
      type: 'string',
      isDisplayed: true,
    },
    {
      id: 'materialCost',
      label: 'Cost per unit',
      type: 'string',
      isDisplayed: true,
    },
    {
      id: 'slump',
      label: 'Slump',
      type: 'string',
      isDisplayed: false,
    },
    {
      id: 'aggregateSize',
      label: 'Aggregate size',
      type: 'string',
      isDisplayed: false,
    },
    {
      id: 'waterCementPercentage',
      label: 'Water cement ratio',
      type: 'string',
      isDisplayed: false,
    },
    {
      id: 'flyAshPercentage',
      label: 'Fly ash percentage',
      type: 'string',
      isDisplayed: false,
    },
  ];

  return {
    rowsRouteKey: 'GET mix summaries',
    extractRows: (list) => list.items,
    initialSortBy: {
      name: 'plantName',
      direction: Enums.SortDirection.Ascending,
    },
    initialFilterBy: [
      { operation: Enums.FilterOperation.NotEquals, name: 'id', value: mix.id },
      { operation: Enums.FilterOperation.Equals, name: 'name', value: mix.name },
    ],
    makeColumnConfigs: () => columns,
  };
};

type SimilarMixDesignTableProps = {
  mix: Mix;
  onEditPress: (value: MixSummary) => void;
};

export const SimilarMixDesignTable = ({
  mix,
  onEditPress,
}: SimilarMixDesignTableProps): JSX.Element => {
  const tableModel = useApiTableDataWithoutAggregations(buildTableDataModelConfig({ mix }));

  return (
    <Box>
      <Box display='flex' justifyContent='space-between' textAlign='center' paddingBottom='1.25rem'>
        <Box alignSelf='center'>
          <Typography variant='h4'>Similar Mix Designs</Typography>
        </Box>
      </Box>

      <Box height='400px'>
        <DataTable
          tableName='mix-designs-similar'
          tableModel={tableModel}
          onEditPress={onEditPress}
        />
      </Box>
    </Box>
  );
};
