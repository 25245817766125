// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.
/* eslint-disable camelcase */ // webgen:keep
/* eslint-disable no-useless-constructor */ // webgen:keep
/* eslint-disable @typescript-eslint/no-unused-vars */ // webgen:keep
/* eslint-disable no-empty-function */ // webgen:keep
import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';

export class SendMessageOutput {
  constructor(data: { [key: string]: any } = {}) {}

  static zero(): SendMessageOutput {
    const zeroValues: ExcludeMethodsDeep<SendMessageOutput> = {};
    return new SendMessageOutput(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewSendMessageOutput = (
  props: PartialDeep<SendMessageOutput, { recurseIntoArrays: true }>,
): SendMessageOutput => new SendMessageOutput(props);

export const NewSendMessageOutputFromDomainObject = (
  props: PartialDeep<DomainObject<SendMessageOutput>, { recurseIntoArrays: true }>,
): SendMessageOutput => new SendMessageOutput(props);
