import ReactPDF, { Text, View } from '@react-pdf/renderer';

import { Quote } from '../../../../generated-types/Quote/Quote';
import { randomUUID } from '../../../../utils/UUID';
import { PdfColumnConfig, PdfColumnFromConfig, PdfTable } from '../../../components/PdfTable';
import { PdfTextBlock } from '../../../components/PdfTextBlock';
import { PdfSpacer } from '../../components/PdfSpacer';
import { PdfTextWithLine } from '../../components/PdfTextWithLine';

/** Most text on this page is the same style */
const defaultTextStyle: ReactPDF.TextProps['style'] = {
  fontFamily: 'Calibri',
  fontSize: '10px',
};

const formatElementForPdf = (value: JSX.Element): JSX.Element => value;

const styledText = (text: string, style: ReactPDF.TextProps['style']): JSX.Element => (
  <Text style={style}>{text}</Text>
);

type SignatureRow = {
  id: string;
  leftValue: JSX.Element;
  rightValue: JSX.Element;
};

const Table1 = (): JSX.Element => {
  const signatureRows: SignatureRow[] = [
    {
      id: randomUUID(),
      leftValue: <PdfTextWithLine text='Name of Project:' textStyle={defaultTextStyle} />,
      rightValue: <PdfTextWithLine text='OWNER/AGENCY:' underline textStyle={defaultTextStyle} />,
    },
    {
      id: randomUUID(),
      leftValue: <PdfTextWithLine text='Address:' textStyle={defaultTextStyle} />,
      rightValue: <PdfTextWithLine text='Address:' textStyle={defaultTextStyle} />,
    },
    {
      id: randomUUID(),
      leftValue: <PdfTextWithLine text='' />,
      rightValue: <PdfTextWithLine text='' />,
    },
    {
      id: randomUUID(),
      leftValue: (
        <PdfTextWithLine text='Job# _______________ Total Yards:' textStyle={defaultTextStyle} />
      ),
      rightValue: <PdfTextWithLine text='Contact Person:' textStyle={defaultTextStyle} />,
    },
  ];

  const signatureCols: PdfColumnConfig<SignatureRow>[] = [
    {
      id: 'leftValue',
      label: '',
      type: 'custom',
      isDisplayed: false,
      formatValueForPdf: formatElementForPdf as (value: JSX.Element | string | null) => JSX.Element,
    },
    {
      id: 'rightValue',
      label: '',
      type: 'custom',
      isDisplayed: false,
      formatValueForPdf: formatElementForPdf as (value: JSX.Element | string | null) => JSX.Element,
    },
  ];

  return (
    <PdfTable columns={signatureCols.map((col) => PdfColumnFromConfig(col))} rows={signatureRows} />
  );
};

const Table2 = (): JSX.Element => {
  const signatureRows: SignatureRow[] = [
    {
      id: randomUUID(),
      leftValue: styledText('FOR PUBLIC IMPROVEMENT:', {
        ...defaultTextStyle,
        fontStyle: 'bold',
        textDecoration: 'underline',
        paddingLeft: 0,
      }),
      rightValue: <PdfTextWithLine text='Agency:' textStyle={defaultTextStyle} />,
    },
    {
      id: randomUUID(),
      leftValue: <PdfTextWithLine text='Contract #:' textStyle={defaultTextStyle} />,
      rightValue: <PdfTextWithLine text='Address:' textStyle={defaultTextStyle} />,
    },
    {
      id: randomUUID(),
      leftValue: (
        <PdfTextWithLine
          text="Comptroller's Registration Number:"
          borderBottom='none'
          textStyle={defaultTextStyle}
        />
      ),
      rightValue: <PdfTextWithLine text='' />,
    },
    {
      id: randomUUID(),
      leftValue: <PdfTextWithLine text='' />,
      rightValue: <PdfTextWithLine text='Telephone:' textStyle={defaultTextStyle} />,
    },
    {
      id: randomUUID(),
      leftValue: styledText('', {}),
      rightValue: <PdfTextWithLine text='Contact Person:' textStyle={defaultTextStyle} />,
    },
    {
      id: randomUUID(),
      leftValue: styledText('FOR PRIVATE IMPROVEMENT:', {
        ...defaultTextStyle,
        fontStyle: 'bold',
        textDecoration: 'underline',
        paddingLeft: 0,
      }),
      rightValue: styledText('', {}),
    },
  ];

  const signatureCols: PdfColumnConfig<SignatureRow>[] = [
    {
      id: 'leftValue',
      label: '',
      type: 'custom',
      isDisplayed: false,
      formatValueForPdf: formatElementForPdf as (value: JSX.Element | string | null) => JSX.Element,
    },
    {
      id: 'rightValue',
      label: '',
      type: 'custom',
      isDisplayed: false,
      formatValueForPdf: formatElementForPdf as (value: JSX.Element | string | null) => JSX.Element,
    },
  ];

  return (
    <PdfTable columns={signatureCols.map((col) => PdfColumnFromConfig(col))} rows={signatureRows} />
  );
};

const Table3 = (): JSX.Element => {
  const signatureRows: SignatureRow[] = [
    {
      id: randomUUID(),
      leftValue: styledText('PAYMENT BOND INFORMATION:', {
        ...defaultTextStyle,
        paddingLeft: 0,
      }),
      rightValue: <PdfTextWithLine text='Bond Number:' textStyle={defaultTextStyle} />,
    },
    {
      id: randomUUID(),
      leftValue: styledText('Name and address of Bonding Company:', {
        ...defaultTextStyle,
        paddingLeft: 0,
      }),
      rightValue: styledText('', {}),
    },
    {
      id: randomUUID(),
      leftValue: <PdfTextWithLine text='' />,
      rightValue: <PdfTextWithLine text='Bond Amount:' textStyle={defaultTextStyle} />,
    },
    {
      id: randomUUID(),
      leftValue: <PdfTextWithLine text='' />,
      rightValue: (
        <PdfTextWithLine text='Completed By:' borderBottom='none' textStyle={defaultTextStyle} />
      ),
    },
    {
      id: randomUUID(),
      leftValue: <PdfTextWithLine text='' />,
      rightValue: <PdfTextWithLine text='' />,
    },
    {
      id: randomUUID(),
      leftValue: (
        <PdfTextWithLine
          text='(MUST ATTACH COPY OF PAYMENT BOND)'
          borderBottom='none'
          textStyle={defaultTextStyle}
        />
      ),
      rightValue: styledText('', {}),
    },
    {
      id: randomUUID(),
      leftValue: styledText('', {}),
      rightValue: <PdfTextWithLine text='' />,
    },
    {
      id: randomUUID(),
      leftValue: styledText('', {}),
      rightValue: (
        <PdfTextWithLine
          text='(Print Name and Title)'
          borderBottom='none'
          textStyle={defaultTextStyle}
        />
      ),
    },
  ];

  const signatureCols: PdfColumnConfig<SignatureRow>[] = [
    {
      id: 'leftValue',
      label: '',
      type: 'custom',
      isDisplayed: false,
      formatValueForPdf: formatElementForPdf as (value: JSX.Element | string | null) => JSX.Element,
    },
    {
      id: 'rightValue',
      label: '',
      type: 'custom',
      isDisplayed: false,
      formatValueForPdf: formatElementForPdf as (value: JSX.Element | string | null) => JSX.Element,
    },
  ];

  return (
    <PdfTable columns={signatureCols.map((col) => PdfColumnFromConfig(col))} rows={signatureRows} />
  );
};

type CityTransitMixSigningAreaProps = {
  quote: Quote;
};

export const CityTransitMixSigningArea = ({
  quote,
}: CityTransitMixSigningAreaProps): JSX.Element => (
  <>
    <PdfTextBlock
      content='City Transit Mix, Inc.'
      textStyle={{
        fontSize: '18px',
        textAlign: 'center',
        fontFamily: 'DancingScript',
        fontStyle: 'bold',
        color: '#2f5496',
      }}
    />
    <PdfTextBlock
      content={quote.project.plant.address.basicDisplay()}
      textStyle={{
        fontFamily: 'DancingScript',
        fontSize: '12px',
        textAlign: 'center',
      }}
    />
    <PdfTextBlock
      content={quote.project.plant.address.basicStateDisplay()}
      textStyle={{
        fontFamily: 'DancingScript',
        fontSize: '12px',
        textAlign: 'center',
      }}
    />
    <PdfSpacer height='0.1in' />
    <PdfTextBlock
      content='PROJECT INFORMATIONAL SUMMARY'
      textStyle={{
        fontFamily: 'Calibri',
        fontStyle: 'bold',
        textDecoration: 'underline',
        fontSize: '11px',
        textAlign: 'center',
      }}
    />
    <PdfSpacer height='0.1in' />
    <PdfTextBlock
      content='I hereby certify that the Project stated below is public/private improvement:  __________________'
      textStyle={{
        fontFamily: 'OpenSans',
        fontSize: '9px',
        textAlign: 'center',
      }}
    />
    <PdfSpacer height='0.1in' />
    <View>
      <Text style={defaultTextStyle}>
        <Text style={{ textDecoration: 'underline' }}>PLEASE NOTE:</Text>
        &nbsp;A SEPARATE SHEET MUST BE COMPLETE FOR EACH PROJECT.
      </Text>
    </View>
    <PdfSpacer height='0.25in' />
    <PdfTextWithLine text='Contractor/Customer:' textStyle={defaultTextStyle} />
    <PdfSpacer height='0.1in' />
    <Table1 />
    <PdfSpacer height='0.5in' />
    <Table2 />
    <PdfSpacer height='0.1in' />
    <PdfTextBlock
      content='County:___________________________    Block: __________________        Lot(s):_______________'
      textStyle={defaultTextStyle}
    />
    <PdfSpacer height='0.1in' />
    <Text style={defaultTextStyle}>
      <Text style={{ textDecoration: 'underline', fontStyle: 'bold' }}>PLEASE NOTE:</Text>
      &nbsp;This informational summary must be fully completed for&nbsp;
      <Text style={{ textDecoration: 'underline' }}>each</Text>
      &nbsp;project that will require materials. Credit may&nbsp;
      <Text style={{ textDecoration: 'underline' }}>not</Text>
      &nbsp;be extended for materials required for this or any other project&nbsp;
      <Text style={{ textDecoration: 'underline' }}>until</Text>
      &nbsp;we receive this project summary fully and properly completed. Thank you for your prompt
      attention to and cooperation in completing and returning this summary sheet. Thank you.
    </Text>
    <PdfSpacer height='0.1in' />
    <Table3 />
  </>
);
