import { Box, Grid, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useState } from 'react';

import { Activities } from '../../components/Activities/Activities';
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import { MetabaseDashboard } from '../../components/MetabaseDashboard/MetabaseDashboard';
import { Page } from '../../components/Page/Page';
import { TaskList } from '../../components/TaskList/TaskList';
import Enums from '../../generated-types/Enums';
import { useSlabQuery } from '../../hooks/useSlabQuery';

type DashboardProps = {
  title: string;
  kind: Enums.DashboardKind;
};

export const Dashboard = ({ title, kind }: DashboardProps): JSX.Element => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [activityId, setActivityId] = useState<string | null>(null);

  const { isLoading, isError, data: activityList } = useSlabQuery('GET activities', {});

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError || activityList === undefined) {
    return <div>ERROR</div>;
  }

  const currentDate = DateTime.now();

  return (
    <Page title={title}>
      <Box display='flex' justifyContent='space-between' paddingBottom='3.5rem'>
        <Typography variant='h1'>Dashboard</Typography>
      </Box>
      <Box>
        <Grid container>
          <Grid item xs={9}>
            <MetabaseDashboard kind={kind} />
            <Activities
              currentDate={currentDate}
              activityId={activityId}
              isDrawerOpen={isDrawerOpen}
              setIsDrawerOpen={setIsDrawerOpen}
              setActivityId={setActivityId}
              drawerOnly
            />
          </Grid>
          <Grid item xs={3}>
            <Box paddingLeft='1.5rem'>
              <TaskList
                activities={activityList.items}
                currentDate={currentDate}
                setIsDrawerOpen={setIsDrawerOpen}
                setActivityId={setActivityId}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Page>
  );
};
