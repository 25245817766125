// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import { NIL_UUID } from '../../utils/UUID';
import {
  NewProjectCustomField,
  ProjectCustomField,
} from '../ProjectCustomField/ProjectCustomField';

export class ProjectSummary {
  readonly id: string;
  readonly name: string;
  readonly projectNumber: string | null;
  readonly owner: string | null;
  readonly contractorDisplay: string;
  readonly statusName: string;
  readonly salesRepName: string;
  readonly plantName: string;
  readonly plantId: string;
  readonly marketId: string;
  readonly statusId: string;
  readonly isArchived: boolean;
  readonly bidDate: string | null;
  readonly expirationDate: string | null;
  readonly totalCosts: string;
  readonly estimatedMargin: string;
  readonly estimatedCuyd: number;
  readonly revenue: string;
  readonly segment: string | null;
  readonly customFieldMap: { [key: string]: ProjectCustomField };

  constructor(data: { [key: string]: any } = {}) {
    this.id = data.id === undefined ? NIL_UUID : data.id;
    this.name = data.name === undefined ? '' : data.name;
    this.projectNumber = data.projectNumber === undefined ? null : data.projectNumber;
    this.owner = data.owner === undefined ? null : data.owner;
    this.contractorDisplay = data.contractorDisplay === undefined ? '' : data.contractorDisplay;
    this.statusName = data.statusName === undefined ? '' : data.statusName;
    this.salesRepName = data.salesRepName === undefined ? '' : data.salesRepName;
    this.plantName = data.plantName === undefined ? '' : data.plantName;
    this.plantId = data.plantId === undefined ? NIL_UUID : data.plantId;
    this.marketId = data.marketId === undefined ? NIL_UUID : data.marketId;
    this.statusId = data.statusId === undefined ? NIL_UUID : data.statusId;
    this.isArchived = data.isArchived === undefined ? false : data.isArchived;
    this.bidDate = data.bidDate === undefined ? null : data.bidDate;
    this.expirationDate = data.expirationDate === undefined ? null : data.expirationDate;
    this.totalCosts = data.totalCosts === undefined ? '' : data.totalCosts;
    this.estimatedMargin = data.estimatedMargin === undefined ? '' : data.estimatedMargin;
    this.estimatedCuyd = data.estimatedCuyd === undefined ? 0 : data.estimatedCuyd;
    this.revenue = data.revenue === undefined ? '' : data.revenue;
    this.segment = data.segment === undefined ? null : data.segment;
    this.customFieldMap = Object.fromEntries(
      Object.entries(data.customFieldMap ?? {}).map(([key, customFieldMap]: any) => [
        key,
        NewProjectCustomField(customFieldMap),
      ]),
    );
  }

  static zero(): ProjectSummary {
    const zeroValues: ExcludeMethodsDeep<ProjectSummary> = {
      id: NIL_UUID,
      name: '',
      projectNumber: null,
      owner: null,
      contractorDisplay: '',
      statusName: '',
      salesRepName: '',
      plantName: '',
      plantId: NIL_UUID,
      marketId: NIL_UUID,
      statusId: NIL_UUID,
      isArchived: false,
      bidDate: null,
      expirationDate: null,
      totalCosts: '',
      estimatedMargin: '',
      estimatedCuyd: 0,
      revenue: '',
      segment: null,
      customFieldMap: {},
    };
    return new ProjectSummary(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewProjectSummary = (
  props: PartialDeep<ProjectSummary, { recurseIntoArrays: true }>,
): ProjectSummary => new ProjectSummary(props);

export const NewProjectSummaryFromDomainObject = (
  props: PartialDeep<DomainObject<ProjectSummary>, { recurseIntoArrays: true }>,
): ProjectSummary => new ProjectSummary(props);
