import { QuestionMark } from '@mui/icons-material';
import {
  Box,
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  SxProps,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { Add, Copy, Edit2 } from 'iconsax-react';
import LogRocket from 'logrocket';
import { useState } from 'react';

import { SlabTheme } from '../../theme';

type VariantOpts = 'primary' | 'secondary';

type SizeOpts = 'normal' | 'small';

export type ButtonIconOpts = 'edit' | 'add' | 'copy';

type ButtonCssProps = {
  border: string;
  backgroundColor: string;
  color: string;
  disabledColor: string;
  hoverBgColor: string;
};

type SizeCssProps = {
  fontSize: string;
  iconSize: number;
};

type ButtonPillProps = {
  text: string;
  variant: VariantOpts;
  size?: SizeOpts;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  icon?: ButtonIconOpts;
  /** @default true */
  capitalize?: boolean;
  /** @default false */
  disabled?: boolean;
  /** @default undefined */
  tip?: string;
};

type ButtonPillCssProps = ButtonCssProps & {
  boxShadow: string;
};

const sizeProps: Record<SizeOpts, SizeCssProps> = {
  normal: {
    fontSize: '1.125rem',
    iconSize: 24,
  },
  small: {
    fontSize: '0.875rem',
    iconSize: 20,
  },
};

export const ButtonIcon = ({
  icon,
  size = 'normal',
}: {
  icon: ButtonIconOpts;
  size: SizeOpts;
}): JSX.Element => {
  const iconStyle = {
    height: sizeProps[size].iconSize,
    width: sizeProps[size].iconSize,
  };
  if (icon === 'edit') {
    return <Edit2 style={iconStyle} variant='TwoTone' />;
  }
  if (icon === 'add') {
    return <Add style={iconStyle} />;
  }
  if (icon === 'copy') {
    return <Copy style={iconStyle} variant='TwoTone' />;
  }
  LogRocket.error(`unknown ButtonIcon ${icon}`);
  return <div style={iconStyle} />;
};

export const ButtonPill = ({
  text,
  variant,
  size = 'normal',
  onClick,
  icon,
  capitalize = true,
  disabled = false,
  tip,
}: ButtonPillProps): JSX.Element => {
  const theme = useTheme();

  // Need to have variant props declared inside component so it can use useTheme
  const variantProps = (isDisabled: boolean): Record<VariantOpts, ButtonPillCssProps> => ({
    primary: {
      border: 'none',
      backgroundColor: isDisabled ? theme.palette.SlabYellow[400] : theme.palette.SlabYellow[500],
      color: theme.palette.SlabGray['900'],
      disabledColor: theme.palette.SlabGray['600'],
      hoverBgColor: theme.palette.SlabYellow[500],
      boxShadow: '0px 4px 32px 0px #ffd64180',
    },
    secondary: {
      border: `1px solid ${theme.palette.SlabGray['900']}`,
      backgroundColor: theme.palette.SlabGray[0],
      color: theme.palette.SlabGray['900'],
      disabledColor: theme.palette.SlabGray['600'],
      hoverBgColor: 'inherit',
      boxShadow: '0px 4px 32px 0px #c9d0fa80',
    },
  });

  const button = (
    <Button
      variant='pill'
      disabled={disabled}
      onClick={onClick}
      sx={{
        textTransform: 'none',
        backgroundColor: variantProps(disabled)[variant].backgroundColor,
        color: variantProps(disabled)[variant].color,
        border: variantProps(disabled)[variant].border,
        '&:hover': {
          backgroundColor: variantProps(disabled)[variant].backgroundColor,
          boxShadow: variantProps(disabled)[variant].boxShadow,
        },
      }}
      startIcon={icon !== undefined && <ButtonIcon icon={icon} size={size} />}
    >
      <Typography fontSize={sizeProps[size].fontSize}>
        {capitalize ? text.toUpperCase() : text}
      </Typography>
    </Button>
  );

  return tip === undefined || tip === '' ? (
    button
  ) : (
    <Tooltip enterDelay={200} title={tip} placement='bottom' arrow>
      <Box sx={{ display: 'flex' }}>
        {button}
        <QuestionMark
          sx={{
            backgroundColor: theme.palette.SlabGray['950'],
            color: theme.palette.SlabGray[0],
            borderRadius: '50%',
            width: '0.75em',
            height: '0.75em',
          }}
        />
      </Box>
    </Tooltip>
  );
};

type ButtonMenuOption = {
  label: string;
  icon?: ButtonIconOpts;
  onClick: () => void;
};

type ButtonMenuProps = {
  label: string;
  icon?: ButtonIconOpts;
  options: ButtonMenuOption[];
} & Omit<ButtonPillProps, 'onClick' | 'text' | 'icon'>;

/**
 * ButtonMenu is a ButtonPill that triggers a dropdown menu to offer a primary
 * action and one or more secondary actions.
 *
 * The available actions are specified by the menu's `options`, where the first
 * item in the `options` list is the primary action (used to label the
 * ButtonPill as a whole), and all of the `options` are displayed in the
 * specified order within the dropdown menu.
 */
export const ButtonMenu = (props: ButtonMenuProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const openMenu = (e: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(e.currentTarget);
  };
  const closeMenu = (): void => {
    setAnchorEl(null);
  };
  const isMenuOpen = anchorEl !== null;

  const menuSx: SxProps<typeof SlabTheme> =
    props.variant === 'primary'
      ? {
          background: SlabTheme.palette.SlabYellow[500],
        }
      : {};

  return (
    <>
      <ButtonPill {...props} icon={props.icon} text={props.label} onClick={openMenu} />
      <Menu
        open={isMenuOpen}
        anchorEl={anchorEl}
        onClose={closeMenu}
        MenuListProps={{ sx: menuSx }}
      >
        {props.options.map(({ label, icon, onClick }) => (
          <MenuItem
            key={label}
            onClick={() => {
              onClick();
              closeMenu();
            }}
          >
            {icon !== undefined && (
              <ListItemIcon>
                <ButtonIcon icon={icon} size='normal' />
              </ListItemIcon>
            )}
            <ListItemText>{label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
