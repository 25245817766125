import * as Yup from 'yup';

import {
  FormikProductReference,
  MainProjectOrQuoteProductsFormikSchema,
  ProjectOrQuoteProductFormikType,
} from '../../components/ProductSection/ProjectOrQuoteProductFormik';
import { NewCurrency } from '../../generated-types/Currency/Currency';
import Enums from '../../generated-types/Enums';
import { ProjectConfig } from '../../generated-types/ProjectConfig/ProjectConfig';
import { DomainObject } from '../../utils/ApiClient';
import { FormikNullableCurrency } from '../../utils/FormikHelpers';
import { NIL_UUID } from '../../utils/UUID';
import {
  TestForUndefined,
  YupNullableCurrency,
  YupNullableNumber,
  YupNullableReference,
  YupReference,
  YupSchemaNullableReferenceType,
  YupSchemaReferenceType,
  YupString,
} from '../../utils/YupHelpers';
import { CompanyContactToFormik } from '../Projects/ProjectFormik';

export type ProjectConfigProductFormikType = Pick<
  ProjectOrQuoteProductFormikType,
  | 'product'
  | 'quantity'
  | 'usage'

  // 'kind' and 'price' are required in the shared yup validation schema, but are not used in the formik type
  | 'kind'
  | 'price'
  | 'haulRate'
>;

export type ProjectConfigFormikType = DomainObject<
  Omit<ProjectConfig, 'plant' | 'products' | 'companies'>
> & {
  plant: YupSchemaReferenceType;
  contractors: {
    company: YupSchemaReferenceType;
    contact: YupSchemaNullableReferenceType;
  }[];
  otherCompanies: {
    company: YupSchemaReferenceType;
    contact: YupSchemaNullableReferenceType;
  }[];
  products: ProjectConfigProductFormikType[];
};

const sharedProjectConfigSchemaFields = {
  name: YupString('Name'),
  plant: YupReference('Plant'),
  estimatedVolumeOverride: YupNullableNumber({
    label: 'Volume',
    integer: true,
    positive: true,
  }),
  revenueOverride: YupNullableCurrency('Revenue'),

  products: MainProjectOrQuoteProductsFormikSchema,
};

export const ProjectConfigSchema: Yup.SchemaOf<Omit<ProjectConfigFormikType, 'id'>> = Yup.object()
  .shape({
    ...sharedProjectConfigSchemaFields,

    contractors: Yup.array().of(
      Yup.object().shape({
        company: YupReference('Company'),
        contact: YupNullableReference('Contact'),
      }),
    ),
    otherCompanies: Yup.array().of(
      Yup.object().shape({
        company: YupReference('Company'),
        contact: YupNullableReference('Contact'),
      }),
    ),
  })
  .test(TestForUndefined('ProjectConfigSchema'));

/** This schema will be used for yup validation when sending it to the API. */
export const ProjectConfigSchemaWire: Yup.SchemaOf<
  Omit<ProjectConfigFormikType, 'id' | 'contractors' | 'otherCompanies'>
> = Yup.object().shape({
  ...sharedProjectConfigSchemaFields,

  companies: Yup.array().of(
    Yup.object().shape({
      company: YupReference('Company'),
      contact: YupNullableReference('Contact'),
    }),
  ),
});

export const FormikProjectConfig = (
  projectConfig: Partial<ProjectConfig> | undefined,
  usesDispatchCustomer: boolean,
): ProjectConfigFormikType => ({
  id: projectConfig?.id ?? NIL_UUID,
  name: projectConfig?.name ?? '',
  plant: {
    id: projectConfig?.plant?.id ?? null,
    option:
      projectConfig?.plant === undefined
        ? null
        : {
            value: projectConfig.plant.id,
            label: projectConfig.plant.name,
          },
  },
  estimatedVolumeOverride: projectConfig?.estimatedVolumeOverride ?? null,
  revenueOverride: FormikNullableCurrency(projectConfig?.revenueOverride),

  contractors: (projectConfig?.companies ?? [])
    .filter((pc) => pc.company.category === Enums.CompanyCategory.Contractor)
    .map((pc) =>
      CompanyContactToFormik({
        usesDispatchCustomer,
        company: pc.company,
        contact: pc.contact,
      }),
    ),
  otherCompanies: (projectConfig?.companies ?? [])
    .filter((pc) => pc.company.category !== Enums.CompanyCategory.Contractor)
    .map((pc) =>
      CompanyContactToFormik({
        usesDispatchCustomer,
        company: pc.company,
        contact: pc.contact,
      }),
    ),

  products: (projectConfig?.products ?? []).map((p) => ({
    product: FormikProductReference(p.product),
    quantity: p.quantity,
    usage: p.usage,

    // These fields are required in the shared yup validation schema,
    // but are not used in the formik type, so we set fake values to make the schema happy
    kind: Enums.QuoteProductKind.Primary,
    price: NewCurrency({ number: '0' }),
    haulRate: null,
  })),
});

export const EmptyFormikProjectConfig = FormikProjectConfig(undefined, false);
