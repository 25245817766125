import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ButtonPill } from '../../components/ButtonPill/ButtonPill';
import { FilterLabel } from '../../components/DataTable/components/FilterTabs';
import {
  FormatErrorCell,
  FormatLinkCell,
} from '../../components/DataTable/components/SlabTableRowCells';
import { DataTable } from '../../components/DataTable/DataTable';
import { ColumnConfig } from '../../components/DataTable/TableDataModel';
import { ApiTableDataParams, useApiTableData } from '../../components/DataTable/useApiTableData';
import { Page } from '../../components/Page/Page';
import Enums from '../../generated-types/Enums';
import { Product } from '../../generated-types/Product/Product';
import { ProductCategoryAgg } from '../../generated-types/ProductCategoryAgg/ProductCategoryAgg';
import { ProductSummary } from '../../generated-types/ProductSummary/ProductSummary';
import { useDrawerManager } from '../../hooks/useDrawerManager';
import { QueryRouteBarrelTypes } from '../../utils/ApiClient';
import { NestedKeyOf } from '../../utils/Types';
import { ProductDrawer } from './ProductDrawer';

export type ProductListAggregations = {
  id: string;
  count: number;
};

const filterKeys: FilterLabel<ProductSummary, ProductListAggregations>[] = [
  {
    label: 'All',
    filter: null,
    resultCount: (aggregations: { [id: string]: ProductListAggregations }): number =>
      aggregations[''].count,
  },
  ...Object.values(Enums.ProductCategory).map(
    (category): FilterLabel<ProductSummary, ProductListAggregations> => ({
      label: category,
      filter: {
        name: 'category',
        operation: Enums.FilterOperation.Equals,
        value: category,
      },
      resultCount: (aggregations: { [id: string]: ProductListAggregations }): number =>
        aggregations[category].count,
    }),
  ),
];

const columns: ColumnConfig<ProductSummary, NestedKeyOf<ProductSummary>>[] = [
  {
    id: 'alternateId',
    label: 'Product ID',
    type: 'string',
    isDisplayed: true,
  },
  {
    id: 'name',
    label: 'Product',
    type: 'string',
    isDisplayed: true,
    formatValueForWeb: (label, row) => FormatLinkCell({ label, link: `/products/${row.id}` }),
  },
  {
    id: 'category',
    label: 'Product type',
    type: 'string',
    isDisplayed: true,
  },
  {
    id: 'suggestedPrice',
    label: 'Suggested price',
    type: 'string',
    isDisplayed: true,
  },
  {
    id: 'pricePerUnit',
    label: 'Price',
    type: 'string',
    isDisplayed: true,
  },
  {
    id: 'plantName',
    label: 'Plant',
    type: 'string',
    isDisplayed: true,
  },
  {
    id: 'incompatibleMixBatchUnits',
    label: 'Incompatible batch unit',
    tooltipText:
      '"UOM issue" products include a mix material whose batch unit is incompatible with its underlying material’s cost unit.',
    type: 'string',
    isDisplayed: true,
    formatValueForWeb: (v) => FormatErrorCell(v),
  },
  {
    id: 'costPerUnit',
    label: 'Cost',
    type: 'string',
    isDisplayed: false,
  },
  {
    id: 'actualMargin',
    label: 'Actual margin',
    type: 'string',
    isDisplayed: false,
  },
  {
    id: 'targetMargin',
    label: 'Target margin',
    type: 'string',
    isDisplayed: false,
  },
];

const buildApiTableDataModelConfig = (): ApiTableDataParams<
  ProductSummary,
  'GET product summaries',
  QueryRouteBarrelTypes['GET product summaries'],
  ProductListAggregations,
  'GET product category aggregations',
  QueryRouteBarrelTypes['GET product category aggregations']
> => {
  const extractAggregations = (
    stats: ProductCategoryAgg[] = [],
  ): { [id: string]: ProductListAggregations } => {
    const aggregationConfig: { [id: string]: ProductListAggregations } = Object.fromEntries(
      Object.values(Enums.ProductCategory).map((productCategory) => [
        productCategory,
        {
          id: productCategory,
          count: 0,
        },
      ]),
    );
    stats.forEach((stat) => {
      aggregationConfig[stat.category].count = stat.count;
    });
    aggregationConfig[''] = {
      id: '',
      count: stats.reduce((sum: number, stat: ProductCategoryAgg) => sum + stat.count, 0),
    };
    return aggregationConfig;
  };

  const makeAggregationArgs = (
    rowsArgs: QueryRouteBarrelTypes['GET product summaries']['args'],
  ): QueryRouteBarrelTypes['GET product category aggregations']['args'] => {
    const filterBy = (rowsArgs.queryParams?.filterBy ?? []).filter(
      (param) => param.operation !== 'equals' || !(param.name as string).startsWith('category'),
    );
    if (!filterBy.length) {
      return {};
    }
    return { queryParams: { filterBy } };
  };

  return {
    rowsRouteKey: 'GET product summaries',
    extractRows: (productList) => productList.items,
    initialSortBy: {
      direction: Enums.SortDirection.Ascending,
      name: 'alternateId',
    },
    aggregationsRouteKey: 'GET product category aggregations',
    extractAggregations,
    makeColumnConfigs: () => columns,
    makeAggregationArgs,
  };
};

export const ProductList = (): JSX.Element => {
  const navigate = useNavigate();

  const [productId, setProductId] = useState<string | null>(null);

  const { toastHook: productToastHook, ...productDrawerProps } = useDrawerManager({
    baseUrl: '/products',
    resourceTypeName: 'product',
    drawerProps: {
      resourceId: productId,
    },
  });

  const tableModel = useApiTableData(buildApiTableDataModelConfig());

  return (
    <Page title='Products'>
      {productToastHook.toast}
      <ProductDrawer
        {...productDrawerProps}
        productId={productDrawerProps.resourceId}
        onSuccess={(product: Product): void => {
          if (productId === null) {
            navigate(`/products/${product.id}`);
          } else {
            productDrawerProps.setIsOpen(false);
            productToastHook.showToast('success');
          }
        }}
      />
      <Box display='flex' justifyContent='space-between' paddingBottom='3.5rem'>
        <Typography variant='h1'>Products</Typography>
        <ButtonPill
          text='create product'
          variant='primary'
          onClick={(): void => {
            setProductId(null);
            productDrawerProps.setIsOpen(true);
          }}
          icon='add'
        />
      </Box>

      <Box height='400px'>
        <DataTable
          tableName='products'
          tableModel={tableModel}
          onEditPress={(row): void => {
            setProductId(row.id);
            productDrawerProps.setIsOpen(true);
          }}
          tabFilters={filterKeys}
          includePrint={{ title: 'Products' }}
          marketSelectorOpts={{ enabled: true }}
          plantSelectorOpts={{ enabled: true }}
        />
      </Box>
    </Page>
  );
};
