import { Box, Grid, Typography, useTheme } from '@mui/material';
import { Formik } from 'formik';
import _ from 'lodash';
import * as React from 'react';

import { ButtonPill } from '../../components/ButtonPill/ButtonPill';
import { InputDropdown } from '../../components/InputDropdown/InputDropdown';
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import { SlabDrawer } from '../../components/SlabDrawer/SlabDrawer';
import { QuoteStatus } from '../../generated-types/QuoteStatus/QuoteStatus';
import { SharedDrawerOverrideProps } from '../../hooks/useDrawerManager';
import { useSlabMutation } from '../../hooks/useSlabMutation';
import { useSlabQuery } from '../../hooks/useSlabQuery';
import { lookups } from '../../utils/DomainHelpers';
import { FormErrorNotification } from '../../utils/FormikHelpers';
import { YupReference } from '../../utils/YupHelpers';
import { FormikQuoteStatus, QuoteStatusFormikType, QuoteStatusSchemaFormik } from './QuoteFormik';

export const formikQuoteStatusToWire = (values: QuoteStatusFormikType): QuoteStatus =>
  // TODO #2219: Need a NewQuoteStatusFromForm wrapper for this case
  new QuoteStatus(_.merge(QuoteStatus.zero(), values));

type QuoteStatusDrawerProps = Omit<SharedDrawerOverrideProps<QuoteStatus>, 'onSaveAsNew'>;

const QuoteStatusDrawerPage = ({
  resourceId,
  setIsOpen,
  onSave,
  onError,
  onClose,
}: QuoteStatusDrawerProps): JSX.Element | null => {
  const theme = useTheme();

  const updateStatus = useSlabMutation('PUT quote status by quote ID', {
    onSuccess: (quote) => onSave(quote.status),
    onError,
  });

  const {
    isLoading: isLoadingStatuses,
    isError: isErrorStatuses,
    data: statusList,
  } = useSlabQuery('GET quote statuses', {});

  const {
    isLoading: isLoadingQuote,
    isError: isErrorQuote,
    data: quote,
  } = useSlabQuery('GET quote by ID', {
    pathParams: {
      id: resourceId ?? '',
    },
  });

  if (isLoadingStatuses || isLoadingQuote) {
    return <LoadingSpinner />;
  }
  if (isErrorStatuses || isErrorQuote || quote === undefined || statusList === undefined) {
    return <div>ERROR</div>;
  }

  const formikStatus = FormikQuoteStatus(quote.status);

  const possibleDestinationStatuses = statusList.items.filter((status) =>
    quote.status.canTransitionTo(status),
  );
  const statusLookups = lookups({
    items: possibleDestinationStatuses,
    count: possibleDestinationStatuses.length,
    label: (cqs: QuoteStatus) => cqs.name,
    compare: null,
  });

  return (
    <Formik
      validationSchema={QuoteStatusSchemaFormik}
      initialValues={formikStatus}
      onSubmit={async (values: QuoteStatusFormikType): Promise<void> =>
        updateStatus.mutate({
          args: {
            pathParams: { quoteId: resourceId ?? '' },
            body: formikQuoteStatusToWire(values),
          },
          schema: YupReference('quote status'),
        })
      }
      onSuccess={onSave}
    >
      {(formikBag): JSX.Element => (
        <Box padding='5rem 3.5rem 5rem 3.5rem'>
          <FormErrorNotification />
          <Typography variant='h1' fontSize='2rem'>
            Edit quote status
          </Typography>

          <Box display='flex' flexDirection='column' gap='1rem' paddingY='1rem'>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                The only part of this quote that is editable is its status, because this quote has
                already been approved or rejected for sending to the customer. If you need to make
                other changes, create a new revision.
              </Grid>
              {possibleDestinationStatuses.length === 1 && (
                <Grid item xs={12} color={theme.palette.error.main}>
                  Also, your organization does not have any other statuses available at this stage
                  of the approval workflow process.
                </Grid>
              )}
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <InputDropdown name='id' label='Status*' options={statusLookups} />
              </Grid>
            </Grid>
          </Box>

          <Grid container spacing={2} sx={{ '& button': { width: '100%' } }}>
            <Grid item xs={4}>
              <ButtonPill
                text='cancel'
                variant='secondary'
                onClick={(): void => {
                  formikBag.resetForm({ values: formikStatus });
                  setIsOpen(false);
                  onClose?.();
                }}
                disabled={updateStatus.isPending}
              />
            </Grid>
            <Grid item xs={4}>
              <ButtonPill
                text='reset'
                variant='secondary'
                onClick={(): void => {
                  formikBag.resetForm({ values: formikStatus });
                }}
                disabled={!formikBag.dirty || updateStatus.isPending}
              />
            </Grid>
            <Grid item xs={4}>
              <ButtonPill
                text='save'
                variant='primary'
                onClick={async (): Promise<void> => {
                  formikBag.submitForm();
                }}
                disabled={!(formikBag.dirty || updateStatus.isPending)}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </Formik>
  );
};

export const QuoteStatusDrawer = (props: QuoteStatusDrawerProps): JSX.Element => (
  <SlabDrawer
    isOpen={props.isOpen}
    // Required in the scenario a user closes then reopens the same drawer. Product state
    // will not be updated due to single-hook rendering values.
    slideProps={{
      unmountOnExit: true,
    }}
  >
    <QuoteStatusDrawerPage {...props} />
  </SlabDrawer>
);
