// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import Enums from '../Enums';

export class QuotePolicyThreshold {
  readonly kind: Enums.QuotePolicyThresholdKind;
  readonly description: string;
  readonly thresholdValue: string;

  constructor(data: { [key: string]: any } = {}) {
    this.kind =
      data.kind === undefined
        ? Enums.QuotePolicyThresholdKind.MarginMixProductsAverageLessThanDollars
        : data.kind;
    this.description = data.description === undefined ? '' : data.description;
    this.thresholdValue = data.thresholdValue === undefined ? '0' : data.thresholdValue;
  }

  static zero(): QuotePolicyThreshold {
    const zeroValues: ExcludeMethodsDeep<QuotePolicyThreshold> = {
      kind: Enums.QuotePolicyThresholdKind.MarginMixProductsAverageLessThanDollars,
      description: '',
      thresholdValue: '0',
    };
    return new QuotePolicyThreshold(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************
  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewQuotePolicyThreshold = (
  props: PartialDeep<QuotePolicyThreshold, { recurseIntoArrays: true }>,
): QuotePolicyThreshold => new QuotePolicyThreshold(props);

export const NewQuotePolicyThresholdFromDomainObject = (
  props: PartialDeep<DomainObject<QuotePolicyThreshold>, { recurseIntoArrays: true }>,
): QuotePolicyThreshold => new QuotePolicyThreshold(props);
