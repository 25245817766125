import LogRocket from 'logrocket';
import { DateTime } from 'luxon';

import { FilterLabel } from '../../components/DataTable/components/FilterTabs';
import { FormatLinkCell } from '../../components/DataTable/components/SlabTableRowCells';
import {
  ApplyColumnChanges,
  DataTable,
  SharedDataTableProps,
} from '../../components/DataTable/DataTable';
import {
  ColumnConfig,
  DateTimeToString,
  NumberToString,
} from '../../components/DataTable/TableDataModel';
import {
  ApiTableDataWithMoreParams,
  useApiTableDataWithMore,
} from '../../components/DataTable/useApiTableData';
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import {
  CustomFieldDefinition,
  NewCustomFieldDefinition,
} from '../../generated-types/CustomFieldDefinition/CustomFieldDefinition';
import Enums from '../../generated-types/Enums';
import { CustomFieldType } from '../../generated-types/generated-enums';
import { Units } from '../../generated-types/generated-units';
import { ProjectStatus } from '../../generated-types/ProjectStatus/ProjectStatus';
import {
  NewProjectStatusAgg,
  ProjectStatusAgg,
} from '../../generated-types/ProjectStatusAgg/ProjectStatusAgg';
import { ProjectSummary } from '../../generated-types/ProjectSummary/ProjectSummary';
import { useSlabQuery } from '../../hooks/useSlabQuery';
import { ListURLParams, QueryRouteBarrelTypes, StatsURLParams } from '../../utils/ApiClient';
import { CurrencyDinero, DineroString } from '../../utils/Currency';
import { List } from '../../utils/List';
import { PossiblyAsyncResult } from '../../utils/Query';
import { NestedKeyOf } from '../../utils/Types';

const builtinProjectColumns: ColumnConfig<ProjectSummary, NestedKeyOf<ProjectSummary>>[] = [
  {
    id: 'name',
    label: 'Project',
    type: 'string',
    isDisplayed: true,
    formatValueForWeb: (label, row) =>
      FormatLinkCell({
        label: `${row.name}${row.owner === null ? '' : ` - ${row.owner}`}`,
        link: `/projects/${row.id}`,
      }),
  },
  {
    id: 'projectNumber',
    label: 'Project Number',
    type: 'string',
    isDisplayed: true,
  },
  {
    id: 'contractorDisplay',
    label: 'Contractors',
    type: 'string',
    isDisplayed: true,
  },
  {
    id: 'estimatedCuyd',
    label: 'CUYD',
    type: 'number',
    isDisplayed: true,
  },
  {
    id: 'revenue',
    label: '$ Revenue',
    type: 'string',
    isDisplayed: true,
  },
  {
    id: 'statusName',
    label: 'Status',
    type: 'string',
    isDisplayed: true,
  },
  {
    id: 'bidDate',
    label: 'Bid Date',
    type: 'string',
    isDisplayed: true,
  },
  {
    id: 'expirationDate',
    label: 'Expiration Date',
    type: 'string',
    isDisplayed: false,
  },
  {
    id: 'salesRepName',
    label: 'Sales Rep',
    type: 'string',
    isDisplayed: false,
  },
  {
    id: 'estimatedMargin',
    label: 'Est. Margin',
    type: 'string',
    isDisplayed: false,
  },
  {
    id: 'plantName',
    label: 'Plant',
    type: 'string',
    isDisplayed: false,
  },
  {
    id: 'totalCosts',
    label: 'Est. Costs',
    type: 'string',
    isDisplayed: false,
  },
  {
    id: 'segment',
    label: 'Segment',
    type: 'string',
    isDisplayed: false,
  },
];

type ProjectTypeAndReferences =
  | {
      type: 'all projects';
    }
  | {
      type: 'company projects';
      companyId: string;
    }
  | {
      type: 'contact projects';
      contactId: string;
    };

type ProjectSummariesRoute =
  | 'GET project summaries'
  | 'GET project summaries by company ID'
  | 'GET project summaries by contact ID';

const columnRenderingOptions = (
  cfd: CustomFieldDefinition,
): Partial<ColumnConfig<ProjectSummary, NestedKeyOf<ProjectSummary>>> & {
  type: 'string' | 'number' | 'DateTime' | 'custom';
} => {
  switch (cfd.type) {
    case CustomFieldType.Options:
      return {
        type: 'custom',
        formatValueToString: (value) =>
          cfd.options?.find((opt) => opt.value === value)?.label ?? String(value ?? ''),
      };
    case CustomFieldType.Date:
      // Can't directly use type: 'DateTime' because the value is a string, not a parsed Luxon DateTime.
      return {
        type: 'custom',
        formatValueToString: (value) =>
          DateTimeToString(typeof value === 'string' ? DateTime.fromISO(value) : null),
      };
    case CustomFieldType.Number:
      // Can't directly use type: 'number' because the value is a string, not a JS/TS number.
      return {
        type: 'custom',
        formatValueToString: (value): string => {
          if (value === null || value === undefined) {
            return '';
          }
          const num = Number(value);
          if (Number.isNaN(num)) {
            return '';
          }
          return NumberToString(num);
        },
      };
    case CustomFieldType.String:
    default:
      return { type: 'string' };
  }
};

const buildApiTableDataModelConfig = ({
  statusResult,
  customFieldDefinitionResult,
  typeAndReferences,
  includeTabs,
  initialFilterBy,
  optionalColumnOverrides = [],
  optionalColumnRemovals = [],
}: {
  statusResult: PossiblyAsyncResult<List<ProjectStatus> | undefined>;
  customFieldDefinitionResult: PossiblyAsyncResult<List<CustomFieldDefinition> | undefined>;
  typeAndReferences: ProjectTypeAndReferences;
  includeTabs: boolean;
  initialFilterBy: ListURLParams<ProjectSummary>['filterBy'];
  optionalColumnOverrides?: ColumnConfig<ProjectSummary, NestedKeyOf<ProjectSummary>>[];
  optionalColumnRemovals?: NestedKeyOf<ProjectSummary>[];
}): ApiTableDataWithMoreParams<
  ProjectSummary,
  ProjectSummariesRoute,
  any,
  ProjectStatusAgg,
  'GET project status aggregations',
  { args: { queryParams?: StatsURLParams<ProjectSummary> }; returns: ProjectStatusAgg[] },
  List<CustomFieldDefinition> | undefined
> => {
  const makeColumnConfigs = (
    customFieldDefinitionList: List<CustomFieldDefinition> | undefined,
  ): ColumnConfig<ProjectSummary, NestedKeyOf<ProjectSummary>>[] =>
    ApplyColumnChanges({
      columns: [
        ...builtinProjectColumns,
        ...(customFieldDefinitionList ?? new List(NewCustomFieldDefinition)).items.map(
          (cfd): ColumnConfig<ProjectSummary, NestedKeyOf<ProjectSummary>> => ({
            id: `customFieldMap.${cfd.id}.value` as NestedKeyOf<ProjectSummary>,
            label: cfd.name,
            isDisplayed: false,
            isSortingDisabled: true,
            ...columnRenderingOptions(cfd),
          }),
        ),
      ],
      optionalColumnOverrides,
      optionalColumnRemovals,
    });

  const extractAggregations = (data: ProjectStatusAgg[]): { [id: string]: ProjectStatusAgg } => {
    const statusList: List<ProjectStatus> = statusResult.data ?? List.zero();
    const aggregationConfig = Object.fromEntries(
      statusList.items.map((s) => [s.id, NewProjectStatusAgg({ id: s.id, name: s.name })]),
    );
    data.forEach((s) => {
      aggregationConfig[s.id] = s;
    });
    // Total for all statuses.
    aggregationConfig[''] = data.reduce(
      (agg: ProjectStatusAgg, current: ProjectStatusAgg): ProjectStatusAgg =>
        NewProjectStatusAgg({
          ...agg,
          count: agg.count + current.count,
          totalRevenue: agg.totalRevenue.add(current.totalRevenue) ?? agg.totalRevenue,
          totalCuyd: agg.totalCuyd + current.totalCuyd,
        }),
      ProjectStatusAgg.zero(),
    );
    return aggregationConfig;
  };

  type KeyAndPathParams = {
    rowsRouteKey: ProjectSummariesRoute;
    rowsPathParams?: QueryRouteBarrelTypes[ProjectSummariesRoute]['args'] extends { pathParams: {} }
      ? QueryRouteBarrelTypes[ProjectSummariesRoute]['args']['pathParams']
      : any;
  };
  const rowsKeyAndPathParams = ((): KeyAndPathParams => {
    if (typeAndReferences.type === 'company projects') {
      return {
        rowsRouteKey: 'GET project summaries by company ID',
        rowsPathParams: { id: typeAndReferences.companyId },
      };
    }
    if (typeAndReferences.type === 'contact projects') {
      return {
        rowsRouteKey: 'GET project summaries by contact ID',
        rowsPathParams: { id: typeAndReferences.contactId },
      };
    }
    // typeAndReferences.type === 'all projects'
    return {
      rowsRouteKey: 'GET project summaries',
    };
  })();

  const makeAggregationArgs = (
    rowsArgs: QueryRouteBarrelTypes['GET project summaries']['args'],
  ): QueryRouteBarrelTypes['GET project status aggregations']['args'] => {
    const filterBy = (rowsArgs.queryParams?.filterBy ?? []).filter(
      (param) => param.operation !== 'equals' || !(param.name as string).startsWith('status'),
    );
    if (!filterBy.length) {
      return {};
    }
    return { queryParams: { filterBy } };
  };

  return {
    ...rowsKeyAndPathParams,
    extractRows: (list) => list.items,
    initialSortBy: {
      direction: Enums.SortDirection.Ascending,
      name: 'name',
    },
    initialFilterBy,
    aggregationsRouteKey: 'GET project status aggregations',
    aggregationsQueryOptions: { enabled: includeTabs },
    extractAggregations,
    makeColumnConfigs,
    makeAggregationArgs,
    moreDataResult: customFieldDefinitionResult,
  };
};

type ProjectTableProps = SharedDataTableProps<ProjectSummary, ProjectStatusAgg> & {
  includeTabs?: boolean;
  typeAndReferences: ProjectTypeAndReferences;
};

export const ProjectTable = ({
  typeAndReferences,
  includeTabs = false,
  optionalColumnOverrides,
  optionalColumnRemovals,
  ...dataTableProps
}: ProjectTableProps): JSX.Element => {
  const statusResult = useSlabQuery('GET project statuses', {});
  const { isLoading: isLoadingStatuses, isError: isErrorStatuses, data: statusList } = statusResult;

  const customFieldDefinitionResult = useSlabQuery('GET custom field definitions', {});
  const {
    isLoading: isLoadingCustomFieldDefinitions,
    isError: isErrorCustomFieldDefinitions,
    data: customFieldDefinitionList,
  } = customFieldDefinitionResult;

  const initialFilterBy = (dataTableProps.filterCheckboxes ?? [])
    .filter(({ defaultChecked }) => defaultChecked)
    .map(({ filter }) => filter);
  const tableModel = useApiTableDataWithMore(
    buildApiTableDataModelConfig({
      statusResult,
      customFieldDefinitionResult,
      typeAndReferences,
      includeTabs,
      initialFilterBy,
      optionalColumnOverrides,
      optionalColumnRemovals,
    }),
  );

  const isLoading = isLoadingStatuses || isLoadingCustomFieldDefinitions;

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const isError = isErrorStatuses || isErrorCustomFieldDefinitions;
  const isDataUndefined = statusList === undefined || customFieldDefinitionList === undefined;

  if (isError || isDataUndefined) {
    return <div>ERROR</div>;
  }

  const filterKeys = (): FilterLabel<ProjectSummary, ProjectStatusAgg>[] => [
    {
      label: 'All',
      filter: null,
      additionalLabels: (aggregations: {
        [id: string]: ProjectStatusAgg | undefined;
      }): string[] => {
        const agg = aggregations[''] ?? ProjectStatusAgg.zero();

        return [
          DineroString({ dinero: CurrencyDinero(agg.totalRevenue) }),
          `${agg.totalCuyd.toLocaleString()} ${Units.cu_yd.label}`,
        ];
      },
      resultCount: (aggregations: { [id: string]: ProjectStatusAgg | undefined }): number => {
        const agg = aggregations[''] ?? ProjectStatusAgg.zero();
        return agg.count;
      },
    },
    ...statusList.items.map(
      (projectStatus): FilterLabel<ProjectSummary, ProjectStatusAgg> => ({
        label: projectStatus.name,
        filter: {
          name: 'statusId',
          operation: Enums.FilterOperation.Equals,
          value: projectStatus.id,
        },
        additionalLabels: (aggregations: {
          [id: string]: ProjectStatusAgg | undefined;
        }): string[] => {
          let agg = aggregations[projectStatus.id];

          // We are unable to recreate this locally, so log it to catch it next time.
          if (agg === undefined) {
            LogRocket.error('additionalLabels: Aggregation for specific status ID is undefined', {
              aggregations,
              projectStatusId: projectStatus.id,
            });
            agg = ProjectStatusAgg.zero();
          }
          return [
            DineroString({ dinero: CurrencyDinero(agg.totalRevenue) }),
            `${agg.totalCuyd.toLocaleString()} ${Units.cu_yd.label}`,
          ];
        },
        resultCount: (aggregations: { [id: string]: ProjectStatusAgg | undefined }): number => {
          let agg = aggregations[projectStatus.id];

          // We are unable to recreate this locally, so log it to catch it next time.
          if (agg === undefined) {
            LogRocket.error('resultCount: Aggregation for specific status ID is undefined', {
              aggregations,
              projectStatusId: projectStatus.id,
            });
            agg = ProjectStatusAgg.zero();
          }
          return agg.count;
        },
      }),
    ),
  ];

  return (
    <DataTable
      tableModel={tableModel}
      tabFilters={includeTabs ? filterKeys() : undefined}
      {...dataTableProps}
    />
  );
};
