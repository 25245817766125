import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ButtonPill } from '../../components/ButtonPill/ButtonPill';
import { FormatLinkCell } from '../../components/DataTable/components/SlabTableRowCells';
import { DataTable } from '../../components/DataTable/DataTable';
import { ColumnConfig } from '../../components/DataTable/TableDataModel';
import {
  ApiTableDataWithoutAggregationsParams,
  useApiTableDataWithoutAggregations,
} from '../../components/DataTable/useApiTableData';
import { Page } from '../../components/Page/Page';
import Enums from '../../generated-types/Enums';
import { ProjectConfigSummary } from '../../generated-types/ProjectConfigSummary/ProjectConfigSummary';
import { useDrawerManager } from '../../hooks/useDrawerManager';
import { QueryRouteBarrelTypes } from '../../utils/ApiClient';
import { NestedKeyOf } from '../../utils/Types';
import { ProjectConfigDrawer } from './ProjectConfigDrawer';

const columns: ColumnConfig<ProjectConfigSummary, NestedKeyOf<ProjectConfigSummary>>[] = [
  {
    id: 'name',
    label: 'Name',
    type: 'string',
    isDisplayed: true,
    formatValueForWeb: (label, row) => FormatLinkCell({ label, link: `/projectconfigs/${row.id}` }),
  },
  {
    id: 'plant',
    label: 'Plant',
    type: 'string',
    isDisplayed: true,
  },
  {
    id: 'revenueOverride',
    label: 'Revenue Override',
    type: 'string',
    isDisplayed: true,
  },
  {
    id: 'estimatedVolumeOverride',
    label: 'CUYD Override',
    type: 'number',
    isDisplayed: true,
  },
];

const buildApiTableDataModelConfig = (): ApiTableDataWithoutAggregationsParams<
  ProjectConfigSummary,
  'GET project config summaries',
  QueryRouteBarrelTypes['GET project config summaries']
> => ({
  rowsRouteKey: 'GET project config summaries',
  extractRows: (projectConfigList) => projectConfigList.items,
  initialSortBy: {
    direction: Enums.SortDirection.Ascending,
    name: 'name',
  },
  makeColumnConfigs: () => columns,
});

export const ProjectConfigList = (): JSX.Element => {
  const navigate = useNavigate();

  const [projectConfigId, setProjectConfigId] = useState<string | null>(null);

  const { toastHook: projectConfigToastHook, ...projectConfigDrawerProps } = useDrawerManager({
    baseUrl: '/projectconfigs',
    resourceTypeName: 'project config',
    drawerProps: {
      resourceId: projectConfigId,
    },
  });

  const tableModel = useApiTableDataWithoutAggregations(buildApiTableDataModelConfig());

  return (
    <Page title='Project Configs'>
      {projectConfigToastHook.toast}
      <ProjectConfigDrawer
        {...projectConfigDrawerProps}
        projectConfigId={projectConfigId}
        onSuccess={(projectConfig): void => {
          if (projectConfigId === null) {
            navigate(`/projectconfigs/${projectConfig.id}`);
          } else {
            projectConfigDrawerProps.setIsOpen(false);
            projectConfigToastHook.showToast('success');
          }
        }}
      />
      <Box display='flex' justifyContent='space-between' paddingBottom='3.5rem'>
        <Typography variant='h1'>Project Configs</Typography>
        <ButtonPill
          text='create config'
          variant='primary'
          onClick={(): void => {
            setProjectConfigId(null);
            projectConfigDrawerProps.setIsOpen(true);
          }}
          icon='add'
        />
      </Box>

      <Box height='400px'>
        <DataTable
          tableName='project-configs'
          tableModel={tableModel}
          onEditPress={(row): void => {
            setProjectConfigId(row.id);
            projectConfigDrawerProps.setIsOpen(true);
          }}
          includePrint={{ title: 'Project configs' }}
          marketSelectorOpts={{ enabled: true }}
          plantSelectorOpts={{ enabled: true }}
        />
      </Box>
    </Page>
  );
};
