import { Box, InputAdornment, TextField } from '@mui/material';
import { Field, FieldAttributes, useFormikContext } from 'formik';
import { debounce } from 'lodash';
import { DateTime, Duration } from 'luxon';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { NumericFormat } from 'react-number-format';

import Enums from '../../generated-types/Enums';
import { SetFormikValue } from '../../utils/FormikHelpers';
import { InputTooltip } from '../InputTooltip/InputTooltip';

type InputTypeOptions =
  | 'text'
  | 'number'
  | 'textarea'
  | 'date'
  | 'datetime-local'
  | 'email'
  | 'tel'
  | 'duration-hour-minute'
  | 'currency'
  | 'cost';

// Cost inputs might contain `{ unit: 'Required' }` errors. When this happens,
// if we do not handle it appropriately (see below), there is a runtime panic.
const costErrorHelperText = (formikError: string | { unit: Enums.Unit | undefined }): string => {
  if (typeof formikError === 'string') {
    return formikError;
  }
  return formikError.unit === undefined ? ' ' : `Unit: ${formikError.unit}`;
};

export type InputProps = {
  label?: string;
  name: string;

  /** Default 'text' */
  type?: InputTypeOptions;
  /** Default empty */
  placeholder?: string;
  disabled?: boolean;
  /** Min numbers should use usually Yup validation. */
  min?: string;
  /** Max numbers should use usually Yup validation. */
  max?: string;
  /** Optional onChange chain function */
  onInputChange?: (arg0: string) => Promise<void>;
  /** value to prepend to input */
  startAdornment?: string;
  /** value to append to input */
  endAdornment?: string;
  tip?: string;
  /**
   * Optional limit to trailing decimal places.
   * @default ['cost', 'currency'].includes(type) ? 2 : undefined
   */
  precision?: number;
};

/**
 * Given an input value of any type, convert it to the correct input value string
 */
const handleValue = (value: string | null | undefined, type: InputTypeOptions): string => {
  if (value === null || value === undefined || value === '') {
    return '';
  }

  switch (type) {
    case 'date':
      return DateTime.fromISO(value).toISODate() ?? '';
    case 'datetime-local':
      // The yyyy-MM-ddThh:mm format is required by the datetime-local <input> type
      return (
        DateTime.fromISO(value).startOf('minute').toISO({
          includeOffset: false,
          suppressSeconds: true,
          suppressMilliseconds: true,
        }) ?? ''
      );
    case 'duration-hour-minute':
      return Duration.fromISO(value).toFormat("h' hours' m' minutes'");
    case 'cost':
    case 'currency':
    case 'email':
    case 'number':
    case 'tel':
    case 'text':
    case 'textarea':
    default:
      return value;
  }
};

export const Input = ({
  label,
  name,
  type = 'text',
  placeholder,
  disabled,
  min,
  max,
  onInputChange,
  startAdornment,
  endAdornment,
  tip,
  precision = ['cost', 'currency'].includes(type) ? 2 : undefined,
}: InputProps): JSX.Element => {
  const formikBag = useFormikContext<any>();

  const formikName = ((): string => {
    switch (type) {
      case 'cost':
        return `${name}.amount.number`;
      case 'currency':
        return `${name}.number`;
      case 'date':
      case 'datetime-local':
      case 'duration-hour-minute':
      case 'email':
      case 'number':
      case 'tel':
      case 'text':
      case 'textarea':
      default:
        return name;
    }
  })();

  const [localValue, setLocalValue] = useState<string | undefined>();
  const { value: fieldValue } = formikBag.getFieldProps(formikName);
  const isExternallySet = useRef(false);

  // Sync localValue with formikBag value on initial render
  useEffect(() => {
    if (!isExternallySet.current && localValue !== handleValue(fieldValue, type)) {
      setLocalValue(handleValue(fieldValue, type));
    }
    isExternallySet.current = false; // Reset after external sync
  }, [fieldValue, type]);

  // updateFormikValue is debounced to prevent rapid changes from causing unnecessary re-renders.
  const updateFormikValue = useCallback(
    debounce(async (newValue: string) => {
      formikBag.setFieldValue(formikName, newValue);
      await onInputChange?.(newValue.replaceAll(',', ''));
    }, 300),
    [onInputChange, formikBag],
  );

  // handleInputChange is called whenever the input value changes.
  // It updates the local value and calls updateFormikValue to update the formik value
  // in a debounced manner, particularly the `onInputChange` that likely effects other
  // inputs in a form state.
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    const newValue = e.target.value;
    setLocalValue(newValue);
    isExternallySet.current = true;
    updateFormikValue(newValue);
  };

  return (
    <Field id={name} name={formikName}>
      {({ field: { value, onBlur }, meta }: FieldAttributes<any>): JSX.Element => {
        const formikBagMeta = formikBag.getFieldMeta(name);
        const hasError: boolean =
          formikBagMeta.touched === true && formikBagMeta.error !== undefined;

        // If the base object reference is passed as name, the error could be at a lower level or the highest level.
        const formikError = meta.error ?? formikBagMeta.error;

        const labelWithTip =
          label === undefined ? null : (
            <Box display='flex' gap='0.25rem' alignItems='center'>
              {label}
              {tip !== undefined ? <InputTooltip tip={tip} /> : null}
            </Box>
          );

        switch (type) {
          case 'number':
          case 'currency':
          case 'cost':
            return (
              <NumericFormat
                id={formikName}
                decimalScale={precision}
                thousandSeparator
                customInput={TextField}
                InputProps={{
                  startAdornment:
                    startAdornment !== undefined ? (
                      <InputAdornment position='start'>{startAdornment}</InputAdornment>
                    ) : undefined,
                  endAdornment:
                    endAdornment !== undefined ? (
                      <InputAdornment position='end'>{endAdornment}</InputAdornment>
                    ) : undefined,
                }}
                name={formikName}
                label={labelWithTip}
                value={localValue ?? handleValue(value, type)}
                onBlur={async (e): Promise<void> => {
                  // If you enter a negative then click away, NumberFormat automatically clears it.
                  // Because of that, we check and if it is only a `-`, set the formikBag state to match the
                  // soon-to-be empty string. Otherwise an `Invalid` error is displayed, while no value exists.
                  const curV = e.currentTarget.value;
                  if (curV === '-') {
                    await SetFormikValue(formikBag, formikName, '');
                  }
                  await onBlur(e);
                }}
                onChange={handleInputChange}
                fullWidth
                error={hasError}
                helperText={hasError ? costErrorHelperText(formikError) : ' '}
                placeholder={placeholder}
                disabled={disabled}
                // Close outlined box + fix height if label is undefined
                sx={
                  label === undefined
                    ? {
                        fieldset: {
                          top: 0,
                        },
                        legend: {
                          display: 'none',
                        },
                      }
                    : {}
                }
              />
            );
          case 'tel':
          case 'email':
          case 'date':
          case 'datetime-local':
          case 'duration-hour-minute':
          case 'textarea':
          case 'text':
            return (
              <TextField
                id={formikName}
                name={formikName}
                label={labelWithTip}
                value={localValue ?? handleValue(value, type)}
                onBlur={onBlur}
                onChange={handleInputChange}
                type={type}
                inputProps={{
                  min,
                  max,
                }}
                // min/max allowed on inputProps but not InputProps.
                // adornments on inputProps are not respected.
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  startAdornment:
                    startAdornment !== undefined ? (
                      <InputAdornment position='start'>{startAdornment}</InputAdornment>
                    ) : undefined,
                  endAdornment:
                    endAdornment !== undefined ? (
                      <InputAdornment position='end'>{endAdornment}</InputAdornment>
                    ) : undefined,
                }}
                fullWidth
                multiline={type === 'textarea'}
                error={hasError}
                helperText={hasError ? formikError : ' '}
                placeholder={placeholder}
                disabled={disabled}
                // Default 'shrink' date labels because they overlap the select text
                InputLabelProps={
                  type === 'date' || type === 'datetime-local'
                    ? {
                        shrink: true,
                      }
                    : {}
                }
                // Close outlined box + fix height if label is undefined
                sx={
                  label === undefined
                    ? {
                        fieldset: {
                          top: 0,
                        },
                        legend: {
                          display: 'none',
                        },
                      }
                    : {}
                }
              />
            );

          // This default state will never be reached.
          default:
            return <div />;
        }
      }}
    </Field>
  );
};
