// Code generated by domaingen. DO NOT EDIT.

/* eslint-disable no-shadow, quotes, no-multiple-empty-lines */

export enum AccessType {
  Read = 'read',
  Write = 'write',
}
export enum ActivityCategory {
  Task = 'Task',
  Call = 'Call',
  Email = 'Email',
  SalesVisit = 'Sales Visit',
  SiteVisit = 'Site Visit',
  Other = 'Other',
}
export enum ActivityStatus {
  Planned = 'Planned',
  Completed = 'Completed',
  Canceled = 'Canceled',
}
export enum CompanyCategory {
  Contractor = 'Contractor',
  Engineer = 'Engineer',
  Subcontractor = 'Subcontractor',
  Owner = 'Owner',
  Vendor = 'Vendor',
  Supplier = 'Supplier',
  OutsideDesigner = 'Outside Designer',
  Architect = 'Architect',
  GeneralContractor = 'General Contractor',
}
export enum CostRuleKind {
  Ratio = 'Ratio',
  Flat = 'Flat',
}
export enum CustomFieldType {
  String = 'string',
  Number = 'number',
  Date = 'Date',
  Options = 'options',
}
export enum DashboardKind {
  Admin = 'Admin',
  CRM = 'CRM',
  Database = 'Database',
  Reporting = 'Reporting',
}
export enum DomainType {
  Activities = 'activities',
  Companies = 'companies',
  Contacts = 'contacts',
  CustomFieldDefinitions = 'custom_field_definitions',
  Dashboards = 'dashboards',
  Forecasts = 'forecasts',
  Materials = 'materials',
  Mixes = 'mixes',
  PdfTemplates = 'pdf_templates',
  Plants = 'plants',
  Products = 'products',
  ProjectStatuses = 'project_statuses',
  Projects = 'projects',
  QuoteConfigs = 'quote_configs',
  QuoteStatuses = 'quote_statuses',
  Quotes = 'quotes',
  Roles = 'roles',
  TaxCodes = 'tax_codes',
  Users = 'users',
  Markets = 'markets',
  Segments = 'segments',
  DispatchCustomers = 'dispatch_customers',
  ProjectConfigs = 'project_configs',
  QueuedNotificationEmails = 'queued_notification_emails',
  QuotePolicies = 'quote_policies',
  PlantTruckingTypes = 'plant_trucking_types',
}
export enum EscalationKind {
  Percentage = 'Percentage',
  Flat = 'Flat',
}
export enum FeatureFlagName {
  TestDummy = '_',
  DataTablePrinting = 'DataTablePrinting',
  FeatureFlagDispatchCustomer = 'DispatchCustomer',
  FeatureFlagNylasV3 = 'NylasV3',
  FeatureFlagLocalStorage = 'LocalStorage',
  FeatureFlagIntegrationImport = 'IntegrationImport',
  FeatureFlagIntegrationExport = 'IntegrationExport',
  FeatureFlagAggregatesDemo = 'AggregatesDemo',
}
export enum FilterOperation {
  Equals = 'equals',
  EqualsOrNull = 'equalsornull',
  NotEquals = 'notequals',
  NotEqualsAndNotNull = 'notequalsnornull',
  Lookup = 'lookup',
  Search = 'search',
}
export enum ForecastKind {
  Project = 'project',
  Budget = 'budget',
  Capacity = 'capacity',
}
export enum MaterialType {
  Aggregate = 'Aggregate',
  Cementitious = 'Cementitious',
  Admixture = 'Admixture',
  Fiber = 'Fiber',
  Water = 'Water',
  Other = 'Other',
}
export enum NylasProvider {
  Google = 'google',
  Icloud = 'icloud',
  Imap = 'imap',
  Microsoft = 'microsoft',
  Yahoo = 'yahoo',
  Ews = 'ews',
  Custom = 'custom',
  Eas = 'eas',
  Gmail = 'gmail',
}
export enum NylasTokenType {
  Bearer = 'Bearer',
  Digest = 'Digest',
  Basic = 'Basic',
}
export enum PdfTemplateType {
  Code = 'code',
  PdfGenerator = 'pdf_generator',
}
export enum ProductCategory {
  Mix = 'Mix',
  Resale = 'Resale',
  Precast = 'Precast',
  Other = 'Other',
  Aggregate = 'Aggregate',
  Asphalt = 'Asphalt',
}
export enum ProductDeliveryType {
  Pickup = 'pickup',
  Hauling = 'hauling',
}
export enum QuotePolicyThresholdKind {
  MarginMixProductsAverageLessThanDollars = 'margin_mix_products_average_less_than_dollars',
}
export enum QuotePolicyViolationState {
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
}
export enum QuoteProductKind {
  Primary = 'primary',
  Additional = 'additional',
}
export enum RoleName {
  Salesperson = 'Salesperson',
  SlabstackAdministrator = 'Slabstack Administrator',
  ITAdministrator = 'IT Administrator',
  Manager = 'Manager',
  QualityControl = 'Quality Control',
  Purchasing = 'Purchasing',
}
export enum SortDirection {
  Descending = 'desc',
  Ascending = 'asc',
}
export enum Unit {
  CuYd = 'cu_yd',
  Ea = 'ea',
  Lb = 'lb',
  Tn = 'tn',
  FlOz = 'fl_oz',
  FlOzPerCwt = 'fl_oz_per_cwt',
  Gal = 'gal',
  PerHr = 'per_hr',
  In = 'in',
  Ft = 'ft',
  Yd = 'yd',
  Lf = 'lf',
  Mm = 'mm',
  Cm = 'cm',
  M = 'm',
  Km = 'km',
  SqIn = 'sq_in',
  SqFt = 'sq_ft',
  SqYd = 'sq_yd',
  SqMi = 'sq_mi',
  Ac = 'ac',
  SqMm = 'sq_mm',
  SqCm = 'sq_cm',
  SqM = 'sq_m',
  SqKm = 'sq_km',
  CuIn = 'cu_in',
  CuFt = 'cu_ft',
  CuMm = 'cu_mm',
  CuCm = 'cu_cm',
  CuM = 'cu_m',
  Pt = 'pt',
  Qt = 'qt',
  L = 'L',
  ML = 'mL',
  Oz = 'oz',
  G = 'g',
  Kg = 'kg',
  T = 't',
  PerL = 'per_L',
  PerQty = 'per_qty',
  PerCuYd = 'per_cu_yd',
  PerM = 'per_m',
  Bag = 'bag',
  Dosage = 'dosage',
  Percent = 'percent',
  PerTn = 'per_tn',
  PerLoad = 'per_load',
  Mi = 'mi',
  PerCuM = 'per_cu_m',
  BlockEquivalentUnit = 'block_equivalent_unit',
}
