// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import { NIL_UUID } from '../../utils/UUID';

export class MixSummary {
  readonly id: string;
  readonly alternateId: string;
  readonly name: string;
  readonly psi: string;
  readonly materialCost: string;
  readonly plantName: string;
  readonly incompatibleBatchUnits: string | null;
  readonly slump: string | null;
  readonly aggregateSize: string | null;
  readonly waterCementPercentage: string | null;
  readonly flyAshPercentage: string | null;
  readonly plantId: string;
  readonly marketId: string;
  readonly externalId: string | null;

  constructor(data: { [key: string]: any } = {}) {
    this.id = data.id === undefined ? NIL_UUID : data.id;
    this.alternateId = data.alternateId === undefined ? '' : data.alternateId;
    this.name = data.name === undefined ? '' : data.name;
    this.psi = data.psi === undefined ? '' : data.psi;
    this.materialCost = data.materialCost === undefined ? '' : data.materialCost;
    this.plantName = data.plantName === undefined ? '' : data.plantName;
    this.incompatibleBatchUnits =
      data.incompatibleBatchUnits === undefined ? null : data.incompatibleBatchUnits;
    this.slump = data.slump === undefined ? null : data.slump;
    this.aggregateSize = data.aggregateSize === undefined ? null : data.aggregateSize;
    this.waterCementPercentage =
      data.waterCementPercentage === undefined ? null : data.waterCementPercentage;
    this.flyAshPercentage = data.flyAshPercentage === undefined ? null : data.flyAshPercentage;
    this.plantId = data.plantId === undefined ? NIL_UUID : data.plantId;
    this.marketId = data.marketId === undefined ? NIL_UUID : data.marketId;
    this.externalId = data.externalId === undefined ? null : data.externalId;
  }

  static zero(): MixSummary {
    const zeroValues: ExcludeMethodsDeep<MixSummary> = {
      id: NIL_UUID,
      alternateId: '',
      name: '',
      psi: '',
      materialCost: '',
      plantName: '',
      incompatibleBatchUnits: null,
      slump: null,
      aggregateSize: null,
      waterCementPercentage: null,
      flyAshPercentage: null,
      plantId: NIL_UUID,
      marketId: NIL_UUID,
      externalId: null,
    };
    return new MixSummary(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewMixSummary = (
  props: PartialDeep<MixSummary, { recurseIntoArrays: true }>,
): MixSummary => new MixSummary(props);

export const NewMixSummaryFromDomainObject = (
  props: PartialDeep<DomainObject<MixSummary>, { recurseIntoArrays: true }>,
): MixSummary => new MixSummary(props);
